import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ImageIcon from '@mui/icons-material/Image';
import ReplayIcon from '@mui/icons-material/Replay';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { useParams } from 'react-router-dom';
import { getDJInfo, updateDJInfo } from '../api/airtable';
import { uploadFileToCloudinary } from '../api/cloudinary';

const styles = {
  container: {
    py: 4,
    minHeight: '100vh'
  },
  paper: {
    padding: 3,
    marginTop: 4,
    marginBottom: 4,
    backgroundColor: '#121212',
    color: '#FFFFFF',
    border: '1px solid rgba(255,255,255,0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 8px 24px rgba(0,0,0,0.3)'
    }
  },
  title: {
    color: 'white',
    fontWeight: 700,
    fontSize: { xs: '1.8rem', sm: '2rem' },
    textAlign: 'center',
    marginBottom: 2
  },
  subtitle: {
    color: '#b70a20',
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: 1,
    fontSize: '1.1rem'
  },
  description: {
    color: 'grey.400',
    textAlign: 'center',
    marginBottom: 4,
    '& strong': {
      color: 'white',
      fontWeight: 600
    }
  },
  textField: {
    marginBottom: 3,
    '& .MuiOutlinedInput-root': {
      color: '#FFFFFF',
      '& fieldset': { borderColor: 'rgba(255,255,255,0.1)' },
      '&:hover fieldset': { borderColor: '#b70a20' },
      '&.Mui-focused fieldset': { borderColor: '#b70a20' },
    },
    '& .MuiInputLabel-root': {
      color: 'grey.400',
      '&.Mui-focused': { color: '#b70a20' },
    }
  },
  imageButton: {
    backgroundColor: '#b70a20',
    '&:hover': { 
      backgroundColor: '#8f0816',
      transform: 'translateY(-2px)'
    },
    transition: 'all 0.3s ease'
  },
  deleteButton: {
    backgroundColor: '#b70a20',
    '&:hover': { 
      backgroundColor: '#8f0816',
      transform: 'translateY(-2px)'
    }
  },
  buttonContainer: {
    display: 'flex',
    gap: 2,
    marginTop: 3
  },
  undoButton: {
    flex: 1,
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    border: '1px solid #b70a20',
    '&:hover': {
      backgroundColor: 'rgba(183, 10, 32, 0.1)',
      transform: 'translateY(-2px)'
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      borderColor: 'rgba(183, 10, 32, 0.3)',
      color: 'rgba(255, 255, 255, 0.3)'
    },
    transition: 'all 0.3s ease',
    padding: '12px',
    borderRadius: '8px'
  },
  saveButton: {
    flex: 2,
    backgroundColor: '#b70a20',
    color: '#FFFFFF',
    padding: '12px',
    '&:hover': {
      backgroundColor: '#8f0816',
      transform: 'translateY(-2px)'
    },
    '&:disabled': {
      backgroundColor: 'rgba(183, 10, 32, 0.3)'
    },
    transition: 'all 0.3s ease'
  },
  avatarContainer: {
    position: 'relative',
    '&:hover': {
      '& .edit-button': {
        opacity: 1
      }
    }
  },
  avatar: {
    width: 120,
    height: 120,
    border: '3px solid #b70a20',
    marginBottom: 2,
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  },
  editButton: {
    position: 'absolute',
    bottom: 10,
    right: 0,
    opacity: 0,
    transition: 'all 0.3s ease'
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: { xs: 'row', sm: 'row' },
    justifyContent: 'center',
    gap: 2,
    marginBottom: 3,
    flexWrap: 'wrap'
  },
  imageSection: {
    flex: '0 0 auto',
    width: { xs: '45%', sm: '40%' },
    maxWidth: '200px',
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#1E1E1E',
    border: '1px solid rgba(255,255,255,0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
    }
  },
  imageLabel: {
    textAlign: 'center',
    marginBottom: 1,
    color: 'white',
    fontWeight: 600,
    fontSize: '0.9rem'
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'all 0.3s ease',
  },
  imageActions: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    display: 'flex',
    gap: 0.5,
    zIndex: 1
  },
  emptyImageText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#888888',
    textAlign: 'center',
    width: '100%',
    padding: 1,
    fontSize: '0.8rem'
  },
  confirmDialog: {
    '& .MuiPaper-root': {
      backgroundColor: '#121212',
      color: '#FFFFFF',
      minWidth: '300px',
      border: '1px solid rgba(255,255,255,0.1)',
      boxShadow: '0 8px 32px rgba(0,0,0,0.5)',
    }
  },
  tipBox: {
    backgroundColor: 'rgba(84, 167, 114, 0.1)',
    borderRadius: 2,
    padding: 2,
    marginBottom: 3,
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },
  errorText: {
    color: 'error.main',
    fontSize: '0.8rem',
    marginTop: 1
  }
};

const DJQRProfileEditor = () => {
  const { formId } = useParams();
  const [profileData, setProfileData] = useState({
    name: '',
    instagramLink: '',
    instagramHandle: '',
    foto: null,
    logo: null
  });
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [venueImagePreview, setVenueImagePreview] = useState(null);
  const [saving, setSaving] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [changedFields, setChangedFields] = useState({});
  const [originalData, setOriginalData] = useState(null);
  const [currentStep, setCurrentStep] = useState(-1);
  const [fieldsTouched, setFieldsTouched] = useState({
    name: false,
    instagramLink: false
  });
  const [loading, setLoading] = useState(false);
  const [editHistory, setEditHistory] = useState([]);
  const [errors, setErrors] = useState({
    name: '',
    instagramLink: '',
    foto: '',
  });

  // Validación de Instagram sin escape innecesario
  const validateInstagramLink = (link) => {
    const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+\/?$/;
    return instagramRegex.test(link) || link === '';
  };

  // Función para guardar un estado en el historial
  const saveToHistory = useCallback((newData) => {
    setEditHistory(prev => [...prev.slice(0, currentStep + 1), newData]);
    setCurrentStep(prev => prev + 1);
  }, [currentStep]);

  // Función para extraer el handle de Instagram de una URL
  const extractInstagramHandle = (url) => {
    if (!url) return '';
    
    try {
      // Extraer el username del link de Instagram
      const match = url.match(/instagram\.com\/([A-Za-z0-9_\.]+)/);
      if (match && match[1]) {
        return `@${match[1]}`; // Agregamos el @ al inicio
      }
      return '';
    } catch (error) {
      console.error('Error al extraer handle de Instagram:', error);
      return '';
    }
  };

  // Modificar handleChange para guardar historia
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    let newData = { ...profileData, [name]: value };

    // Si se está actualizando el link de Instagram, actualizar también el handle
    if (name === 'instagramLink') {
      const handle = extractInstagramHandle(value);
      newData = { ...newData, instagramHandle: handle };
    }

    setProfileData(newData);
    setFieldsTouched(prev => ({ ...prev, [name]: true }));
    
    // Validación en tiempo real
    if (name === 'name') {
      if (!value.trim()) {
        setErrors(prev => ({ ...prev, name: 'El nombre del DJ es obligatorio' }));
      } else if (value.trim().length < 2) {
        setErrors(prev => ({ ...prev, name: 'El nombre debe tener al menos 2 caracteres' }));
      } else {
        setErrors(prev => ({ ...prev, name: '' }));
      }
    }

    if (name === 'instagramLink') {
      if (!value.trim()) {
        setErrors(prev => ({ ...prev, instagramLink: 'El enlace de Instagram es obligatorio' }));
      } else if (!validateInstagramLink(value)) {
        setErrors(prev => ({ ...prev, instagramLink: 'Ingresa un enlace válido de Instagram' }));
      } else {
        setErrors(prev => ({ ...prev, instagramLink: '' }));
      }
    }

    saveToHistory(newData);
  };

  // Modificar handleImageChange para guardar historia
  const handleImageChange = async (event, type) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (type === 'profile') {
            setProfileImagePreview(reader.result);
          } else {
            setVenueImagePreview(reader.result);
          }
        };
        reader.readAsDataURL(file);

        const uploadedUrl = await uploadFileToCloudinary(file);
        const newData = {
          ...profileData,
          [type === 'profile' ? 'foto' : 'logo']: uploadedUrl
        };
        
        setProfileData(newData);
        saveToHistory(newData);
        showNotification('Imagen subida exitosamente', 'success');
      } catch (error) {
        console.error('Error al subir la imagen:', error);
        showNotification('Error al subir la imagen', 'error');
        if (type === 'profile') {
          setProfileImagePreview(profileData.foto);
        } else {
          setVenueImagePreview(profileData.logo);
        }
      }
    }
  };

  // Función para deshacer cambios
  const handleUndo = useCallback(() => {
    if (currentStep > 0) {
      const previousState = editHistory[currentStep - 1];
      setProfileData(previousState);
      setCurrentStep(prev => prev - 1);
      
      // Actualizar previews si es necesario
      if ('foto' in previousState) {
        setProfileImagePreview(previousState.foto);
      }
      if ('logo' in previousState) {
        setVenueImagePreview(previousState.logo);
      }
      
      showNotification('Cambio deshecho', 'info');
    }
  }, [currentStep, editHistory]);

  // Guardar estado inicial en el historial cuando se carga la data
  useEffect(() => {
    const fetchDJInfo = async () => {
      setLoading(true);
      try {
        const data = await getDJInfo(formId);
        if (data) {
          const initialData = {
            name: data.Name || '',
            instagramLink: data.InstagramLink || '',
            instagramHandle: data.InstagramHandle || '',
            foto: data.Foto?.[0]?.url || null,
            logo: data.Logo?.[0]?.url || null
          };
          setProfileData(initialData);
          setOriginalData(initialData);
          setProfileImagePreview(initialData.foto);
          setVenueImagePreview(initialData.logo);
          // Inicializar el historial con el estado inicial
          setEditHistory([initialData]);
        }
      } catch (error) {
        console.error('Error al cargar la información:', error);
        showNotification('Error al cargar la información', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchDJInfo();
  }, [formId]);

  const handleRemoveLogo = () => {
    setProfileData(prev => ({ ...prev, logo: null }));
    setVenueImagePreview(null);
  };

  const showNotification = (message, severity) => {
    setNotification({ open: true, message, severity });
  };

  const getChangedFields = () => {
    const changes = {};
    if (!originalData) return changes;

    if (profileData.name !== originalData.name) {
      changes.name = {
        old: originalData.name,
        new: profileData.name
      };
    }
    if (profileData.instagramLink !== originalData.instagramLink) {
      changes.instagramLink = {
        old: originalData.instagramLink,
        new: profileData.instagramLink
      };
      // Incluir el cambio del handle si cambió el link
      changes.instagramHandle = {
        old: originalData.instagramHandle,
        new: profileData.instagramHandle
      };
    }
    if (profileData.foto !== originalData.foto) {
      changes.foto = {
        changed: true
      };
    }
    if (profileData.logo !== originalData.logo) {
      changes.logo = {
        changed: true
      };
    }
    return changes;
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Validar nombre
    if (!profileData.name.trim()) {
      newErrors.name = 'El nombre del DJ es obligatorio';
      isValid = false;
    } else if (profileData.name.trim().length < 2) {
      newErrors.name = 'El nombre debe tener al menos 2 caracteres';
      isValid = false;
    }

    // Validar foto principal
    if (!profileData.foto && !profileImagePreview) {
      newErrors.foto = 'La foto de perfil es obligatoria';
      isValid = false;
    }

    // Validar Instagram (obligatorio y formato)
    if (!profileData.instagramLink.trim()) {
      newErrors.instagramLink = 'El enlace de Instagram es obligatorio';
      isValid = false;
    } else if (!validateInstagramLink(profileData.instagramLink)) {
      newErrors.instagramLink = 'Ingresa un enlace válido de Instagram';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      showNotification('Por favor, completa todos los campos obligatorios', 'error');
      return;
    }

    const changes = getChangedFields();
    if (Object.keys(changes).length === 0) {
      showNotification('No se han realizado cambios', 'info');
      return;
    }

    setChangedFields(changes);
    setOpenConfirmDialog(true);
  };

  const handleConfirmSave = async () => {
    setOpenConfirmDialog(false);
    setSaving(true);
    try {
      await updateDJInfo(formId, profileData);
      setOriginalData(profileData);
      showNotification('Perfil actualizado exitosamente', 'success');
    } catch (error) {
      showNotification('Error al actualizar el perfil', 'error');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress sx={{ color: '#54A772' }} />
      </Box>
    );
  }

  return (
    <>
      <Container maxWidth="sm" sx={styles.container}>
        <Paper elevation={3} sx={styles.paper}>
          <Typography variant="h4" sx={styles.title}>
          Personaliza tu QR DJ 🎧
          </Typography>
          
          <Typography variant="body1" sx={styles.description}>
            Estás editando <strong>la vista que ve tu público</strong> cuando quieren sugerirte canciones.
          </Typography>

          <Box sx={styles.tipBox}>
            <EmojiObjectsIcon sx={{ color: '#54A772' }} />
            <Typography variant="body2" sx={{ color: '#54A772' }}>
              ¡Pro tip! Mantén tu perfil actualizado para conseguir más interacciones
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
            <Box sx={styles.imagesContainer}>
              {/* Foto de Perfil */}
              <Box sx={styles.imageSection}>
                <Typography sx={styles.imageLabel}>
                  Foto de Perfil *
                </Typography>
                <Box sx={styles.imageWrapper}>
                  {profileImagePreview ? (
                    <>
                      <Box
                        component="img"
                        src={profileImagePreview}
                        alt="Foto de perfil"
                        sx={styles.image}
                      />
                      <Box sx={styles.imageActions}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setProfileData(prev => ({ ...prev, foto: null }));
                            setProfileImagePreview(null);
                          }}
                          sx={styles.deleteButton}
                        >
                          <DeleteIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                        <input
                          accept="image/*"
                          type="file"
                          id="profile-image"
                          hidden
                          onChange={(e) => handleImageChange(e, 'profile')}
                        />
                        <label htmlFor="profile-image">
                          <IconButton
                            component="span"
                            size="small"
                            sx={styles.imageButton}
                          >
                            <EditIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </label>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography sx={styles.emptyImageText}>
                        📸 Sube tu foto
                      </Typography>
                      <input
                        accept="image/*"
                        type="file"
                        id="profile-image"
                        hidden
                        onChange={(e) => handleImageChange(e, 'profile')}
                      />
                      <label htmlFor="profile-image">
                        <IconButton
                          component="span"
                          size="small"
                          sx={{
                            ...styles.imageButton,
                            position: 'absolute',
                            bottom: 8,
                            right: 8
                          }}
                        >
                          <EditIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </label>
                    </>
                  )}
                </Box>
                {errors.foto && (
                  <Typography sx={styles.errorText}>
                    {errors.foto}
                  </Typography>
                )}
              </Box>

              {/* Logo */}
              <Box sx={styles.imageSection}>
                <Typography sx={styles.imageLabel}>
                  Logo
                </Typography>
                <Box sx={styles.imageWrapper}>
                  {venueImagePreview ? (
                    <>
                      <Box
                        component="img"
                        src={venueImagePreview}
                        alt="Logo"
                        sx={styles.image}
                      />
                      <Box sx={styles.imageActions}>
                        <IconButton
                          size="small"
                          onClick={handleRemoveLogo}
                          sx={styles.deleteButton}
                        >
                          <DeleteIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                        <input
                          accept="image/*"
                          type="file"
                          id="venue-image"
                          hidden
                          onChange={(e) => handleImageChange(e, 'venue')}
                        />
                        <label htmlFor="venue-image">
                          <IconButton
                            component="span"
                            size="small"
                            sx={styles.imageButton}
                          >
                            <EditIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </label>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography sx={styles.emptyImageText}>
                        🎨 Sube tu logo
                      </Typography>
                      <input
                        accept="image/*"
                        type="file"
                        id="venue-image"
                        hidden
                        onChange={(e) => handleImageChange(e, 'venue')}
                      />
                      <label htmlFor="venue-image">
                        <IconButton
                          component="span"
                          size="small"
                          sx={{
                            ...styles.imageButton,
                            position: 'absolute',
                            bottom: 8,
                            right: 8
                          }}
                        >
                          <EditIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </label>
                    </>
                  )}
                </Box>
              </Box>
            </Box>

            {/* Campos de texto */}
            <TextField
              required
              error={!!errors.name}
              helperText={errors.name || 'Este campo es obligatorio'}
              fullWidth
              name="name"
              label="Nombre del DJ"
              value={profileData.name}
              onChange={handleFieldChange}
              sx={styles.textField}
            />

            <TextField
              required
              error={!!errors.instagramLink}
              helperText={errors.instagramLink || 'Ejemplo: https://instagram.com/usuario'}
              fullWidth
              name="instagramLink"
              label="Instagram"
              value={profileData.instagramLink}
              onChange={handleFieldChange}
              sx={styles.textField}
            />

            <Box sx={styles.buttonContainer}>
              {currentStep > 0 && (
                <Button
                  startIcon={<ReplayIcon sx={{ color: '#FFFFFF' }} />}
                  onClick={handleUndo}
                  disabled={currentStep <= 0}
                  sx={styles.undoButton}
                >
                  Deshacer
                </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                disabled={saving}
                sx={styles.saveButton}
              >
                {saving ? 
                  <CircularProgress size={24} sx={{ color: '#FFFFFF' }} /> : 
                  '¡Guardar Cambios! 🚀'
                }
              </Button>
            </Box>
          </form>
        </Paper>

        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={() => setNotification({ ...notification, open: false })}
        >
          <Alert 
            severity={notification.severity}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Container>

      <Dialog 
        open={openConfirmDialog} 
        onClose={() => setOpenConfirmDialog(false)}
        sx={styles.confirmDialog}
      >
        <DialogTitle sx={{ color: '#b70a20' }}>
          Confirmar Cambios
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Se realizarán los siguientes cambios:
          </Typography>
          <List>
            {changedFields.name && (
              <ListItem>
                <ListItemIcon>
                  <EditNoteIcon sx={{ color: '#b70a20' }} />
                </ListItemIcon>
                <ListItemText
                  primary="Nombre"
                  secondary={
                    <Box>
                      <Typography variant="body2" sx={{ color: 'grey.500' }}>
                        Anterior: {changedFields.name.old || '(vacío)'}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#fff' }}>
                        Nuevo: {changedFields.name.new}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            )}
            {changedFields.instagramLink && (
              <>
                <ListItem>
                  <ListItemIcon>
                    <EditNoteIcon sx={{ color: '#b70a20' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Instagram"
                    secondary={
                      <Box>
                        <Typography variant="body2" sx={{ color: 'grey.500' }}>
                          Anterior: {changedFields.instagramLink.old || '(vacío)'}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#fff' }}>
                          Nuevo: {changedFields.instagramLink.new}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'grey.500', mt: 1 }}>
                          Usuario anterior: {changedFields.instagramHandle.old || '(vacío)'}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#fff' }}>
                          Usuario nuevo: {changedFields.instagramHandle.new}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              </>
            )}
            {changedFields.foto && (
              <ListItem>
                <ListItemIcon>
                  <ImageIcon sx={{ color: '#b70a20' }} />
                </ListItemIcon>
                <ListItemText primary="Foto de perfil actualizada" />
              </ListItem>
            )}
            {changedFields.logo && (
              <ListItem>
                <ListItemIcon>
                  <ImageIcon sx={{ color: '#b70a20' }} />
                </ListItemIcon>
                <ListItemText primary="Logo actualizado" />
              </ListItem>
            )}
          </List>
          <Typography variant="body2" sx={{ mt: 2, color: 'grey.400' }}>
            Puedes deshacer estos cambios después si lo necesitas.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button 
            onClick={() => setOpenConfirmDialog(false)}
            sx={{ color: 'grey.400' }}
          >
            Regresar
          </Button>
          <Button
            onClick={handleConfirmSave}
            variant="contained"
            sx={styles.saveButton}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DJQRProfileEditor; 