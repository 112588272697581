import React from 'react';
import { Card, CardContent, Typography, Divider, List, ListItem, ListItemIcon, ListItemText, Button, Chip, Box } from '@mui/material';

const PlanCard = ({ plan, features, handleOpenModal, color, currency, onCurrencyChange, sx }) => {
  return (
    <Card
      sx={{
        maxWidth: 400,
        mx: "auto",
        mt: 4,
        backgroundColor: "rgba(34, 34, 34, 0.95)",
        backdropFilter: "blur(20px)",
        color: "white",
        borderRadius: '24px',
        transition: "all 0.3s ease",
        boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.2)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        overflow: 'visible',
        position: 'relative',
        ...sx
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography variant="h5" component="div" gutterBottom>
          {plan.title}
        </Typography>
        
        {plan.savings && (
          <Chip
            label={plan.savings}
            size="small"
            sx={{
              backgroundColor: '#4CAF50',
              color: 'white',
              fontSize: '0.75rem',
              fontWeight: 'bold',
              mb: 2
            }}
          />
        )}

        {/* Botones de moneda dentro de la tarjeta */}
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'center',
          gap: 1,
          mb: 2,
          mt: 1
        }}>
          {['PEN', 'USD'].map((curr) => (
            <Button
              key={curr}
              size="small"
              variant={currency === curr ? 'contained' : 'outlined'}
              onClick={() => onCurrencyChange(curr)}
              sx={{
                minWidth: '60px',
                fontSize: '0.75rem',
                py: 0.5,
                px: 1,
                backgroundColor: currency === curr ? color : 'transparent',
                borderColor: color,
                color: 'white',
                borderRadius: '12px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: currency === curr ? color : `${color}30`,
                  borderColor: color,
                  transform: 'translateY(-2px)'
                }
              }}
            >
              {curr === 'PEN' ? 'Soles' : 'USD'}
            </Button>
          ))}
        </Box>

        <Typography 
          variant="h3" 
          component="div" 
          sx={{ 
            mb: 2, 
            color: 'white',
            fontWeight: 'bold',
            textShadow: '0 2px 4px rgba(0,0,0,0.2)'
          }}
        >
          {plan.price}
        </Typography>
        
        <Typography 
          variant="subtitle1" 
          sx={{ 
            mb: 3, 
            opacity: 0.8,
            color: 'rgba(255,255,255,0.9)'
          }}
        >
          {plan.subtitle}
        </Typography>

        <List sx={{ mb: 3 }}>
          {features.map((feature, index) => (
            <ListItem 
              key={index}
              sx={{
                px: 0,
                py: 1,
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateX(5px)'
                }
              }}
            >
              <ListItemIcon sx={{ color: color, minWidth: 40 }}>
                {feature.icon}
              </ListItemIcon>
              <ListItemText 
                primary={feature.text}
                sx={{
                  '& .MuiListItemText-primary': {
                    color: 'rgba(255,255,255,0.9)'
                  }
                }}
              />
            </ListItem>
          ))}
        </List>

        <Button
          variant="contained"
          onClick={handleOpenModal}
          fullWidth
          sx={{
            backgroundColor: color,
            color: 'white',
            py: 1.5,
            borderRadius: '12px',
            fontSize: '1rem',
            fontWeight: 'bold',
            textTransform: 'none',
            transition: 'all 0.3s ease',
            boxShadow: `0 4px 15px -3px ${color}80`,
            '&:hover': {
              backgroundColor: color,
              transform: 'translateY(-2px)',
              boxShadow: `0 8px 20px -5px ${color}`
            }
          }}
        >
          Comprar suscripción
        </Button>
      </CardContent>
    </Card>
  );
};

export default PlanCard; 