import React, { memo, useState, useEffect } from 'react';
import { Box, Typography, Paper, Button, Grid, Chip, Tooltip, Container, CircularProgress } from '@mui/material';
import { LocationOn, AttachMoney, Work, MoreHoriz } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import IconoPremium from '../assets/icono premium.png';
import { getDJRecords } from '../api/airtable';
import Navbar from './Navbar';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import BreadcrumbSchema from './BreadcrumbSchema';
import SEO from './SEO';

// Constantes
const WHATSAPP_NUMBER = '51945429543';
const BASE_URL = 'https://www.djponla.com/dj';

// Configuración de animaciones
const ANIMATION_CONFIG = {
  container: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  },
  item: {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  }
};

// Styles Constants
const STYLES = {
  pageContainer: {
    minHeight: '100vh',
    pt: { xs: 10, sm: 12 },
    pb: 8,
    backgroundColor: '#121212'
  },
  heroSection: {
    textAlign: 'center',
    mb: 8,
    mt: 4
  },
  title: {
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: { xs: '2rem', sm: '3rem' },
    mb: 3,
    '& span': {
      color: '#b70a20'
    }
  },
  subtitle: {
    color: '#A6A5A6',
    fontSize: { xs: '1.1rem', sm: '1.3rem' },
    maxWidth: '800px',
    mx: 'auto',
    mb: 4,
    lineHeight: 1.8
  },
  ctaButton: {
    backgroundColor: '#b70a20',
    fontSize: '1.1rem',
    py: 2,
    px: 4,
    borderRadius: 50,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#8f0816',
      transform: 'translateY(-2px)',
      boxShadow: '0 8px 16px rgba(183, 10, 32, 0.2)'
    }
  },
  featuredSection: {
    textAlign: 'center',
    mt: 8,
    mb: 4
  },
  featuredTitle: {
    color: '#ffffff',
    fontSize: '1.8rem',
    fontWeight: 600,
    mb: 3,
    '& span': {
      color: '#b70a20'
    }
  },
  pageTitle: {
    textAlign: 'center',
    mb: 6,
    '& h3': {
      fontWeight: 700,
      color: '#ffffff',
      mb: 2
    },
    '& h6': {
      color: 'rgba(255,255,255,0.7)',
      maxWidth: '800px',
      mx: 'auto'
    }
  },
  card: {
    p: 3,
    textAlign: 'center',
    borderRadius: '24px',
    height: '100%',
    minHeight: '450px',
    background: 'linear-gradient(145deg, rgba(34, 34, 34, 0.95) 0%, rgba(45, 45, 45, 0.95) 100%)',
    backdropFilter: 'blur(20px)',
    transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      transform: 'translateY(-10px)',
      boxShadow: '0 20px 40px rgba(0, 0, 0, 0.3)',
      '& .card-content': {
        transform: 'translateY(-5px)'
      },
      '& .avatar-container': {
        transform: 'scale(1.05)'
      }
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), transparent)',
      opacity: 0.1,
      transition: 'opacity 0.4s ease'
    },
    '&:hover::before': {
      opacity: 0.15
    }
  },
  avatar: {
    width: 120,
    height: 120,
    transition: 'all 0.3s ease',
    border: '2px solid rgba(255, 255, 255, 0.1)',
    backgroundColor: 'rgba(17, 91, 235, 0.05)',
    borderRadius: '16px',
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    }
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mb: 2,
    pt: 2
  },
  imageContainer: {
    position: 'relative',
    width: 120,
    height: 120,
    marginBottom: '20px',
    transition: 'all 0.3s ease',
    borderRadius: '16px',
    overflow: 'hidden',
    backgroundColor: 'rgba(17, 91, 235, 0.05)',
    border: '2px solid rgba(255, 255, 255, 0.1)',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  },
  djImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    position: 'relative',
    zIndex: 1,
    transition: 'all 0.3s ease',
    backgroundColor: 'rgba(17, 91, 235, 0.05)',
    '&.error': {
      display: 'none'
    }
  },
  fallbackAvatar: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(17, 91, 235, 0.1)',
    color: '#ffffff',
    fontSize: '2rem',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0
  },
  premiumBadge: {
    color: '#FFD700',
    marginLeft: '8px',
    fontSize: '1.2rem',
    verticalAlign: 'middle',
    filter: 'drop-shadow(0 2px 4px rgba(255, 215, 0, 0.3))',
    animation: 'shine 2s infinite linear',
    '@keyframes shine': {
      '0%': { filter: 'brightness(100%) drop-shadow(0 2px 4px rgba(255, 215, 0, 0.3))' },
      '50%': { filter: 'brightness(130%) drop-shadow(0 2px 8px rgba(255, 215, 0, 0.5))' },
      '100%': { filter: 'brightness(100%) drop-shadow(0 2px 4px rgba(255, 215, 0, 0.3))' }
    }
  },
  contactButton: {
    minWidth: "140px",
    maxWidth: "180px",
    margin: "0 auto",
    padding: "8px 24px",
    backgroundColor: "rgb(17, 91, 235)",
    fontSize: "0.9rem",
    borderRadius: "50px",
    textTransform: "none",
    transition: "all 0.3s ease",
    mt: 'auto',
    mb: 2,
    '&:hover': {
      backgroundColor: "rgb(13, 71, 161)",
      transform: "translateY(-2px)",
      boxShadow: '0 10px 20px rgba(17, 91, 235, 0.3)'
    }
  },
  cardContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 1,
    py: 0.75,
    px: 2,
    width: '100%',
    borderRadius: 2,
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(17, 91, 235, 0.05)',
      transform: 'translateX(5px)',
      '& .info-icon': {
        transform: 'scale(1.1) rotate(5deg)'
      }
    }
  },
  infoIcon: {
    color: '#115beb',
    fontSize: '1.1rem'
  },
  infoText: {
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: '0.9rem',
    fontWeight: '500',
    textAlign: 'left'
  },
  verifiedBadge: {
    color: '#FFD700',
    marginLeft: '8px',
    fontSize: '1rem',
    verticalAlign: 'middle',
    filter: 'drop-shadow(0 2px 4px rgba(255, 215, 0, 0.3))',
  },
  localesContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 1,
    mb: 2
  },
  viewMoreButton: {
    minWidth: "180px",
    padding: "12px 32px",
    backgroundColor: "rgb(17, 91, 235)",
    color: "white",
    fontSize: "0.9rem",
    borderRadius: "50px",
    textTransform: "none",
    transition: "all 0.3s ease",
    mt: 6,
    '&:hover': {
      backgroundColor: "rgb(13, 71, 161)",
      transform: "translateY(-2px)",
      boxShadow: '0 10px 20px rgba(17, 91, 235, 0.3)'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    mt: 4
  },
  chip: {
    backgroundColor: 'rgba(17, 91, 235, 0.1)',
    border: '1px solid rgba(17, 91, 235, 0.2)',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: 'rgba(17, 91, 235, 0.2)',
      transform: 'translateY(-2px)'
    }
  },
  sectionTitle: {
    color: '#ffffff',
    fontSize: { xs: '1.8rem', sm: '2rem' },
    fontWeight: 'bold',
    marginBottom: '1rem'
  },
  sectionSubtitle: {
    color: '#A6A5A6',
    fontSize: '1.1rem',
    marginBottom: '3rem',
    maxWidth: '600px',
    margin: '0 auto 4rem'
  },
  djName: {
    color: '#ffffff',
    fontSize: { xs: '1.8rem', sm: '2rem' },
    fontWeight: 'bold',
    marginBottom: '1rem'
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '300px',
    gap: 2
  },
  loadingText: {
    color: '#ffffff',
    mt: 2,
    opacity: 0.8
  },
  infoContainer: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mb: 2
  }
};

// Componente InfoRow optimizado
const InfoRow = memo(({ icon, text }) => (
  <Box sx={STYLES.infoRow}>
    {React.cloneElement(icon, { sx: STYLES.infoIcon })}
    <Typography sx={STYLES.infoText}>{text}</Typography>
  </Box>
));

// Componente LocalesSection optimizado
const LocalesSection = memo(({ locales, hasMoreLocales }) => (
  <Box sx={STYLES.localesContainer}>
    <Typography variant="body2" color="textSecondary" gutterBottom align="center">
      Locales:
    </Typography>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 0.5 }}>
      {locales.slice(0, 3).map((local, index) => (
        <Chip 
          key={local}
          label={local}
          size="small"
          sx={STYLES.chip}
        />
      ))}
      {hasMoreLocales && (
        <Tooltip
          title={
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {locales.slice(3).map(local => (
                <Chip 
                  key={local}
                  label={local}
                  size="small"
                  sx={STYLES.chip}
                />
              ))}
            </Box>
          }
          arrow
          placement="top"
        >
          <Chip
            icon={<MoreHoriz />}
            label={`+${locales.length - 3}`}
            size="small"
            sx={STYLES.chip}
          />
        </Tooltip>
      )}
    </Box>
  </Box>
));

// Componente DJCard optimizado
const DJCard = memo(({ dj }) => {
  const { fields } = dj;
  const [imageError, setImageError] = useState(false);
  
  const locales = fields['Nombre Local']?.split(',').map(local => local.trim()).filter(Boolean) || [];
  const hasMoreLocales = locales.length > 3;
  
  const getInitials = name => name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  const createWhatsAppLink = () => {
    const message = encodeURIComponent(
      `¡Hola! Estoy interesado en contratar el servicio de ${fields['name']}.`
    );
    return `https://wa.me/${WHATSAPP_NUMBER}?text=${message}`;
  };

  const handleContactClick = e => {
    if (!fields['Verificado']) {
      e.preventDefault();
      window.open(createWhatsAppLink(), '_blank');
    }
  };

  return (
    <Paper elevation={3} sx={STYLES.card}>
      <Box className="card-content" sx={STYLES.cardContent}>
        <Box sx={STYLES.imageWrapper}>
          <Box 
            className="imageContainer"
            sx={STYLES.imageContainer}
          >
            <Box
              component="img"
              src={fields['photo']?.[0]?.url}
              alt={fields['name']}
              onError={() => setImageError(true)}
              className={imageError ? 'error' : ''}
              sx={STYLES.djImage}
            />
            {imageError && (
              <Box sx={STYLES.fallbackAvatar}>
                {getInitials(fields['name'])}
              </Box>
            )}
          </Box>
        </Box>
        
        <Typography variant="h6" sx={{ 
          ...STYLES.djName,
          fontSize: '1.25rem',
          fontWeight: 600
        }}>
          {fields['name']}
          {fields['Verificado'] && (
            <Tooltip title="DJ Premium">
              <img 
                src={IconoPremium} 
                alt="DJ Premium" 
                style={{ 
                  width: '20px', 
                  height: '20px', 
                  marginLeft: '5px',
                  verticalAlign: 'middle',
                  position: 'relative',
                }} 
              />
            </Tooltip>
          )}
        </Typography>
        
        <Box sx={STYLES.infoContainer}>
          <InfoRow icon={<LocationOn />} text={fields['Lugar']} />
          <InfoRow icon={<Work />} text={`${fields['Experiencia']} años de experiencia`} />
          <InfoRow icon={<AttachMoney />} text={`Precio: S/${fields['Precio Min']} - S/${fields['Precio Max']}`} />
        </Box>
        
        <LocalesSection locales={locales} hasMoreLocales={hasMoreLocales} />
        
        <Button
          component="a"
          href={fields['Verificado'] ? `${BASE_URL}/${fields['username']}` : '#'}
          onClick={handleContactClick}
          target="_blank"
          variant="contained"
          sx={STYLES.contactButton}
        >
          Contactar
        </Button>
      </Box>
    </Paper>
  );
});

const DJsSection = () => {
  const [showAllDJs, setShowAllDJs] = useState(false);
  const [featuredDJs, setFeaturedDJs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeaturedDJs = async () => {
      try {
        setIsLoading(true);
        const records = await getDJRecords('DJs Bio');
        const sortedDJs = records.sort((a, b) => {
          if (a.fields['Verificado'] === b.fields['Verificado']) {
            return (a.fields['name'] || '').localeCompare(b.fields['name'] || '');
          }
          return b.fields['Verificado'] - a.fields['Verificado'];
        });
        setFeaturedDJs(sortedDJs);
      } catch (error) {
        console.error('Error al cargar DJs:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFeaturedDJs();
  }, []);

  const displayedDJs = showAllDJs ? featuredDJs : featuredDJs.slice(0, 9);

  // Datos estructurados para el listado
  const djListStructuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "DJ Diego Pozo",
        "url": "https://djponla.com/dj/djdiegopozo"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "DJ Checho Peru",
        "url": "https://djponla.com/dj/djchechoperu"
      }
      // Puedes añadir más DJs (hasta unos 3-5 destacados) para SEO
    ]
  };

  // Breadcrumb items
  const breadcrumbItems = [
    { name: 'Inicio', link: '/' },
    { name: 'Directorio de DJs', link: '/djs' }
  ];

  return (
    <>
      <SEO 
        title="Directorio de DJs | DJ Ponla - Encuentra DJs destacados" 
        description="Explora nuestro directorio de DJs profesionales que utilizan DJ Ponla. Encuentra DJs por género musical, ubicación y conecta con ellos."
        keywords="directorio DJs, encontrar DJ, DJs profesionales, DJs con QR, DJs destacados"
        canonicalUrl="/djs"
        ogImage="/djs-directory-og.jpg"
        structuredData={djListStructuredData}
      />
      
      <Navbar />
      <Container maxWidth="xl">
        <BreadcrumbSchema items={breadcrumbItems} />
        
        <Container maxWidth="lg" sx={STYLES.pageContainer}>
          <Box sx={STYLES.heroSection}>
            <Typography variant="h2" sx={STYLES.title}>
              Únete a la Comunidad de{' '}
              <Box component="span">
                DJs más Grande
              </Box>
            </Typography>
            <Typography variant="h5" sx={STYLES.subtitle}>
              Crea tu perfil profesional, muestra tu talento y conecta con más clientes. 
              Más de 50 DJs ya confían en nosotros para impulsar su carrera.
            </Typography>
            <Button 
              variant="contained"
              onClick={() => navigate('/profile-dj-product')}
              sx={STYLES.ctaButton}
            >
              Crear mi Perfil Profesional
            </Button>
          </Box>

          <Box sx={STYLES.featuredSection}>
            <AnimatePresence>
              {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <CircularProgress sx={{ color: '#b70a20' }} />
                </Box>
              ) : (
                <>
                  <Grid 
                    container 
                    spacing={4}
                    component={motion.div}
                    variants={ANIMATION_CONFIG.container}
                    initial="hidden"
                    animate="visible"
                  >
                    {displayedDJs.map((dj, index) => (
                      <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        md={4} 
                        key={dj.fields['name'] || index}
                        component={motion.div}
                        variants={ANIMATION_CONFIG.item}
                      >
                        <DJCard dj={dj} />
                      </Grid>
                    ))}
                  </Grid>

                  {featuredDJs.length > 9 && (
                    <Box sx={STYLES.buttonContainer}>
                      <Button
                        variant="contained"
                        onClick={() => setShowAllDJs(!showAllDJs)}
                        sx={STYLES.viewMoreButton}
                      >
                        {showAllDJs ? 'Ver menos' : 'Ver más DJs'}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </AnimatePresence>
          </Box>
          <Footer />
        </Container>
      </Container>
    </>
  );
};

export default memo(DJsSection);
