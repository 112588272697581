import React from 'react';
import { Box, Typography } from '@mui/material';

const YoutubePreview = ({ youtubeLink }) => {
  const getVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const videoId = getVideoId(youtubeLink);

  if (!videoId) return null;

  return (
    <Box sx={{ 
      marginTop: 1, 
      marginBottom: 2,
      maxWidth: '250px', // Limitamos el ancho máximo
      margin: '0 auto' // Centramos el contenedor
    }}>
      <Typography 
        variant="subtitle2" 
        sx={{ 
          color: 'rgb(255, 165, 0)', 
          marginBottom: 0.5,
          textAlign: 'center'
        }}
      >
        Vista previa:
      </Typography>
      <Box sx={{ 
        position: 'relative',
        paddingTop: '56.25%', // Mantiene relación 16:9
        width: '100%',
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.2)'
      }}>
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none'
          }}
          src={`https://www.youtube-nocookie.com/embed/${videoId}?origin=${window.location.origin}`}
          title="YouTube video preview"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          sandbox="allow-same-origin allow-scripts allow-popups allow-presentation"
        />
      </Box>
    </Box>
  );
};

export default YoutubePreview; 