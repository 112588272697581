import React from 'react';
import { Box, Typography, Grid, Card, Button, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HW1 from '../../assets/1.png';
import HW2 from '../../assets/2.gif';
import HW3 from '../../assets/3.png';
import HW4 from '../../assets/4.gif';

const steps = [
  {
    image: HW1,
    title: "Tu público escanea tu QR",
    description: "Sin descargar ningún aplicativo la gente escanea con su celular y llegan a tu vista personalizada.",
  },
  {
    image: HW2,
    title: "Envían su sugerencia",
    description: "A través de nuestro buscador seleccionan su canción y te la envían en tiempo real.",
  },
  {
    image: HW3,
    title: "El DJ decide cuál poner",
    description: "Tú decides cuál poner, marca las canciones que mejor se adapten al evento.",
  },
  {
    image: HW4,
    title: "Recibes un reporte",
    description: "Accede a estadísticas de los gustos musicales de tu público y optimiza tus próximos sets.",
  }
];

const HowItWorks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handlePricingClick = () => {
    navigate('/qr-dj-product');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Box 
      sx={{ 
        py: { md: 10 },
        mb: 8
      }}
    >
      <Box sx={{ 
        textAlign: 'center',
        mb: 4,
        position: 'relative'
      }}>
        <Typography 
            variant="h5" 
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: '#ffffff',
              mb: 3,
              fontSize:{xs: '2rem', md: '2.2rem'}
            }}
          >
           ¿Cómo{' '}
            <Box component="span" sx={{ color: '#b70a20' }}>
            funciona?
            </Box>
        </Typography>
      </Box>

      <Grid container spacing={4} sx={{ px: { xs: 2, md: 4 } }}>
        {steps.map((step, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                background: '#1a1a1a',
                borderRadius: '20px',
                transition: 'all 0.3s ease',
                position: 'relative',
                overflow: 'hidden',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                }
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                width: '100%',
                mb: 3,
                mt: 1,
                justifyContent: 'center',
                gap: 2
              }}>
                <Box
                  className="step-number"
                  sx={{
                    width: 36,
                    height: 36,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#b70a20',
                    color: '#ffffff',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    flexShrink: 0
                  }}
                >
                  {index + 1}
                </Box>
                
                <Typography 
                  variant="h5" 
                  sx={{
                    fontWeight: 700,
                    color: '#ffffff',
                    fontSize: { xs: '1.2rem', md: '1.4rem' }
                  }}
                >
                  {step.title}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: 200,
                  height: 380,
                  mb: 3,
                  overflow: 'hidden',
                  borderRadius: '10px',
                  position: 'relative',
                  mx: 'auto',
                  '@media (max-width: 600px)': {
                    width: '100%',
                    maxWidth: 200,
                    height: 380,
                  }
                }}
              >
                <img
                  src={step.image}
                  alt={step.title}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    transition: 'transform 0.3s ease',
                    backgroundColor: '#000',
                  }}
                />
              </Box>

              <Typography
                variant="body1"
                sx={{
                  color: 'rgba(255, 255, 255, 0.8)',
                  textAlign: 'center',
                  lineHeight: 1.8
                }}
              >
                {step.description}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 6, textAlign: 'center' }}>
               
        <Button
          onClick={handlePricingClick}
          variant="contained"
          sx={{
            py: { xs: 1.5, md: 2 },
            px: { xs: 4, md: 6 },
            fontSize: { xs: '1.1rem', md: '1.3rem' },
            fontWeight: 800,
            borderRadius: '50px',
            background: 'linear-gradient(45deg, #b70a20, #8f0816)',
            position: 'relative',
            overflow: 'hidden',
            textTransform: 'uppercase',
            letterSpacing: '1px',
            boxShadow: '0 4px 15px rgba(183, 10, 32, 0.4)',
            '&:hover': {
              transform: 'translateY(-3px) scale(1.03)',
              boxShadow: '0 10px 25px rgba(183, 10, 32, 0.5)',
              background: 'linear-gradient(45deg, #8f0816, #b70a20)',
            },
            '&:active': {
              transform: 'translateY(1px)',
            },
            transition: 'all 0.3s ease'
          }}
        >
          ¡Generar mi QR!
        </Button>
      </Box>
    </Box>
  );
};

export default HowItWorks; 