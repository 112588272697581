import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@mui/system';

const glowAnimation = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 0.8; }
  100% { opacity: 0.5; }
`;

const HeroSection = () => {
  const navigate = useNavigate();

  const handlePricingClick = () => {
    navigate('/qr-dj-product');
  };

  return (
    <Container maxWidth="lg">
      <Box 
        sx={{
          minHeight: { md: '90vh' },
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          py: { xs: 4, md: 12 }
        }}
      >
        {/* Efecto de luz central con rojo aún más suave */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '300px', sm: '500px', md: '800px' },
            height: { xs: '300px', sm: '500px', md: '800px' },
            background: `
              radial-gradient(circle, 
                rgba(255, 138, 138, 0.15) 0%, 
                rgba(255, 138, 138, 0.1) 20%, 
                rgba(255, 138, 138, 0.07) 40%,
                rgba(255, 138, 138, 0.03) 60%,
                transparent 80%
              )
            `,
            animation: `${glowAnimation} 4s ease-in-out infinite`,
            zIndex: 0,
            pointerEvents: 'none',
            filter: 'blur(40px)',
            opacity: 0.8,
          }}
        />

        {/* Capa adicional con rojo aún más suave */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -70%)',
            width: { xs: '350px', sm: '450px', md: '600px' },
            height: { xs: '350px', sm: '450px', md: '600px' },
            background: `
              radial-gradient(circle, 
                rgba(255, 138, 138, 0.2) 0%, 
                rgba(255, 138, 138, 0.1) 40%, 
                transparent 80%
              )
            `,
            animation: `${glowAnimation} 4s ease-in-out infinite reverse`,
            zIndex: 0,
            pointerEvents: 'none',
            filter: 'blur(20px)',
          }}
        />

        <Box sx={{ 
          position: 'relative', 
          zIndex: 1, 
          textAlign: 'center',
          width: '100%'
        }}>
          <Typography 
            variant="h1"
            sx={{
              fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
              fontWeight: 800,
              lineHeight: 1.1,
              mb: { xs: 2, md: 3 },
              '& .highlight': {
                color: '#b70a20',
                display: 'block',
                fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
              },
              color: '#ffffff',
              position: 'relative', // Para que el texto se mantenga sobre el efecto
            }}
          >
            Mejoramos la
            <br />
            conexión entre los
            <span className="highlight">DJs y su público</span>
          </Typography>

          <Typography 
            variant="h6"
            sx={{
              color: '#A6A5A6',
              maxWidth: '600px',
              mx: 'auto',
              mb: { xs: 4, md: 6 },
              lineHeight: 1.8,
              fontSize: { xs: '1rem', md: '1.25rem' },
              px: { xs: 2, md: 0 },
              position: 'relative', // Para que el texto se mantenga sobre el efecto
            }}
          >
            Un QR que tu público escanea, te pueden sugerir canciones y llegan a tu celular en tiempo real. Recopilamos esas sugerencias y te damos estadísticas de los gustos musicales de tu público.
          </Typography>

          <Button
            onClick={handlePricingClick}
            variant="contained"
            sx={{
              py: { xs: 1.5, md: 1.5 },
              px: { xs: 3.5, md: 4 },
              fontSize: { xs: '1.1rem', md: '1.1rem' },
              fontWeight: 800,
              borderRadius: '50px',
              background: 'linear-gradient(45deg, #b70a20, #8f0816)',
              position: 'relative',
              overflow: 'hidden',
              textTransform: 'uppercase',
              letterSpacing: '1px',
              boxShadow: '0 4px 15px rgba(183, 10, 32, 0.4)',
              '&:hover': {
                transform: 'translateY(-3px) scale(1.03)',
                boxShadow: '0 10px 25px rgba(183, 10, 32, 0.5)',
                background: 'linear-gradient(45deg, #8f0816, #b70a20)',
              },
              '&:active': {
                transform: 'translateY(1px)',
              },
              transition: 'all 0.3s ease'
            }}
          >
            Empezar ahora
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default HeroSection;
