import React from 'react';
import {
  Grid,
  Typography,
  Card,
  Box,
} from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';

const cardStyles = {
  backgroundColor: "rgba(34, 34, 34, 0.95)",
  backdropFilter: "blur(20px)",
  borderRadius: '24px',
  p: 3,
  minHeight: 'auto',
  boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)'
  }
};

const COLORS = {
  played: '#b70a20',
  notPlayed: '#FF6B6B'
};

const PlaybackStatus = ({ playOptionsData, statistics }) => {
  // Filtrar solo "Se puso" y "No la quiero poner"
  const filteredData = playOptionsData.filter(item => 
    ['Se puso', 'No la quiero poner'].includes(item.rawOption)
  );

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box sx={{
          backgroundColor: '#1e1e1e',
          border: '1px solid rgba(255,255,255,0.1)',
          borderRadius: '12px',
          p: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.3)'
        }}>
          <Typography sx={{ color: '#fff', fontWeight: 'bold', mb: 1 }}>
            {data.name}
          </Typography>
          <Typography sx={{ color: '#fff' }}>
            {data.value} canciones
          </Typography>
          <Typography sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
            {(data.value / statistics.totalRequests * 100).toFixed(1)}%
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card sx={cardStyles}>
          <Typography variant="h5" sx={{ 
            mb: 0.5,
            color: '#fff', 
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', md: '1.5rem' },
          }}>
            Acciones realizadas
          </Typography>
          <Box sx={{ 
            height: 300, 
            position: 'relative',
            mt: 0
          }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={filteredData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  innerRadius={0}
                  label={({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
                    const radius = (innerRadius + outerRadius) * 0.5;
                    const RADIAN = Math.PI / 180;
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    return (
                      <text
                        x={x}
                        y={y}
                        fill="#ffffff"
                        textAnchor="middle"
                        dominantBaseline="central"
                        fontSize="16"
                        fontWeight="bold"
                      >
                        {`${(percent * 100).toFixed(1)}%`}
                      </text>
                    );
                  }}
                  labelLine={false}
                >
                  {filteredData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={entry.rawOption === 'Se puso' ? COLORS.played : COLORS.notPlayed}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign="bottom"
                  height={24}
                  content={({ payload }) => (
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      gap: 4,
                      mt: 0
                    }}>
                      {payload.map((entry, index) => (
                        <Box 
                          key={index}
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1 
                          }}
                        >
                          <Box sx={{ 
                            width: 16, 
                            height: 16, 
                            backgroundColor: entry.color,
                            borderRadius: '4px'
                          }} />
                          <Typography sx={{ color: '#fff' }}>
                            {entry.value}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card sx={cardStyles}>
          <Typography variant="h5" sx={{ 
            mb: 4, 
            color: '#fff', 
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', md: '1.5rem' },
          }}>
            Resumen de acciones realizadas
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h3" sx={{ 
                color: '#b70a20',
                fontWeight: 'bold'
              }}>
                {statistics.playedPercentage}
              </Typography>
              <Typography variant="body1" sx={{ color: '#fff' }}>
                Tasa de Reproducción
              </Typography>
            </Box>

            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-around', 
              gap: 2
            }}>
              <Box sx={{ 
                textAlign: 'center',
                p: 2,
                backgroundColor: 'rgba(255,255,255,0.05)',
                borderRadius: 2,
                flex: 1
              }}>
                <Typography variant="h4" sx={{ color: COLORS.played }}>
                  {statistics.playedSongs}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Se puso ✅
                </Typography>
              </Box>
              <Box sx={{ 
                textAlign: 'center',
                p: 2,
                backgroundColor: 'rgba(255,255,255,0.05)',
                borderRadius: 2,
                flex: 1
              }}>
                <Typography variant="h4" sx={{ color: COLORS.notPlayed }}>
                  {statistics.totalRequests - statistics.playedSongs}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  No la quiero poner ❌
                </Typography>
              </Box>
            </Box>

            <Box sx={{ 
              p: 3,
              backgroundColor: 'rgba(255,255,255,0.05)',
              borderRadius: 2,
              border: '1px solid rgba(255,255,255,0.1)'
            }}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ 
                    color: '#fff',
                    textAlign: 'center',
                    mb: 1
                  }}>
                    Total de Solicitudes:
                  </Typography>
                  <Typography variant="h4" sx={{ 
                    color: '#b70a20',
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}>
                    {statistics.totalRequests}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PlaybackStatus; 