import React from 'react';
import { Box, Typography, TextField, Paper, InputAdornment } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import SoundcloudIcon from '@mui/icons-material/Audiotrack';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const SocialMediaForm = ({ formData, errors, handleChange }) => {
  const socialFields = [
    {
      name: 'instagramUrl',
      label: 'Instagram',
      icon: <InstagramIcon />,
      color: '#e4405f',
      placeholder: '@usuario',
      required: true
    },
    {
      name: 'soundcloudUrl',
      label: 'Soundcloud',
      icon: <SoundcloudIcon />,
      color: '#ff7700',
      placeholder: 'soundcloud.com/usuario'
    },
    {
      name: 'youtubeUrl',
      label: 'YouTube',
      icon: <YouTubeIcon />,
      color: '#ff0000',
      placeholder: 'youtube.com/@usuario'
    },
    {
      name: 'facebookUrl',
      label: 'Facebook',
      icon: <FacebookIcon />,
      color: '#1877f2',
      placeholder: 'facebook.com/usuario'
    },
    {
      name: 'tiktokUrl',
      label: 'TikTok',
      icon: <MusicNoteIcon />,
      color: '#ffffff',
      placeholder: '@usuario'
    },
    {
      name: 'spotifyUrl',
      label: 'Spotify',
      icon: <MusicNoteIcon />,
      color: '#1db954',
      placeholder: 'open.spotify.com/artist/...'
    }
  ];

  return (
    <Paper 
      elevation={3}
      sx={{
        p: 3,
        bgcolor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
          transform: 'translateY(-2px)'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <ShareIcon sx={{ color: '#b70a20', mr: 2, fontSize: 28 }} />
        <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
          Redes Sociales
        </Typography>
      </Box>

      <Box sx={{ 
        display: 'grid', 
        gap: 3,
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }
      }}>
        {socialFields.map((field) => (
          <Box
            key={field.name}
            sx={{
              position: 'relative',
              '&:hover': {
                '& .MuiInputAdornment-root': {
                  transform: 'scale(1.1)',
                }
              }
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              label={field.label}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              error={!!errors[field.name]}
              helperText={errors[field.name]}
              required={field.required}
              placeholder={field.placeholder}
              InputProps={{
                startAdornment: (
                  <InputAdornment 
                    position="start"
                    sx={{ 
                      transition: 'transform 0.2s ease',
                      '& .MuiSvgIcon-root': {
                        color: field.color,
                        fontSize: '1.5rem'
                      }
                    }}
                  >
                    {field.icon}
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiFilledInput-root': {
                  bgcolor: 'rgba(255, 255, 255, 0.05)',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.08)',
                  },
                  '&::before, &::after': {
                    borderBottomColor: field.color
                  },
                  '&:hover:not(.Mui-disabled):before': {
                    borderBottomColor: field.color
                  }
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&.Mui-focused': {
                    color: field.color
                  }
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                  pl: 1
                },
                '& .MuiFormHelperText-root': {
                  color: 'rgba(255, 255, 255, 0.5)'
                }
              }}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default SocialMediaForm; 