import React, { useState } from 'react';
import { Box, Typography, Button, Modal, IconButton, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import { SportsBar, Close, ContentCopy } from '@mui/icons-material';
import coronaLogo from '../../../assets/corona.png';
import heinekenLogo from '../../../assets/heineken.png';
import pilsenLogo from '../../../assets/pilsen.png';
import YAPE from '../../../assets/yape.png';

const BEER_OPTIONS = [
  { name: 'Corona', price: 10, logo: coronaLogo },
  { name: 'Heineken', price: 7, logo: heinekenLogo },
  { name: 'Pilsen', price: 5, logo: pilsenLogo },
];

const SongRequestModalTips = ({ open, handleClose, djInfo, tableNumber }) => {
  const [selectedBeer, setSelectedBeer] = useState(null);
  const [copyMessage, setCopyMessage] = useState("");
  const [showBeerOptions, setShowBeerOptions] = useState(false);
  const [beerClickCount, setBeerClickCount] = useState(0);

  const trackBeerButtonClick = () => {
    try {
      setBeerClickCount(prev => prev + 1);
      window.gtag('event', 'click_beer_button_modal', {
        event_category: 'DJ Tips',
        event_label: djInfo?.Name || 'DJ Desconocido',
        dj_name: djInfo?.Name || 'DJ Desconocido',
        timestamp: new Date().toISOString(),
        value: 1,
        click_count: beerClickCount + 1
      });
    } catch (error) {
      console.error('Error al registrar evento de cerveza:', error);
    }
  };

  const handleBeerClick = () => {
    setShowBeerOptions(true);
    trackBeerButtonClick();
  };

  const handleSelectBeer = (beer) => {
    setSelectedBeer(beer);
  };

  const handleModalClose = () => {
    handleClose();
    setSelectedBeer(null);
    setShowBeerOptions(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopyMessage("¡Número copiado al portapapeles!");
        setTimeout(() => setCopyMessage(""), 3000);
      })
      .catch(() => {
        setCopyMessage("Error al copiar, inténtalo de nuevo.");
        setTimeout(() => setCopyMessage(""), 3000);
      });
  };

  const renderInitialContent = () => (
    <>
      <Typography variant="h6" component="h3" sx={{ fontSize: '1.1rem', textAlign: 'center', marginBottom: 2, color: '#ffffff' }}>
        {tableNumber ? (
          <>
            🎉 Mesa Nº {tableNumber}, ¡Tu canción fue sugerida!<br/> 
            <span style={{ fontWeight: 'bold', color: '#ffffff', fontSize: '1.3rem', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
              El DJ la tendrá en consideración.
            </span>
          </>
        ) : (
          <>
            🎉 ¡Tu canción fue sugerida!<br/> 
            <span style={{ fontWeight: 'bold', color: '#ffffff', fontSize: '1.3rem', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
              El DJ la tendrá en consideración.
            </span>
          </>
        )}
      </Typography>

      <Button 
        fullWidth 
        variant="contained"
        startIcon={<SportsBar />}
        onClick={handleBeerClick}
        sx={{
          marginTop: 2,
          backgroundColor: '#D4AF37',
          background: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 50%, #D4AF37 70%)',
          backgroundSize: '200% 200%',
          animation: 'shimmer 2s infinite linear',
          '@keyframes shimmer': {
            '0%': { backgroundPosition: '200% 0' },
            '100%': { backgroundPosition: '-200% 0' }
          },
          color: '#000',
          fontWeight: 700,
          '&:hover': {
            backgroundColor: '#D4AF37',
            background: 'linear-gradient(45deg, #FFD700 30%, #FDB931 50%, #FFD700 70%)',
            boxShadow: '0 0 20px rgba(212, 175, 55, 0.5)',
          }
        }}
      >
        Invítale una cerveza al DJ
      </Button>

      <Button 
        fullWidth 
        variant="contained" 
        color="primary" 
        onClick={handleModalClose} 
        sx={{ marginTop: 2, backgroundColor: '#54A772' }}
      >
        Pedir otra canción
      </Button>

      {djInfo.InstagramLink && djInfo.InstagramHandle && (
        <>
          <Typography variant="h6" component="h3" sx={{ fontSize: '1rem', textAlign: 'center', marginTop: 3, color: '#ffffff' }}>
            ¡Síguenos en Instagram!
          </Typography>
          <Button
            component={Link}
            to={djInfo.InstagramLink}
            fullWidth
            variant="contained"
            sx={{
              marginTop: 2,
              background: 'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
              color: '#ffffff',
              '&:hover': {
                background: 'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
                opacity: 0.9,
              },
            }}
          >
            <InstagramIcon sx={{ marginRight: 1 }} /> {djInfo.InstagramHandle}
          </Button>
        </>
      )}
    </>
  );

  const renderBeerSelection = () => (
    <>
      <IconButton
        onClick={() => setShowBeerOptions(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'grey.500',
          '&:hover': { color: 'grey.300' }
        }}
      >
        <Close />
      </IconButton>

      {!selectedBeer ? (
        <>
          <Box sx={{ mb: 2, mt: 1 }}>
            <Typography sx={{ textAlign: 'center', color: '#D4AF37', fontSize: '1.2rem', fontWeight: 700, mb: 1 }}>
              ¡OJO!
            </Typography>
            <Typography sx={{ textAlign: 'center', color: '#fff', fontSize: '0.95rem', lineHeight: 1.4, opacity: 0.9 }}>
              Esto no garantiza que el DJ ponga tu canción.<br />
              <span style={{ fontWeight: 'bold' }}> Pero le refrescas la noche. </span>
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, mt: 3 }}>
            {BEER_OPTIONS.map((beer) => (
              <Button
                key={beer.name}
                variant="contained"
                onClick={() => handleSelectBeer(beer)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px 16px',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  },
                  borderRadius: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <img 
                    src={beer.logo} 
                    alt={beer.name} 
                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                  />
                  <Typography sx={{ color: '#fff', fontWeight: 500 }}>
                    {beer.name}
                  </Typography>
                </Box>
                <Typography sx={{ color: '#D4AF37', fontWeight: 600 }}>
                  S/ {beer.price}
                </Typography>
              </Button>
            ))}
          </Box>
        </>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ textAlign: 'center', color: '#ffffff', fontSize: '1.2rem', fontWeight: 700, mb: 3 }}>
            Cerveza seleccionada:
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2, 
            mb: 3,
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            padding: 2,
            borderRadius: 2
          }}>
            <img 
              src={selectedBeer.logo} 
              alt={selectedBeer.name} 
              style={{ width: '60px', height: '60px', objectFit: 'contain' }}
            />
            <Box>
              <Typography sx={{ color: '#fff', fontWeight: 600 }}>
                {selectedBeer.name}
              </Typography>
              <Typography sx={{ color: '#D4AF37', fontWeight: 600 }}>
                S/ {selectedBeer.price}
              </Typography>
            </Box>
          </Box>

          {copyMessage && (
            <Alert
              severity="info"
              sx={{
                mb: 2,
                textAlign: 'center',
                backgroundColor: 'rgba(227, 242, 253, 0.1)',
                color: '#fff',
              }}
            >
              {copyMessage}
            </Alert>
          )}

          <Typography sx={{ textAlign: 'center', color: '#D4AF37', fontSize: '1rem', fontWeight: 600, mb: 2 }}>
            Copia el número y <br/>ve a tu aplicativo
          </Typography>

          <Box sx={{
            textAlign: 'left',
            mb: 3,
            p: 2,
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Box display="flex" alignItems="center">
              <Box component="img" src={YAPE} alt="Logo de Yape" sx={{ width: 70, height: 45, mr: 2, borderRadius: '4px' }} />
              <Box>
                <Typography variant="subtitle1" sx={{ color: '#fff' }}>
                  Pago por <strong>Yape</strong> o <strong>Plin</strong>
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  A nombre de: {djInfo.NameReal}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Número: {djInfo.Numero}
                </Typography>
              </Box>
            </Box>
            <Button 
              onClick={() => copyToClipboard(djInfo.Numero)} 
              sx={{ minWidth: 'auto', p: 0, color: '#fff' }}
            >
              <ContentCopy fontSize="small" />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );

  return (
    <Modal 
      open={open} 
      onClose={null}
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 320,
        bgcolor: 'rgba(34, 34, 34, 1)',
        border: 'none',
        boxShadow: 24,
        p: 4,
        borderRadius: 8,
      }}>
        {showBeerOptions ? renderBeerSelection() : renderInitialContent()}
      </Box>
    </Modal>
  );
};

export default SongRequestModalTips; 