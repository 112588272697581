import React, { useState, useCallback, useMemo } from "react";
import { 
  Box, 
  Typography, 
  Container, 
  Paper, 
  Grid, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Stack, 
  Button,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme,
  Fab,
  Divider,
  Modal,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import WorkIcon from "@mui/icons-material/Work";
import CollectionsIcon from "@mui/icons-material/Collections";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import Navbar from "../components/Navbar";

// Componentes
import BasicInfoForm from "../components/ProfileDJProduct/forms/BasicInfoForm";
import MultimediaForm from "../components/ProfileDJProduct/forms/MultimediaForm";
import ProfessionalInfoForm from "../components/ProfileDJProduct/forms/ProfessionalInfoForm";
import GalleryLocalesForm from "../components/ProfileDJProduct/forms/GalleryLocalesForm";
import SocialMediaForm from "../components/ProfileDJProduct/forms/SocialMediaForm";
import PaymentStepDJProfile from "../components/Payment/PaymentStepDJProfile";

// Mover las constantes fuera del componente
const INITIAL_FORM_DATA = {
  djName: "",
  tagline: "",
  phone: "",
  email: "",
  photo: null,
  preview: null,
  coverPhoto: null,
  coverPhotoPreview: null,
  bio: "",
  experiencia: "",
  lugar: "",
  precioMin: "",
  precioMax: "",
  gallery: [],
  galleryPreviews: [],
  locales: "",
  soundcloudUrl: "",
  youtubeUrl: "",
  instagramUrl: "",
  facebookUrl: "",
  tiktokUrl: "",
  spotifyUrl: "",
};

const INITIAL_ERRORS = {
  djName: "",
  phone: "",
  email: "",
  photo: "",
  coverPhoto: "",
  bio: "",
  experiencia: "",
  lugar: "",
  precioMin: "",
  precioMax: "",
  locales: "",
  instagramUrl: "",
};

const INITIAL_COMPLETED_SECTIONS = {
  basicInfo: false,
  multimedia: false,
  professional: false,
  gallery: false,
  social: false
};

const ProgressSidebar = ({ completedSections, isMobile, onClose }) => {
  const totalSections = Object.keys(completedSections).length;
  const completedCount = Object.values(completedSections).filter(Boolean).length;
  const progress = (completedCount / totalSections) * 100;

  const sections = [
    {
      id: 'basicInfo',
      label: 'Información Básica',
      icon: <PersonIcon />,
      description: 'Nombre, teléfono y correo',
      completed: completedSections.basicInfo
    },
    {
      id: 'multimedia',
      label: 'Multimedia',
      icon: <PhotoLibraryIcon />,
      description: 'Foto de perfil y portada',
      completed: completedSections.multimedia
    },
    {
      id: 'professional',
      label: 'Información Profesional',
      icon: <WorkIcon />,
      description: 'Experiencia y tarifas',
      completed: completedSections.professional
    },
    {
      id: 'gallery',
      label: 'Galería y Locales',
      icon: <CollectionsIcon />,
      description: 'Fotos y lugares donde tocas',
      completed: completedSections.gallery
    },
    {
      id: 'social',
      label: 'Redes Sociales',
      icon: <ShareIcon />,
      description: 'Enlaces a tus redes',
      completed: completedSections.social
    }
  ].sort((a, b) => {
    // Ordenar las secciones completadas primero
    if (a.completed && !b.completed) return -1;
    if (!a.completed && b.completed) return 1;
    return 0;
  });

  return (
    <Box sx={{ p: 3, height: '100%' }}>
      {isMobile && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, alignItems: 'center' }}>
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
            Progreso ({completedCount}/{totalSections})
          </Typography>
          <IconButton onClick={onClose} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" sx={{ color: 'grey.400' }}>
            Completado
          </Typography>
          <Typography variant="body2" sx={{ color: 'white', fontWeight: 600 }}>
            {Math.round(progress)}%
          </Typography>
        </Box>
        <Box sx={{ 
          width: '100%', 
          height: 4, 
          bgcolor: 'rgba(255,255,255,0.1)', 
          borderRadius: 2,
          overflow: 'hidden'
        }}>
          <Box sx={{ 
            width: `${progress}%`, 
            height: '100%', 
            bgcolor: '#b70a20',
            transition: 'width 0.3s ease'
          }} />
        </Box>
      </Box>

      <List sx={{ py: 0 }}>
        {sections.map((section, index) => (
          <React.Fragment key={section.id}>
            {index > 0 && (
              <Divider sx={{ my: 1, borderColor: 'rgba(255,255,255,0.1)' }} />
            )}
            <ListItem 
              sx={{ 
                py: 1.5,
                px: 2,
                borderRadius: 2,
                transition: 'all 0.3s ease',
                '&:hover': {
                  bgcolor: 'rgba(255,255,255,0.05)'
                }
              }}
            >
              <ListItemIcon sx={{ 
                color: section.completed ? '#4caf50' : 'grey.500',
                minWidth: 40
              }}>
                {section.completed ? <CheckCircleIcon /> : section.icon}
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: 'white',
                      fontWeight: section.completed ? 600 : 400
                    }}
                  >
                    {section.label}
                  </Typography>
                }
                secondary={
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: section.completed ? '#4caf50' : 'grey.500',
                      fontSize: '0.75rem'
                    }}
                  >
                    {section.description}
                  </Typography>
                }
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

const ProfileDJRegistration = () => {
  // Hooks básicos
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  
  // Estados
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [completedSections, setCompletedSections] = useState(INITIAL_COMPLETED_SECTIONS);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  // Valores memorizados
  const selectedPrice = useMemo(() => location.state?.selectedPrice, [location.state]);

  // Handlers optimizados
  const handleClosePaymentModal = useCallback(() => setPaymentModalOpen(false), []);

  const handleDrawerToggle = useCallback(() => setDrawerOpen(prev => !prev), []);

  const validateSection = useCallback((sectionName, data) => {
    const validations = {
      basicInfo: () => data.djName && data.phone && data.email,
      multimedia: () => data.profilePhoto,
      professional: () => data.experiencia && data.lugar && data.precioMin && data.precioMax,
      gallery: () => data.gallery.length > 0 && data.locales,
      social: () => data.instagramUrl
    };

    const isValid = validations[sectionName] ? validations[sectionName]() : false;

    setCompletedSections(prev => ({
      ...prev,
      [sectionName]: isValid
    }));

    return isValid;
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    const sectionValidations = {
      djName: 'basicInfo',
      phone: 'basicInfo',
      email: 'basicInfo',
      experiencia: 'professional',
      lugar: 'professional',
      precioMin: 'professional',
      precioMax: 'professional',
      locales: 'gallery',
    };

    const section = Object.entries(sectionValidations).find(([key]) => name.includes(key))?.[1];
    if (section) {
      validateSection(section, { ...formData, [name]: value });
    } else if (name.includes('Url')) {
      validateSection('social', { ...formData, [name]: value });
    }
  }, [formData, validateSection]);

  const handleFileUpload = useCallback((e, fieldName) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData(prev => ({
        ...prev,
        [fieldName]: file,
        [`${fieldName}Preview`]: reader.result
      }));
      
      if (fieldName === 'profilePhoto' || fieldName === 'coverPhoto') {
        setTimeout(() => {
          validateSection('multimedia', {
            ...formData,
            [fieldName]: file
          });
        }, 0);
      }
    };
    reader.readAsDataURL(file);
  }, [formData, validateSection]);

  const handleGalleryUpload = useCallback((files) => {
    const processFiles = async () => {
      const fileArray = Array.from(files);
      const previews = await Promise.all(
        fileArray.map(file => new Promise(resolve => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        }))
      );

      setFormData(prev => ({
        ...prev,
        gallery: [...prev.gallery, ...fileArray],
        galleryPreviews: [...prev.galleryPreviews, ...previews]
      }));

      validateSection('gallery', { 
        ...formData, 
        gallery: [...formData.gallery, ...fileArray] 
      });
    };

    processFiles();
  }, [formData, validateSection]);

  const handleRemoveImage = useCallback((index) => {
    setFormData(prev => {
      const newGallery = [...prev.gallery];
      const newPreviews = [...prev.galleryPreviews];
      newGallery.splice(index, 1);
      newPreviews.splice(index, 1);
      return { ...prev, gallery: newGallery, galleryPreviews: newPreviews };
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (Object.values(completedSections).every(Boolean)) {
      setPaymentModalOpen(true);
    }
  }, [completedSections]);

  // Componentes memorizados
  const PaymentModal = useMemo(() => (
    <Modal 
      open={paymentModalOpen} 
      onClose={null}
      disableEscapeKeyDown
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(3px)'
        }
      }}
    >
      <Box sx={{ 
        position: "absolute", 
        top: "50%", 
        left: "50%", 
        transform: "translate(-50%, -50%)", 
        width: "90%", 
        maxWidth: 600,
        maxHeight: '90vh',
        overflow: 'auto',
        bgcolor: "#121212",
        borderRadius: 3,
        border: '1px solid rgba(255,255,255,0.1)',
        boxShadow: "0 8px 32px rgba(0,0,0,0.5)",
        p: 4,
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(255,255,255,0.05)'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#b70a20',
          borderRadius: '4px',
          '&:hover': {
            background: '#8f0816'
          }
        }
      }}>
        <IconButton 
          onClick={handleClosePaymentModal} 
          sx={{ 
            position: "absolute", 
            top: 10, 
            right: 10, 
            color: "rgba(255,255,255,0.7)",
            transition: "all 0.3s ease",
            "&:hover": {
              color: "#ffffff",
              backgroundColor: "rgba(183, 10, 32, 0.1)"
            }
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h5" sx={{ mb: 3, color: 'white', fontWeight: 600 }}>
          Finalizar Registro
        </Typography>

        <PaymentStepDJProfile 
          onClose={handleClosePaymentModal} 
          selectedPrice={selectedPrice} 
          formData={formData}
        />
      </Box>
    </Modal>
  ), [paymentModalOpen, handleClosePaymentModal, selectedPrice, formData]);

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" sx={{ py: 4, minHeight: '100vh' }}>
        <Grid container spacing={3}>
          {/* Sidebar de progreso para desktop */}
          {!isMobile && (
            <Grid item md={3}>
              <Paper 
                elevation={3}
                sx={{
                  position: 'sticky',
                  top: 88,
                  border: '1px solid rgba(255,255,255,0.1)',
                  mt: 8,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.3)'
                  }
                }}
              >
                <ProgressSidebar completedSections={completedSections} />
              </Paper>
            </Grid>
          )}

          {/* Formulario principal */}
          <Grid item xs={12} md={9}>
            <Box sx={{ mt: { xs: 6, md: 8 } }}>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h4" sx={{ color: 'white', mb: 2, fontWeight: 700 }}>
                Crea tu propia página web de DJ
                </Typography>
                <Typography variant="body1" sx={{ color: 'grey.400' }}>
                Completa toda la información para destacar tu página y empezar a recibir reservas.
                </Typography>
              </Box>

              <Stack spacing={4}>
                <BasicInfoForm
                  formData={formData}
                  errors={INITIAL_ERRORS}
                  handleChange={handleChange}
                  onValidate={() => validateSection('basicInfo', formData)}
                />

                <MultimediaForm
                  formData={formData}
                  errors={INITIAL_ERRORS}
                  handleFileUpload={handleFileUpload}
                  onValidate={() => validateSection('multimedia', formData)}
                />

                <ProfessionalInfoForm
                  formData={formData}
                  errors={INITIAL_ERRORS}
                  handleChange={handleChange}
                  onValidate={() => validateSection('professional', formData)}
                />

                <GalleryLocalesForm
                  formData={formData}
                  errors={INITIAL_ERRORS}
                  handleGalleryUpload={handleGalleryUpload}
                  handleChange={handleChange}
                  onValidate={() => validateSection('gallery', formData)}
                  handleRemoveImage={handleRemoveImage}
                />

                <SocialMediaForm
                  formData={formData}
                  errors={INITIAL_ERRORS}
                  handleChange={handleChange}
                  onValidate={() => validateSection('social', formData)}
                />

                <Paper 
                  elevation={3}
                  sx={{
                    p: 3,
                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid rgba(255,255,255,0.1)'
                  }}
                >
                  <Box>
                    <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
                      ¿Todo listo?
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                      Revisa que toda la información esté correcta antes de continuar
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    size="large"
                    disabled={!Object.values(completedSections).every(Boolean)}
                    onClick={handleSubmit}
                    sx={{
                      bgcolor: '#b70a20',
                      '&:hover': { bgcolor: '#8f0816' },
                      '&.Mui-disabled': {
                        bgcolor: 'rgba(183, 10, 32, 0.3)'
                      }
                    }}
                  >
                    Finalizar y Pagar
                  </Button>
                </Paper>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Fab para móviles */}
      {isMobile && (
        <Fab
          color="primary"
          aria-label="ver progreso"
          onClick={handleDrawerToggle}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            bgcolor: '#b70a20',
            '&:hover': { bgcolor: '#8f0816' }
          }}
        >
          <AssignmentIcon />
        </Fab>
      )}

      {/* Drawer para móviles */}
      <Drawer
        anchor="right"
        open={isMobile && drawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 360,
            bgcolor: '#121212',
          }
        }}
      >
        <ProgressSidebar 
          completedSections={completedSections} 
          isMobile={true}
          onClose={handleDrawerToggle}
        />
      </Drawer>

      {PaymentModal}
    </>
  );
};

export default ProfileDJRegistration; 