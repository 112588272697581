import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Stack, Divider } from "@mui/material";
import PaymentForm from './PaymentFormQR';
import { registerDJProfilePayment, registerInternationalDJProfilePayment } from "../../api/airtable";
import { uploadFileToCloudinary } from "../../api/cloudinary";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';

const PaymentStepDJProfile = ({ onClose, selectedPrice, formData }) => {
  const navigate = useNavigate();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPeru, setIsPeru] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");

  const trackProfilePaymentStart = () => {
    try {
      window.gtag('event', 'profile_payment_start', {
        event_category: 'Pagos',
        event_label: 'Inicio de Pago Perfil DJ',
        price: selectedPrice,
        dj_name: formData.djName,
        payment_type: isPeru ? 'Nacional' : 'Internacional',
        plan_type: selectedPrice.includes("70") ? "Anual" : "Mensual",
        has_gallery: formData.gallery.length > 0,
        has_cover_photo: !!formData.coverPhoto,
        timestamp: new Date().toISOString(),
        value: parseFloat(selectedPrice.replace(/[^0-9.]/g, ''))
      });
    } catch (error) {
      console.error('Error al registrar inicio de pago de perfil:', error);
    }
  };

  const trackProfilePaymentConfirmation = (success) => {
    try {
      window.gtag('event', success ? 'profile_payment_success' : 'profile_payment_error', {
        event_category: 'Pagos',
        event_label: success ? 'Pago Exitoso Perfil DJ' : 'Error en Pago Perfil DJ',
        price: selectedPrice,
        dj_name: formData.djName,
        payment_type: isPeru ? 'Nacional' : 'Internacional',
        plan_type: selectedPrice.includes("70") ? "Anual" : "Mensual",
        has_payment_proof: fileUploaded,
        gallery_size: formData.gallery.length,
        has_cover_photo: !!formData.coverPhoto,
        timestamp: new Date().toISOString(),
        value: parseFloat(selectedPrice.replace(/[^0-9.]/g, ''))
      });
    } catch (error) {
      console.error('Error al registrar confirmación de pago de perfil:', error);
    }
  };

  const trackWhatsAppContact = () => {
    try {
      window.gtag('event', 'profile_whatsapp_contact', {
        event_category: 'Contacto',
        event_label: 'Contacto WhatsApp Post-Registro',
        dj_name: formData.djName,
        timestamp: new Date().toISOString(),
        value: 1
      });
    } catch (error) {
      console.error('Error al registrar contacto WhatsApp:', error);
    }
  };

  useEffect(() => {
    trackProfilePaymentStart();
    const phoneNumber = formData.phone || "";
    setIsPeru(phoneNumber.startsWith("+51") || phoneNumber.startsWith("51"));
  }, [formData.phone]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopyMessage("¡Número copiado al portapapeles!");
        setTimeout(() => setCopyMessage(""), 3000);
      })
      .catch(() => {
        setCopyMessage("Error al copiar, inténtalo de nuevo.");
        setTimeout(() => setCopyMessage(""), 3000);
      });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      try {
        const uploadedFileUrl = await uploadFileToCloudinary(file);
        setFileUploaded(true);
        setFileName(file.name);
        formData.paymentFile = uploadedFileUrl;
        setErrorMessage("");
      } catch (error) {
        setErrorMessage("Hubo un error al subir el archivo.");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleConfirm = async () => {
    if (isPeru && !fileUploaded) {
      setErrorMessage("Debe subir un comprobante antes de confirmar el pago.");
      return;
    }

    try {
      setConfirming(true);
      setErrorMessage("");

      // Subir todas las imágenes a Cloudinary
      const uploadedProfilePhoto = await uploadFileToCloudinary(formData.profilePhoto);
      const uploadedCoverPhoto = formData.coverPhoto ? await uploadFileToCloudinary(formData.coverPhoto) : null;
      const uploadedGallery = await Promise.all(formData.gallery.map(file => uploadFileToCloudinary(file)));

      const currentDate = new Date();
      const formattedPurchaseDate = currentDate.toLocaleDateString("es-PE");
      const username = formData.djName.toLowerCase().replace(/\s+/g, '-');

      const paymentData = {
        ...formData,
        profilePhoto: uploadedProfilePhoto,
        coverPhoto: uploadedCoverPhoto,
        gallery: uploadedGallery,
        plan: selectedPrice.includes("70") ? "Anual" : "Mensual",
        price: selectedPrice,
        paymentDate: formattedPurchaseDate,
        username: username
      };

      if (isPeru) {
        await registerDJProfilePayment(paymentData);
      } else {
        await registerInternationalDJProfilePayment(paymentData);
      }

      trackProfilePaymentConfirmation(true);
      setShowThankYouMessage(true);
      setPaymentCompleted(true);
    } catch (error) {
      console.error("Error al confirmar el pago:", error);
      trackProfilePaymentConfirmation(false);
      setErrorMessage("Ocurrió un error al registrar el pago. Inténtelo nuevamente.");
    } finally {
      setConfirming(false);
    }
  };

  const handleGoHome = () => {
    navigate('/');
  };

  const handleWhatsApp = () => {
    trackWhatsAppContact();
    const message = encodeURIComponent(
      `¡Hola! Soy ${formData.djName}, acabo de registrarme como DJ en la plataforma y quisiera confirmar mi pago.`
    );
    window.open(`https://wa.me/51945429543?text=${message}`, '_blank');
  };

  return (
    <Box sx={{
      maxWidth: "600px",
      width: "100%",
      maxHeight: "70vh",
      overflowY: "auto",
      p: 3,
      backgroundColor: "#f5f5f5",
      borderRadius: 3,
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      animation: "fadeIn 0.8s ease-out",
    }}>
      {showThankYouMessage || paymentCompleted ? (
        <Box sx={{ textAlign: "center" }}>
          <CheckCircleIcon 
            sx={{ 
              fontSize: 80, 
              color: "#4caf50", 
              mb: 2,
              animation: "bounce 1s ease"
            }} 
          />
          
          <Typography variant="h4" sx={{ 
            fontWeight: "bold", 
            color: "#4caf50", 
            mb: 2,
            animation: "fadeIn 0.8s ease-out"
          }}>
            ¡Registro Exitoso!
          </Typography>

          <Typography variant="h6" sx={{ color: "#333", mb: 3 }}>
            Gracias por unirte a nuestra comunidad, {formData.djName}
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Stack spacing={2} sx={{ mb: 4 }}>
            <Typography variant="body1" sx={{ color: "#555", fontWeight: 500 }}>
              Próximos pasos:
            </Typography>
            <Typography variant="body2" sx={{ color: "#666" }}>
              1. Revisaremos tu información y fotos
            </Typography>
            <Typography variant="body2" sx={{ color: "#666" }}>
              2. Activaremos tu perfil en las próximas 24 horas
            </Typography>
            <Typography variant="body2" sx={{ color: "#666" }}>
              3. Te notificaremos cuando tu perfil esté activo
            </Typography>
          </Stack>

          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2} 
            justifyContent="center"
            sx={{ mt: 3 }}
          >
            <Button
              variant="contained"
              startIcon={<WhatsAppIcon />}
              onClick={handleWhatsApp}
              sx={{
                bgcolor: '#25D366',
                '&:hover': { bgcolor: '#128C7E' },
                color: 'white',
                fontWeight: 'bold'
              }}
            >
              Contactar por WhatsApp
            </Button>

            <Button
              variant="outlined"
              startIcon={<HomeIcon />}
              onClick={handleGoHome}
              sx={{
                borderColor: '#b70a20',
                color: '#b70a20',
                '&:hover': {
                  borderColor: '#8f0816',
                  bgcolor: 'rgba(183, 10, 32, 0.1)'
                }
              }}
            >
              Volver al Inicio
            </Button>
          </Stack>

          <Typography 
            variant="caption" 
            sx={{ 
              display: 'block', 
              mt: 4, 
              color: '#666',
              fontSize: '0.75rem'
            }}
          >
            Si tienes alguna duda, no dudes en contactarnos
          </Typography>
        </Box>
      ) : (
        <PaymentForm
          selectedPrice={selectedPrice}
          isPeru={isPeru}
          copyMessage={copyMessage}
          handleFileUpload={handleFileUpload}
          uploading={uploading}
          fileUploaded={fileUploaded}
          fileName={fileName}
          errorMessage={errorMessage}
          handleConfirm={handleConfirm}
          confirming={confirming}
          onClose={onClose}
          copyToClipboard={copyToClipboard}
        />
      )}
    </Box>
  );
};

// Mantener las animaciones
const styles = `
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default PaymentStepDJProfile; 