import React from 'react';
import {
  Typography,
  Card,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';

const cardStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  p: 3,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
};

const SongList = ({ 
  tableData, 
  page, 
  rowsPerPage = 50,
  handleChangePage, 
  handleChangeRowsPerPage 
}) => {
  return (
    <Card sx={cardStyles}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Typography 
          variant="h5" 
          sx={{ 
            color: '#FFF',
            fontWeight: 'bold',
            flex: 1 
          }}
        >
          Lista completa de peticiones
        </Typography>
      </Box>
      
      <TableContainer component={Paper} sx={{ 
        backgroundColor: 'transparent',
        maxHeight: 650,
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(255, 255, 255, 0.05)',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#D32F2F',
          borderRadius: '4px',
        },
      }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                'N°',
                'Canción',
                'Artista',
                'Género',
                'Hora de la petición',
                'Hora de la marcación',
                'Acción'
              ].map((header) => (
                <TableCell 
                  key={header}
                  sx={{ 
                    backgroundColor: '#1e1e1e', 
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #D32F2F'
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow 
                  key={index} 
                  sx={{
                    '&:nth-of-type(odd)': {
                      backgroundColor: 'rgba(255, 255, 255, 0.02)',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(211, 47, 47, 0.1)',
                      transition: 'background-color 0.2s ease',
                    },
                    cursor: 'pointer'
                  }}
                >
                  <TableCell sx={{ color: '#fff' }}>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{row.song}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{row.artist}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{row.genre}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{row.timeOfRequest}</TableCell>
                  <TableCell sx={{ color: '#fff' }}>{row.dialingTime}</TableCell>
                  <TableCell>
                    <Box sx={{ 
                      color: row.rawOption === 'se puso' ? '#4ECDC4' : 
                             row.rawOption === 'No la quiero poner' ? '#D32F2F' : 
                             '#FFC107',
                      fontWeight: 'medium',
                      display: 'inline-block',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      backgroundColor: row.rawOption === 'se puso' ? 'rgba(78, 205, 196, 0.1)' : 
                                     row.rawOption === 'No la quiero poner' ? 'rgba(211, 47, 47, 0.1)' : 
                                     'rgba(255, 193, 7, 0.1)',
                    }}>
                      {row.option}
                    </Box>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <TablePagination
        component="div"
        count={tableData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100, 200, 500]}
        sx={{ 
          color: '#fff',
          '.MuiTablePagination-selectIcon': { color: '#fff' },
          '.MuiTablePagination-select': { 
            color: '#fff',
            '&:focus': {
              backgroundColor: 'rgba(211, 47, 47, 0.1)'
            }
          },
          '.MuiTablePagination-actions button': {
            color: '#fff',
            '&:hover': {
              backgroundColor: 'rgba(211, 47, 47, 0.1)'
            }
          }
        }}
        labelRowsPerPage="Filas mostradas:"
        labelDisplayedRows={() => 'Cambiar página'}
      />
    </Card>
  );
};

export default SongList; 