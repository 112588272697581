import React from 'react';
import { Box, Typography, Divider, Button, Alert, Stack } from '@mui/material';
import PaymentMethods from './PaymentMethods';
import FileUpload from './FileUpload';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';

const PaymentForm = ({
  selectedPrice,
  isPeru,
  copyMessage,
  handleFileUpload,
  uploading,
  fileUploaded,
  fileName,
  errorMessage,
  handleConfirm,
  confirming,
  onClose,
  copyToClipboard,
  showThankYouMessage,
  paymentCompleted,
  formData
}) => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  const handleWhatsApp = () => {
    const message = encodeURIComponent(
      `¡Hola! Soy ${formData?.djName || ''}, acabo de generar mi QR personalizado y quisiera confirmar mi pago.`
    );
    window.open(`https://wa.me/51945429543?text=${message}`, '_blank');
  };

  if (showThankYouMessage || paymentCompleted) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CheckCircleIcon 
          sx={{ 
            fontSize: 80, 
            color: "#4caf50", 
            mb: 2,
            animation: "bounce 1s ease"
          }} 
        />
        
        <Typography variant="h4" sx={{ 
          fontWeight: "bold", 
          color: "#4caf50", 
          mb: 2,
          animation: "fadeIn 0.8s ease-out"
        }}>
          ¡Pago Exitoso!
        </Typography>

        <Typography variant="h6" sx={{ color: "#333", mb: 3 }}>
          {isPeru 
            ? "Tu QR personalizado estará listo muy pronto" 
            : "Nos contactaremos contigo para coordinar los detalles"}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Stack spacing={2} sx={{ mb: 4 }}>
          <Typography variant="body1" sx={{ color: "#555", fontWeight: 500 }}>
            Próximos pasos:
          </Typography>
          {isPeru ? (
            <>
              <Typography variant="body2" sx={{ color: "#666" }}>
                1. Verificaremos tu pago
              </Typography>
              <Typography variant="body2" sx={{ color: "#666" }}>
                2. Generaremos tu QR personalizado
              </Typography>
              <Typography variant="body2" sx={{ color: "#666" }}>
                3. Te enviaremos tu QR por WhatsApp
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body2" sx={{ color: "#666" }}>
                1. Nos contactaremos contigo
              </Typography>
              <Typography variant="body2" sx={{ color: "#666" }}>
                2. Coordinaremos el método de pago
              </Typography>
              <Typography variant="body2" sx={{ color: "#666" }}>
                3. Generaremos y enviaremos tu QR
              </Typography>
            </>
          )}
        </Stack>

        <Stack 
          direction={{ xs: 'column', sm: 'row' }} 
          spacing={2} 
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <Button
            variant="contained"
            startIcon={<WhatsAppIcon />}
            onClick={handleWhatsApp}
            sx={{
              bgcolor: '#25D366',
              '&:hover': { bgcolor: '#128C7E' },
              color: 'white',
              fontWeight: 'bold'
            }}
          >
            Contactar por WhatsApp
          </Button>

          <Button
            variant="outlined"
            startIcon={<HomeIcon />}
            onClick={handleGoHome}
            sx={{
              borderColor: '#b70a20',
              color: '#b70a20',
              '&:hover': {
                borderColor: '#8f0816',
                bgcolor: 'rgba(183, 10, 32, 0.1)'
              }
            }}
          >
            Volver al Inicio
          </Button>
        </Stack>

        <Typography 
          variant="caption" 
          sx={{ 
            display: 'block', 
            mt: 4, 
            color: '#666',
            fontSize: '0.75rem'
          }}
        >
          Si tienes alguna duda, no dudes en contactarnos
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
        Realiza tu Pago
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: '#ff5722', fontWeight: 'bold', textAlign: 'center', mt: 2 }}
      >
        {`${selectedPrice}`}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: '#555', mb: 2, textAlign: 'center' }}>
        Completa tu pago para activar tu cuenta y disfrutar de todos los beneficios.
      </Typography>
      <Divider sx={{ my: 2 }} />

      {isPeru ? (
        <>
          <PaymentMethods copyMessage={copyMessage} copyToClipboard={copyToClipboard} />
          <FileUpload
            handleFileUpload={handleFileUpload}
            uploading={uploading}
            fileUploaded={fileUploaded}
            fileName={fileName}
          />
        </>
      ) : (
        <Typography variant="body1" sx={{ color: '#555', textAlign: 'center', fontWeight: 'bold' }}>
          El número proporcionado no es de Perú. Nos contactaremos contigo al número proporcionado.
        </Typography>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button variant="outlined" color="secondary" fullWidth onClick={onClose} sx={{ fontWeight: 'bold' }}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleConfirm}
          disabled={confirming}
          sx={{
            fontWeight: 'bold',
            backgroundColor: confirming ? '#e0e0e0' : '#4caf50',
            color: confirming ? '#212121' : '#ffffff',
            '&:hover': { backgroundColor: confirming ? '#bdbdbd' : '#388e3c' },
          }}
        >
          {confirming ? <CircularProgress size={24} sx={{ color: '#ffffff' }} /> : 'Confirmar y Finalizar'}
        </Button>
      </Box>
    </>
  );
};

export default PaymentForm; 