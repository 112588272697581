import React from 'react';
import { Box, Typography } from '@mui/material';
import LogoExtendido from '../assets/Logo DJ Ponla extendido (sin fondo).png';
import { Link } from 'react-router-dom';

const FooterLogo = () => (
  <Box sx={{ 
    textAlign: 'center', 
    marginBottom: 0,
    '& img': {
      width: { xs: '180px', sm: '150px' },
      height: 'auto',
      borderRadius: 2,
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.05)'
      }
    }
  }}>
    <Typography 
      variant="subtitle1" 
      sx={{ 
        fontSize: '0.8rem',
        marginBottom: 1,
        marginTop: 2,
        color: 'rgba(255,255,255,0.7)'
      }}
    >
      Creado por:
    </Typography>
    <Link to="/">
      <img src={LogoExtendido} alt='Logo DJPonla' />
    </Link>
  </Box>
);

export default FooterLogo;
