import React from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Paper,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const DJForm = ({ formData, errors, handleChange, handleFileUpload, handleNext }) => {
  const handlePhoneChange = (phone) => {
    handleChange({ target: { name: 'phone', value: phone } });
  };

  return (
    <Paper 
      elevation={3}
      sx={{
        p: 3,
        bgcolor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
          transform: 'translateY(-2px)'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
          Información del DJ
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="filled"
            label="Nombre de DJ"
            name="djName"
            value={formData.djName}
            onChange={handleChange}
            error={!!errors.djName}
            helperText={errors.djName}
            sx={{
              '& .MuiFilledInput-root': {
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.08)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)'
              },
              '& .MuiInputBase-input': {
                color: 'white'
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="filled"
            label="Link de Instagram"
            name="instagramLink"
            value={formData.instagramLink}
            onChange={handleChange}
            error={!!errors.instagramLink}
            helperText={errors.instagramLink}
            sx={{
              '& .MuiFilledInput-root': {
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.08)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)'
              },
              '& .MuiInputBase-input': {
                color: 'white'
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <PhoneInput
            country="pe"
            value={formData.phone}
            onChange={handlePhoneChange}
            inputStyle={{
              width: '100%',
              height: '56px',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              color: 'white',
              border: 'none',
              borderRadius: '4px 4px 0 0',
              padding: '20px 0 6px 48px'
            }}
            buttonStyle={{
              backgroundColor: 'transparent',
              border: 'none',
              borderRadius: '4px 0 0 0',
              padding: '20px 0 6px 8px'
            }}
            containerStyle={{
              color: 'white'
            }}
            dropdownStyle={{
              backgroundColor: '#121212',
              color: 'white'
            }}
          />
          {errors.phone && (
            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
              {errors.phone}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="filled"
            label="Correo Electrónico"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            sx={{
              '& .MuiFilledInput-root': {
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.08)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)'
              },
              '& .MuiInputBase-input': {
                color: 'white'
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            fullWidth
            startIcon={<PhotoCameraIcon />}
            sx={{
              bgcolor: formData.photo ? '#4caf50' : '#115beb',
              color: 'white',
              p: 1.5,
              transition: 'all 0.3s ease',
              '&:hover': {
                bgcolor: formData.photo ? '#45a049' : '#0d47a1',
                transform: 'translateY(-2px)'
              }
            }}
          >
            {formData.photo ? 'Foto Cargada' : 'Cargar Foto'}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileUpload}
            />
          </Button>
        </Grid>

        {formData.photo && (
          <Grid item xs={12}>
            <Box sx={{ 
              p: 2, 
              bgcolor: 'rgba(76, 175, 80, 0.1)',
              borderRadius: 1,
              border: '1px solid rgba(76, 175, 80, 0.2)'
            }}>
              <Typography variant="body2" sx={{ color: '#4caf50' }}>
                ¡Foto cargada con éxito!
              </Typography>
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleNext}
            sx={{
              bgcolor: '#b70a20',
              color: 'white',
              p: 1.5,
              transition: 'all 0.3s ease',
              '&:hover': {
                bgcolor: '#8f0816',
                transform: 'translateY(-2px)'
              }
            }}
          >
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DJForm;