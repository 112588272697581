import React from 'react';
import {
  Grid,
  Typography,
  Card,
  Box,
  useTheme,
  useMediaQuery,
  LinearProgress,
  IconButton,
  Collapse,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const cardStyles = {
  backgroundColor: "rgba(34, 34, 34, 0.95)",
  backdropFilter: "blur(20px)",
  borderRadius: '24px',
  p: 3,
  minHeight: { xs: 'auto', md: '500px' },
  boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)'
  }
};

const GenreAnalysis = ({ genreAnalysis }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedGenre, setExpandedGenre] = React.useState(null);

  // Top 5 para el gráfico de barras
  const top5Genres = genreAnalysis.slice(0, 5);

  const MobileGenreCard = ({ genre, index, total }) => {
    const isExpanded = expandedGenre === index;
    const isTop5 = index < 5;
    
    return (
      <Box 
        sx={{ 
          mb: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '12px',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          onClick={() => setExpandedGenre(isExpanded ? null : index)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {isTop5 && (
              <Typography 
                sx={{ 
                  color: '#b70a20',
                  fontWeight: 'bold',
                  minWidth: '24px'
                }}
              >
                #{index + 1}
              </Typography>
            )}
            <Box>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: isTop5 ? '#b70a20' : '#fff',
                  fontSize: '1rem',
                  fontWeight: 'bold'
                }}
              >
                {genre.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                {genre.total} solicitudes ({((genre.total/total) * 100).toFixed(1)}%)
              </Typography>
            </Box>
          </Box>
          <IconButton size="small" sx={{ color: '#fff' }}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>

        <Collapse in={isExpanded}>
          <Box sx={{ p: 2, pt: 0 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ color: '#fff', mb: 0.5 }}>
                Tasa de reproducción: {genre.playRate}%
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={parseFloat(genre.playRate)}
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: isTop5 ? '#b70a20' : '#FF6B6B'
                  },
                  borderRadius: 2,
                  height: 8
                }}
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Se puso: {genre.played}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  No la quiero poner: {genre.notPlayed}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Artistas: {genre.artistCount}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  Canciones: {genre.songCount}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>
    );
  };

  // Calcular el total de solicitudes para los porcentajes
  const totalRequests = genreAnalysis.reduce((acc, genre) => acc + genre.total, 0);

  const BarChartComponent = ({ height = 320 }) => (
    <Box sx={{ height: height }}>
      <ResponsiveContainer>
        <BarChart
          data={top5Genres}
          margin={isMobile ? 
            { top: 15, right: 20, left: -20, bottom: 85 }
            : 
            { top: 15, right: 30, left: 20, bottom: 85 }
          }
          barGap={0}
          barCategoryGap="15%"
        >
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
          <XAxis 
            dataKey="name" 
            angle={45} 
            textAnchor="start" 
            height={70}
            stroke="#fff"
            interval={0}
            tick={{ 
              fill: '#fff', 
              fontSize: 11,
              dy: 10
            }}
            tickFormatter={(value) => {
              return value.length > 15 ? value.substring(0, 15) + '...' : value;
            }}
          />
          <YAxis 
            stroke="#fff"
            tick={{ 
              fill: '#fff',
              fontSize: 11
            }}
            width={isMobile ? 35 : 45}
          />
          <Tooltip 
            contentStyle={{ 
              backgroundColor: '#1e1e1e', 
              border: '1px solid rgba(255,255,255,0.1)',
              borderRadius: '12px',
              boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
              fontSize: isMobile ? '12px' : '14px'
            }}
            labelStyle={{ color: '#fff', fontWeight: 'bold' }}
            itemStyle={{ color: '#fff' }}
            formatter={(value, name) => [
              `${value} canciones`,
              name === "Se puso" ? "Se puso ✅" : "No la quiero poner ❌"
            ]}
          />
          <Legend 
            verticalAlign="bottom"
            height={35}
            wrapperStyle={{ 
              color: '#fff',
              paddingTop: '30px',
              fontSize: '0.95rem'
            }}
            formatter={(value) => (
              <span style={{ color: '#fff', fontSize: '0.95rem' }}>
                {value === "Se puso" ? "Se puso ✅" : "No la quiero poner ❌"}
              </span>
            )}
          />
          <Bar 
            name="Se puso" 
            dataKey="played" 
            fill="#b70a20" 
            radius={[4, 4, 0, 0]}
          />
          <Bar 
            name="No la quiero poner" 
            dataKey="notPlayed" 
            fill="#FF6B6B"
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card sx={{
          ...cardStyles,
          minHeight: { xs: 'auto', md: '400px' }
        }}>
          <Typography variant="h5" sx={{ 
            mb: 4, 
            color: '#fff', 
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', md: '1.5rem' },
          }}>
            Top 5 Géneros Más Solicitados
          </Typography>
          <BarChartComponent height={isMobile ? 400 : 320} />
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card sx={cardStyles}>
          <Typography variant="h5" sx={{ 
            mb: 4, 
            color: '#fff', 
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', md: '1.5rem' },
          }}>
            Todos los Géneros ({genreAnalysis.length})
          </Typography>
          <Box sx={{ 
            overflowY: 'auto', 
            maxHeight: isMobile ? 'calc(100vh - 450px)' : 400,
            pr: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'rgba(255,255,255,0.1)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#b70a20',
              borderRadius: '4px',
            }
          }}>
            {genreAnalysis.map((genre, index) => (
              <MobileGenreCard 
                key={index} 
                genre={genre} 
                index={index}
                total={totalRequests}
              />
            ))}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GenreAnalysis; 