import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ConnectIcon from '@mui/icons-material/Group';
import SupportIcon from '@mui/icons-material/Headphones';
import StatsIcon from '@mui/icons-material/BarChart';
import FollowersIcon from '@mui/icons-material/TrendingUp';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const benefits = [
  {
    icon: <ConnectIcon sx={{ fontSize: 40, color: '#b70a20' }} />,
    title: "Conexión con tu público",
    description: "Tu gente ama ser escuchada, toca su canción y verás como creas un vínculo increíble."
  },
  {
    icon: <SupportIcon sx={{ fontSize: 40, color: '#b70a20' }} />,
    title: "Soporte en vivo",
    description: "Con la lista en vivo sabes qué géneros y canciones priorizar, manteniendo la pista encendida."
  },
  {
    icon: <StatsIcon sx={{ fontSize: 40, color: '#b70a20' }} />,
    title: "Estadísticas de canciones",
    description: "Con esta información afinas tus mezclas y consigue mejores contratos."
  },
  {
    icon: <FollowersIcon sx={{ fontSize: 40, color: '#b70a20' }} />,
    title: "Aumento de seguidores",
    description: "Cada sugerencia es una oportunidad, tu público ve tu perfil y te siguen, tu alcance se dispara."
  },
  {
    icon: <MoneyIcon sx={{ fontSize: 40, color: '#b70a20' }} />,
    title: "Cobrar más caro",
    description: "Los DJs que usan DJ PONLA cobran 10% - 20% más, porque brindan una experiencia única."
  }
];

const BenefitsSection = () => {
  const navigate = useNavigate();

  const handlePricingClick = () => {
    navigate('/qr-dj-product');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Box id="benefits-section" sx={{ py: { xs: 2 }, mb:5, backgroundColor: '#111111' }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography 
            variant="h3" 
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: '#ffffff',
              mb: 3,
              fontSize:{xs: '2.2rem', md: '2.9rem'}
            }}
          >
            Beneficios que{' '}
            <Box component="span" sx={{ color: '#b70a20' }}>
              impulsan tu carrera
            </Box>
          </Typography>
          
          <Typography 
            variant="h6"
            sx={{
              color: '#A6A5A6',
              maxWidth: '800px',
              mx: 'auto',
              mb: 4,
              lineHeight: 1.8
            }}
          >
            Descubre cómo DJ PONLA transforma tu manera de conectar con el público y potencia tu éxito como DJ.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                sx={{
                  p: 3,
                  height: '100%',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: 2,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.08)'
                  }
                }}
              >
                <Box sx={{ mb: 2 }}>{benefit.icon}</Box>
                <Typography 
                  variant="h5" 
                  gutterBottom
                  sx={{ color: '#ffffff', fontWeight: 600 }}
                >
                  {benefit.title}
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{ color: '#A6A5A6' }}
                >
                  {benefit.description}
                </Typography>
              </Box>
            </Grid>
          ))}
          
          {/* Card botón actualizado con animación */}
          <Grid item xs={12} md={4}>
            <Box
              onClick={handlePricingClick}
              sx={{
                p: 3,
                height: '100%',
                background: `linear-gradient(135deg, #b70a20 0%, #8f0816 100%)`,
                borderRadius: 2,
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  '&::before': {
                    transform: 'translateX(100%)',
                  },
                  '& .rocket-icon': {
                    transform: 'translateY(-10px)',
                  }
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: '-100%',
                  width: '100%',
                  height: '100%',
                  background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
                  transition: 'transform 0.8s',
                  animation: 'shine 3s infinite',
                },
                '@keyframes shine': {
                  '0%': {
                    transform: 'translateX(-100%)',
                  },
                  '100%': {
                    transform: 'translateX(100%)',
                  },
                },
                '@keyframes float': {
                  '0%, 100%': {
                    transform: 'translateY(0)',
                  },
                  '50%': {
                    transform: 'translateY(-10px)',
                  },
                }
              }}
            >
              <RocketLaunchIcon 
                className="rocket-icon"
                sx={{ 
                  fontSize: 60, 
                  color: '#ffffff', 
                  mb: 2,
                  transition: 'transform 0.3s ease',
                  animation: 'float 3s ease-in-out infinite',
                }} 
              />
              <Typography 
                variant="body1"
                sx={{ 
                  color: '#ffffff', 
                  textAlign: 'center',
                  opacity: 0.9,
                  mb: 3,
                  fontSize: '1.1rem'
                }}
              >
                Únete a la revolución de los DJs y empieza a ponerla con DJ PONLA
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  borderRadius: '30px',
                  py: 1.2,
                  px: 3.5,
                  display: 'inline-block',
                  transition: 'all 0.3s ease',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
                  animation: 'pulse 2s infinite ease-in-out',
                  '@keyframes pulse': {
                    '0%': {
                      transform: 'scale(1)',
                    },
                    '50%': {
                      transform: 'scale(1.08)',
                    },
                    '100%': {
                      transform: 'scale(1)',
                    }
                  },
                  '&:hover': {
                    animation: 'none',
                    transform: 'scale(1.12)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.3)'
                  }
                }}
              >
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: '#b70a20', 
                    fontWeight: 700, 
                    textAlign: 'center',
                    position: 'relative',
                    textTransform: 'uppercase',
                    letterSpacing: '1px',
                    fontSize: '0.85rem'
                  }}
                >
                  ¡Quiero Ponerla!
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BenefitsSection; 