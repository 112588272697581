import React from 'react';
import { Box, Typography, TextField, Paper } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';

const ProfessionalInfoForm = ({ formData, errors, handleChange }) => {
  return (
    <Paper 
      elevation={3}
      sx={{
        p: 3,
        bgcolor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
          transform: 'translateY(-2px)'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <WorkIcon sx={{ color: '#b70a20', mr: 2, fontSize: 28 }} />
        <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
          Información Profesional
        </Typography>
      </Box>

      <Box sx={{ display: 'grid', gap: 3 }}>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="filled"
          label="Biografía"
          name="bio"
          value={formData.bio}
          onChange={handleChange}
          error={!!errors.bio}
          helperText={errors.bio}
          sx={{
            '& .MuiFilledInput-root': {
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.08)',
              }
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.7)'
            },
            '& .MuiInputBase-input': {
              color: 'white'
            }
          }}
        />

        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
          <TextField
            fullWidth
            variant="filled"
            label="Años de experiencia"
            name="experiencia"
            type="number"
            value={formData.experiencia}
            onChange={handleChange}
            error={!!errors.experiencia}
            helperText={errors.experiencia}
            sx={{
              '& .MuiFilledInput-root': {
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.08)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)'
              },
              '& .MuiInputBase-input': {
                color: 'white'
              }
            }}
          />

          <TextField
            fullWidth
            variant="filled"
            label="Ubicación"
            name="lugar"
            value={formData.lugar}
            onChange={handleChange}
            error={!!errors.lugar}
            helperText={errors.lugar}
            sx={{
              '& .MuiFilledInput-root': {
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.08)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)'
              },
              '& .MuiInputBase-input': {
                color: 'white'
              }
            }}
          />
        </Box>

        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
          <TextField
            fullWidth
            variant="filled"
            label="Precio Mínimo (S/.)"
            name="precioMin"
            type="number"
            value={formData.precioMin}
            onChange={handleChange}
            error={!!errors.precioMin}
            helperText={errors.precioMin}
            sx={{
              '& .MuiFilledInput-root': {
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.08)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)'
              },
              '& .MuiInputBase-input': {
                color: 'white'
              }
            }}
          />

          <TextField
            fullWidth
            variant="filled"
            label="Precio Máximo (S/.)"
            name="precioMax"
            type="number"
            value={formData.precioMax}
            onChange={handleChange}
            error={!!errors.precioMax}
            helperText={errors.precioMax}
            sx={{
              '& .MuiFilledInput-root': {
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.08)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)'
              },
              '& .MuiInputBase-input': {
                color: 'white'
              }
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default ProfessionalInfoForm; 