import React from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  Link as MuiLink,
  Avatar,
} from '@mui/material';
import Navbar from '../components/Navbar';
import InstagramIcon from '@mui/icons-material/Instagram';
import AbrahamImage from '../assets/Abraham.jpeg';
import RobertoImage from '../assets/Roberto.jpeg';
import { useNavigate } from 'react-router-dom';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import SEO from '../components/SEO';

const teamMembers = [
  {
    name: 'Roberto Salazar',
    role: 'Founder and CEO',
    image: RobertoImage,
    linkedin: 'https://www.linkedin.com/in/roberto-salazarg/',
  },
  {
    name: 'Abraham Huacchillo',
    role: 'Founder and CTO',
    image: AbrahamImage,
    linkedin: 'https://www.linkedin.com/in/abraham-huacchillo/',
  },
];

const TeamMemberCard = ({ member }) => (
  <Card
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 4,
      overflow: 'hidden',
      alignItems: 'center',
      textAlign: 'center',
      transition: 'transform 0.3s',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: 6,
      },
      padding: 2,
    }}
  >
    <Avatar
      src={member.image}
      alt={`Foto de ${member.name}`}
      sx={{ width: 120, height: 120, mt: 2, mb: 2 }}
    />
    <CardContent sx={{ flexGrow: 1 }}>
      <MuiLink href={member.linkedin} target="_blank" underline="none" color="inherit">
        <Typography variant="h6" gutterBottom>
          {member.name}
        </Typography>
      </MuiLink>
      <Typography variant="body2" color="text.secondary">
        {member.role}
      </Typography>
    </CardContent>
  </Card>
);

const AboutUs = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handlePricingClick = () => {
    navigate('/comprar');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Datos estructurados
  const aboutStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "DJ Ponla",
    "url": "https://djponla.com",
    "logo": "https://djponla.com/LogoDJP.png",
    "description": "DJ Ponla conecta DJs y público a través de un sistema de QR para sugerencias musicales en tiempo real.",
    "foundingDate": "2022",
    "founders": [{
      "@type": "Person",
      "name": "Fundador de DJ Ponla"
    }],
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "customer service",
      "email": "contacto@djponla.com"
    }
  };

  // Breadcrumb items
  const breadcrumbItems = [
    { name: 'Inicio', link: '/' },
    { name: 'Nosotros', link: '/nosotros' }
  ];

  return (
    <>
      <SEO 
        title="Sobre Nosotros | DJ Ponla - Nuestra historia y misión" 
        description="Conoce a DJ Ponla, la plataforma que conecta DJs con su público. Nuestra misión es transformar la experiencia musical y crear conexiones más auténticas."
        keywords="DJ Ponla, equipo DJ Ponla, misión DJ Ponla, historia DJ Ponla, tecnología musical"
        canonicalUrl="/nosotros"
        structuredData={aboutStructuredData}
      />
      
      <Navbar />
      <Container>
        <BreadcrumbSchema items={breadcrumbItems} />
        
        <Box sx={{ mt: { xs: 7, sm: 8 } }}>
          <Container maxWidth="lg">
            {/* Sección Hero */}
            <Box
              sx={{
                textAlign: 'center',
                py: 8,
                px: 2,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                borderRadius: '10%',
                mb: 5,
              }}
            >
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  fontFamily: "'Concert One', cursive",
                  textShadow: '2px 2px 4px rgba(0,0,0,0.6)',
                }}
              >
                Conoce DJ Ponla
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontStyle: 'italic',
                  mb: 3,
                }}
              >
                ¡La fiesta no para y nosotros tampoco!
              </Typography>
              <Button
                variant="contained"
                href="https://www.instagram.com/djponla/"
                target="_blank"
                startIcon={<InstagramIcon />}
                sx={{
                  borderRadius: 4,
                  background: 'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
                  color: theme.palette.common.white,
                  fontWeight: 'bold',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
                    opacity: 0.9,
                  },
                }}
              >
                @djponla
              </Button>
            </Box>

            {/* Historia */}
            <Box sx={{ my: 5 }}>
              <Typography variant="h4" align="center" gutterBottom>
                Nuestra Historia
              </Typography>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      p: 4,
                      borderRadius: 4,
                      boxShadow: 3,
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      El Comienzo
                    </Typography>
                    <Typography variant="body1">
                      Todo comenzó en una fiesta donde nos dimos cuenta de que la música es el alma
                      de cualquier celebración. Desde entonces, nos propusimos conectar a los mejores
                      DJs con quienes buscan momentos inolvidables. 🎶
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      p: 4,
                      borderRadius: 4,
                      boxShadow: 3,
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      Nuestro Crecimiento
                    </Typography>
                    <Typography variant="body1">
                      Hemos crecido gracias a nuestra pasión por la música y el compromiso con la
                      diversión. Cada evento es una nueva oportunidad para crear recuerdos inolvidables.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Valores */}
            <Box sx={{ my: 5 }}>
              <Typography variant="h4" align="center" gutterBottom>
                Nuestros Valores
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      background: `linear-gradient(135deg, ${theme.palette.primary.light} 30%, ${theme.palette.secondary.light} 90%)`,
                      p: 3,
                      borderRadius: 4,
                      boxShadow: 3,
                      color: theme.palette.common.white,
                      height: '100%',
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      Pasión por la Música
                    </Typography>
                    <Typography>
                      Vivimos y respiramos música. Es nuestra motivación y lo que nos impulsa a seguir
                      innovando.
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      background: `linear-gradient(135deg, ${theme.palette.secondary.light} 30%, ${theme.palette.primary.light} 90%)`,
                      p: 3,
                      borderRadius: 4,
                      boxShadow: 3,
                      color: theme.palette.common.white,
                      height: '100%',
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      Innovación y Diversión
                    </Typography>
                    <Typography>
                      Siempre buscamos nuevas formas de hacer que cada evento sea único y memorable.
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Box>

            {/* Equipo */}
            <Box sx={{ my: 5 }}>
              <Typography variant="h4" align="center" gutterBottom>
                Nuestro Equipo
              </Typography>
              <Typography variant="body1" align="center" sx={{ mb: 3 }}>
                Conoce a los cerebros (y corazones) detrás de DJ Ponla.
              </Typography>
              <Grid container spacing={4}>
                {teamMembers.map((member, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <TeamMemberCard member={member} />
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Llamado a la acción final */}
            <Box
              sx={{
                textAlign: 'center',
                my: 5,
                py: 5,
                px: 2,
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                borderRadius: 4,
              }}
            >
              <Typography variant="h5" gutterBottom>
                Únete a nuestra comunidad y vive la experiencia DJ Ponla
              </Typography>
              <Button 
                onClick={handlePricingClick}
                color="secondary" 
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: '#115beb',
                  color: theme.palette.common.white,
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#0e4da6',
                  },
                }}
              >
                ¡Comprar Ahora!
              </Button>
            </Box>
          </Container>
        </Box>
      </Container>
    </>
  );
};

export default AboutUs;
