import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import PaymentFormQR from './PaymentFormQR';
import { registerPaymentQR, registerInternationalPaymentQR } from "../../api/airtable";
import { uploadFileToCloudinary } from "../../api/cloudinary";

const PaymentStep = ({ onClose, selectedPrice, formData }) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPeru, setIsPeru] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");

  const trackPaymentStart = () => {
    try {
      window.gtag('event', 'payment_start', {
        event_category: 'Pagos',
        event_label: 'Inicio de Pago',
        price: selectedPrice,
        dj_name: formData.djName,
        payment_type: isPeru ? 'Nacional' : 'Internacional',
        plan_type: selectedPrice === "S/.35" ? "Mensual" : "Anual",
        timestamp: new Date().toISOString(),
        value: parseFloat(selectedPrice.replace(/[^0-9.]/g, ''))
      });
    } catch (error) {
      console.error('Error al registrar inicio de pago:', error);
    }
  };

  const trackPaymentConfirmation = (success) => {
    try {
      window.gtag('event', success ? 'payment_success' : 'payment_error', {
        event_category: 'Pagos',
        event_label: success ? 'Pago Exitoso' : 'Error en Pago',
        price: selectedPrice,
        dj_name: formData.djName,
        payment_type: isPeru ? 'Nacional' : 'Internacional',
        plan_type: selectedPrice === "S/.35" ? "Mensual" : "Anual",
        has_payment_proof: fileUploaded,
        timestamp: new Date().toISOString(),
        value: parseFloat(selectedPrice.replace(/[^0-9.]/g, ''))
      });
    } catch (error) {
      console.error('Error al registrar confirmación de pago:', error);
    }
  };

  useEffect(() => {
    trackPaymentStart();
    const phoneNumber = formData.phone || "";
    setIsPeru(phoneNumber.startsWith("+51") || phoneNumber.startsWith("51"));
  }, [formData.phone]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopyMessage("¡Número copiado al portapapeles!");
        setTimeout(() => setCopyMessage(""), 3000);
      })
      .catch(() => {
        setCopyMessage("Error al copiar, inténtalo de nuevo.");
        setTimeout(() => setCopyMessage(""), 3000);
      });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      try {
        const uploadedFileUrl = await uploadFileToCloudinary(file);
        setFileUploaded(true);
        setFileName(file.name);
        formData.paymentFile = uploadedFileUrl;
        setErrorMessage("");
      } catch (error) {
        setErrorMessage("Hubo un error al subir el archivo.");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleConfirm = async () => {
    if (isPeru && !fileUploaded) {
      setErrorMessage("Debe subir un comprobante antes de confirmar el pago.");
      return;
    }

    try {
      setConfirming(true);
      setErrorMessage("");

      if (formData.photo && typeof formData.photo !== "string") {
        const uploadedPhotoUrl = await uploadFileToCloudinary(formData.photo);
        formData.photo = uploadedPhotoUrl;
      }

      const currentDate = new Date();
      const formattedPurchaseDate = currentDate.toLocaleDateString("es-PE");

      const paymentData = {
        djName: formData.djName,
        instagramLink: formData.instagramLink,
        phone: formData.phone,
        email: formData.email,
        plan: selectedPrice === "S/.35" ? "Mensual" : "Anual",
        price: selectedPrice,
        paymentDate: formattedPurchaseDate,
        photo: formData.photo,
      };

      if (isPeru) {
        paymentData.paymentFile = formData.paymentFile;
        await registerPaymentQR(paymentData);
      } else {
        await registerInternationalPaymentQR(paymentData);
      }

      trackPaymentConfirmation(true);
      setShowThankYouMessage(true);
      setPaymentCompleted(true);
    } catch (error) {
      console.error("Error al confirmar el pago:", error);
      trackPaymentConfirmation(false);
      setErrorMessage("Ocurrió un error al registrar el pago. Inténtelo nuevamente.");
    } finally {
      setConfirming(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "600px",
        width: "100%",
        maxHeight: "70vh",
        overflowY: "auto",
        p: 3,
        backgroundColor: "#f5f5f5",
        borderRadius: 3,
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        animation: "fadeIn 0.8s ease-out",
      }}
    >
      <PaymentFormQR
        selectedPrice={selectedPrice}
        isPeru={isPeru}
        copyMessage={copyMessage}
        handleFileUpload={handleFileUpload}
        uploading={uploading}
        fileUploaded={fileUploaded}
        fileName={fileName}
        errorMessage={errorMessage}
        handleConfirm={handleConfirm}
        confirming={confirming}
        onClose={onClose}
        copyToClipboard={copyToClipboard}
        showThankYouMessage={showThankYouMessage}
        paymentCompleted={paymentCompleted}
        formData={formData}
      />
    </Box>
  );
};

const styles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default PaymentStep;
