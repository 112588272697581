import React, { useState } from "react";
import { Box, Modal, Stepper, Step, StepLabel, IconButton } from "@mui/material";
import DJForm from "./DJForm";
import DJPreview from "./DJPreview";
import PaymentStep from "../Payment/PaymentStep";
import CloseIcon from "@mui/icons-material/Close";

const DJModal = ({ open, onClose, selectedPrice }) => {
  const [step, setStep] = useState(0); // Mantener el índice del paso actual
  const [formData, setFormData] = useState({
    djName: "",
    instagramLink: "",
    phone: "",
    email: "",
    photo: null,
    preview: null,
  });

  const [errors, setErrors] = useState({
    djName: "",
    instagramLink: "",
    phone: "",
    email: "",
    photo: "",
  });

  const handleNext = () => {
    const newErrors = {
      djName: formData.djName ? "" : "El nombre es obligatorio.",
      instagramLink: formData.instagramLink ? "" : "El link de Instagram es obligatorio.",
      phone: formData.phone ? "" : "El número de teléfono es obligatorio.",
      email: formData.email ? "" : "El correo electrónico es obligatorio.",
      photo: formData.photo ? "" : "La foto es obligatoria.",
    };

    setErrors(newErrors);

    // Verificación del valor del teléfono antes de realizar cualquier operación
    const phone = formData.phone ? String(formData.phone) : "";
    if (!newErrors.djName && !newErrors.instagramLink && !newErrors.phone && !newErrors.email && !newErrors.photo) {
      if (phone.slice(0, 2) === "+5") {
        setStep((prev) => prev + 1); // Avanzar al siguiente paso
      } else {
        setErrors({ ...newErrors, phone: "Te contactaremos al número que has proporcionado." });
        setStep((prev) => prev + 1); // Continuar con el siguiente paso si lo deseas
      }
    }
  };

  const handleBack = () => setStep((prev) => prev - 1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, photo: file, preview: reader.result });
        setErrors({ ...errors, photo: "" });
      };
      reader.readAsDataURL(file);
    }
  };

  const steps = ["Información del DJ", "Vista previa", "Pago"];

  return (
    <Modal 
      open={open} 
      onClose={null}
      disableEscapeKeyDown
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(3px)'
        }
      }}
    >
      <Box sx={{ 
        position: "absolute", 
        top: "50%", 
        left: "50%", 
        transform: "translate(-50%, -50%)", 
        width: "90%", 
        maxWidth: 600,
        maxHeight: '90vh',
        overflow: 'auto',
        bgcolor: "#121212",
        borderRadius: 3,
        border: '1px solid rgba(255,255,255,0.1)',
        boxShadow: "0 8px 32px rgba(0,0,0,0.5)",
        p: 4,
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(255,255,255,0.05)'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#b70a20',
          borderRadius: '4px',
          '&:hover': {
            background: '#8f0816'
          }
        }
      }}>
        <IconButton 
          onClick={onClose} 
          sx={{ 
            position: "absolute", 
            top: 10, 
            right: 10, 
            color: "rgba(255,255,255,0.7)",
            transition: "all 0.3s ease",
            "&:hover": {
              color: "#ffffff",
              backgroundColor: "rgba(183, 10, 32, 0.1)"
            }
          }}
        >
          <CloseIcon />
        </IconButton>

        <Stepper
          activeStep={step}
          alternativeLabel
          sx={{
            mb: 4,
            "& .MuiStepConnector-root": {
              "& .MuiStepConnector-line": {
                borderColor: "rgba(255,255,255,0.1)"
              }
            },
            "& .MuiStepIcon-root": {
              color: "rgba(255,255,255,0.2)",
              transition: "all 0.3s ease",
            },
            "& .MuiStepIcon-root.Mui-active": {
              color: "#b70a20",
              transform: "scale(1.2)"
            },
            "& .MuiStepIcon-root.Mui-completed": {
              color: "#4caf50",
            },
            "& .MuiStepLabel-label": {
              color: "rgba(255,255,255,0.7)",
              "&.Mui-active": {
                color: "#ffffff",
                fontWeight: "bold"
              },
              "&.Mui-completed": {
                color: "#4caf50"
              }
            }
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 2 }}>
          {step === 0 && (
            <DJForm
              formData={formData}
              errors={errors}
              handleChange={handleChange}
              handleFileUpload={handleFileUpload}
              handleNext={handleNext}
            />
          )}

          {step === 1 && (
            <DJPreview
              formData={formData}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )}

          {step === 2 && (
            <PaymentStep 
              onClose={onClose} 
              selectedPrice={selectedPrice} 
              formData={formData} 
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DJModal;
