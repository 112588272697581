import React from 'react';
import { Box, Container, Grid, Typography, IconButton, Link } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LogoExtendido from '../assets/Logo DJ Ponla extendido (sin fondo).png';
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (section) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 300);
    } else {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  };

  const menuItems = [
    { title: 'Testimonios', action: () => scrollToSection('testimonials') },
    { title: 'Cómo funciona', action: () => scrollToSection('how-it-works') },
    { title: 'Beneficios', action: () => scrollToSection('benefits-section') },
    { title: 'Estadísticas', action: () => scrollToSection('stats-section') },
    { title: 'Preguntas frecuentes', action: () => scrollToSection('faq-section') }
  ];

  return (
    <Box sx={{ 
      backgroundColor: '#111111',
      borderTop: '1px solid rgba(255,255,255,0.1)',
      py: 6 
    }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Logo y descripción */}
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 2 }}>
              <img 
                src={LogoExtendido} 
                alt="Logo DJ Ponla" 
                style={{ 
                  height: '70px',
                }} 
              />
            </Box>
            <Typography 
              variant="body2" 
              sx={{ 
                color: '#A6A5A6',
                mb: 3,
                lineHeight: 1.8
              }}
            >
              La plataforma líder de peticiones de canciones a nivel mundial. Poniéndola con los mejores DJs.
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton 
                href="https://instagram.com/djponla" 
                target="_blank"
                sx={{ 
                  color: '#ffffff',
                  '&:hover': { color: '#b70a20' }
                }}
              >
                <InstagramIcon />
              </IconButton>
              <IconButton 
                href="https://www.tiktok.com/@djponla" 
                target="_blank"
                sx={{ 
                  color: '#ffffff',
                  '&:hover': { color: '#b70a20' }
                }}
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="currentColor"
                >
                  <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
                </svg>
              </IconButton>
              <IconButton 
                href="https://www.facebook.com/profile.php?id=61573110691623" 
                target="_blank"
                sx={{ 
                  color: '#ffffff',
                  '&:hover': { color: '#b70a20' }
                }}
              >
                <FacebookIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Links de navegación */}
          <Grid item xs={12} md={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: '#ffffff',
                fontWeight: 700,
                mb: 2
              }}
            >
              Enlaces rápidos
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {menuItems.map((item, index) => (
                <Link
                  key={index}
                  onClick={item.action}
                  sx={{
                    color: '#A6A5A6',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#b70a20'
                    }
                  }}
                >
                  {item.title}
                </Link>
              ))}
            </Box>
          </Grid>

          {/* Información de contacto */}
          <Grid item xs={12} md={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: '#ffffff',
                fontWeight: 700,
                mb: 2
              }}
            >
              Contacto
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <EmailIcon sx={{ color: '#b70a20' }} />
                <Typography 
                  component="a"
                  href="mailto:djponla24@gmail.com"
                  sx={{ 
                    color: '#A6A5A6',
                    textDecoration: 'none',
                    '&:hover': { color: '#b70a20' }
                  }}
                >
                  djponla24@gmail.com
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PhoneIcon sx={{ color: '#b70a20' }} />
                <Typography 
                  component="a"
                  href="tel:+51945429543"
                  sx={{ 
                    color: '#A6A5A6',
                    textDecoration: 'none',
                    '&:hover': { color: '#b70a20' }
                  }}
                >
                  +51 945 429 543
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box sx={{ 
          mt: 6, 
          pt: 3, 
          borderTop: '1px solid rgba(255,255,255,0.1)',
          textAlign: 'center'
        }}>
          <Typography variant="body2" sx={{ color: '#A6A5A6' }}>
            © {new Date().getFullYear()} DJ PONLA. Todos los derechos reservados.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
