import React from 'react';
import { Box, Typography, Avatar, useTheme, useMediaQuery, Paper } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const Testimonials = ({ testimonials }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const renderTestimonials = () => {
    const items = [];
    for (let i = 0; i < testimonials.length; i += isDesktop ? 2 : 1) {
      const item = (
        <Box
          key={i}
          sx={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            gap: 4,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: { xs: '400px', md: '350px' },
            width: '100%',
            px: { xs: 2, md: 4 }
          }}
        >
          <TestimonialCard testimonial={testimonials[i]} />
          {isDesktop && i + 1 < testimonials.length && (
            <TestimonialCard testimonial={testimonials[i + 1]} />
          )}
        </Box>
      );
      items.push(item);
    }
    return items;
  };

  return (
    <Box 
      id="testimonials" 
      sx={{ 
        mb: 5,
        pt: 4,
        position: 'relative'
      }}
    >

      <Box sx={{ maxWidth: 1200, margin: '0 auto' }}>
        <Carousel
          autoPlay
          animation="slide"
          indicators={true}
          navButtonsAlwaysVisible={true}
          interval={6000}
          duration={500}
          sx={{
            '& .MuiIconButton-root': {
              color: '#ffffff',
              backgroundColor: 'rgba(183, 10, 32, 0.1)',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'rgba(183, 10, 32, 0.2)',
                transform: 'scale(1.1)'
              }
            }
          }}
          indicatorContainerProps={{
            style: {
              marginTop: '20px',
              zIndex: 1
            }
          }}
          indicatorIconButtonProps={{
            style: {
              color: 'rgba(183, 10, 32, 0.3)',
              padding: '5px',
              transition: 'all 0.3s ease'
            }
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: '#b70a20',
              transform: 'scale(1.2)'
            }
          }}
        >
          {renderTestimonials()}
        </Carousel>
      </Box>
    </Box>
  );
};

const TestimonialCard = ({ testimonial }) => (
  <Paper
    elevation={0}
    sx={{
      p: 4,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: 1,
      height: { xs: '380px', md: '380px' },
      borderRadius: '20px',
      background: 'rgba(255, 255, 255, 0.05)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      position: 'relative',
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
        background: 'rgba(255, 255, 255, 0.08)'
      }
    }}
  >
    <FormatQuoteIcon 
      sx={{ 
        position: 'absolute',
        top: 20,
        left: 20,
        fontSize: '2rem',
        color: 'rgba(183, 10, 32, 0.2)'
      }} 
    />
    
    <Box
      sx={{
        position: 'relative',
        mb: 3,
        mt: 2,
        '&::after': {
          content: '""',
          position: 'absolute',
          top: -2,
          left: -2,
          right: -2,
          bottom: -2,
          background: 'linear-gradient(45deg, #b70a20, #ffffff)',
          borderRadius: '50%',
          zIndex: 0
        }
      }}
    >
      <Avatar
        src={testimonial.image}
        alt={testimonial.name}
        sx={{ 
          width: 100,
          height: 100,
          border: '4px solid #121212',
          position: 'relative',
          zIndex: 1,
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)'
          }
        }}
      />
    </Box>

    <Typography 
      variant="h6" 
      gutterBottom
      sx={{
        fontWeight: 700,
        color: '#ffffff',
        mb: 2
      }}
    >
      {testimonial.name}
    </Typography>

    <Typography
      variant="body1"
      sx={{ 
        fontStyle: 'italic',
        color: 'rgba(255, 255, 255, 0.8)',
        lineHeight: 1.8,
        fontSize: '1rem',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 8,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis'
      }}
    >
      {testimonial.text}
    </Typography>
  </Paper>
);

export default Testimonials;
