import React, { useState, useEffect, useMemo } from 'react';
import { getDJRecords, getDJInfo } from '../api/airtable';
import {
  Container,
  Typography,
  Box,
  useMediaQuery,
  Tab,
  Tabs,
  Avatar,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
  Tooltip,
  Paper,
  IconButton,
  Fade,
  ButtonBase,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import GeneralStats from '../components/analytics/GeneralStats';
import ArtistAnalysis from '../components/analytics/ArtistAnalysis';
import GenreAnalysis from '../components/analytics/GenreAnalysis';
import TimeAnalysis from '../components/analytics/TimeAnalysis';
import PlaybackStatus from '../components/analytics/PlaybackStatus';
import SongList from '../components/analytics/SongList';
import FooterLogo from '../components/FooterLogo';
import Footer from '../components/Footer';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimelineIcon from '@mui/icons-material/Timeline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import DateRangeIcon from '@mui/icons-material/DateRange';

const DataAnalyticsView = () => {
  const { djId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Estados principales
  const [songs, setSongs] = useState([]);
  const [djInfo, setDjInfo] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedPeriod, setSelectedPeriod] = useState('all');
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [viewType, setViewType] = useState('all');
  const [animationDirection, setAnimationDirection] = useState('left');

  // Cargar datos iniciales
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [records, info] = await Promise.all([
          getDJRecords(djId),
          getDJInfo(`f${djId}m`)
        ]);
        setSongs(records);
        setDjInfo(info);
      } catch (error) {
        console.error('Error al cargar datos:', error);
      }
    };
    fetchData();
  }, [djId]);

  // Calcular semanas disponibles
  const calculateAvailableWeeks = useMemo(() => {
    if (!songs.length) return [];
    
    const dates = songs
      .map(song => new Date(song.fields['Time of request']))
      .filter(date => !isNaN(date));
    
    if (!dates.length) return [];

    const startDate = new Date(Math.min(...dates));
    const endDate = new Date(Math.max(...dates));
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    
    const weeks = [];
    let currentDate = new Date(startDate);
    
    // Ajustar al inicio de la semana (lunes)
    const dayOfWeek = currentDate.getDay();
    const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    currentDate.setDate(currentDate.getDate() + diff);
    
    while (currentDate <= endDate) {
      const weekStart = new Date(currentDate);
      const weekEnd = new Date(currentDate);
      weekEnd.setDate(weekEnd.getDate() + 6);
      weekEnd.setHours(23, 59, 59, 999);
      
      const songsInWeek = songs.filter(song => {
        const songDate = new Date(song.fields['Time of request']);
        return songDate >= weekStart && songDate <= weekEnd;
      });

      if (songsInWeek.length > 0) {
        weeks.push({
          id: weekStart.toISOString(),
          label: `${weekStart.toLocaleDateString('es-ES', { 
            day: 'numeric',
            month: 'short',
          })} - ${weekEnd.toLocaleDateString('es-ES', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })}`,
          songCount: songsInWeek.length,
          startDate: weekStart,
          endDate: weekEnd
        });
      }
      
      currentDate.setDate(currentDate.getDate() + 7);
    }
    
    return weeks;
  }, [songs]);

  useEffect(() => {
    setAvailableWeeks(calculateAvailableWeeks);
  }, [calculateAvailableWeeks]);

  // Filtrar canciones por período seleccionado
  const filteredSongs = useMemo(() => {
    if (viewType === 'all' || selectedPeriod === 'all') return songs;

    const selectedWeek = availableWeeks.find(week => week.id === selectedPeriod);
    if (!selectedWeek) return songs;

    return songs.filter(song => {
      const songDate = new Date(song.fields['Time of request']);
      return songDate >= selectedWeek.startDate && songDate <= selectedWeek.endDate;
    });
  }, [songs, selectedPeriod, viewType, availableWeeks]);

  // Estadísticas generales
  const statistics = useMemo(() => {
    const totalSongs = filteredSongs.length;
    const uniqueArtists = new Set(filteredSongs.map(song => song.fields['Artist'])).size;
    const uniqueSongs = new Set(filteredSongs.map(song => song.fields['Song Name'])).size;
    
    const playedCount = filteredSongs.filter(song => 
      song.fields['Option'] === 'Se puso'
    ).length;
    
    const playedPercentage = totalSongs > 0 ? 
      ((playedCount / totalSongs) * 100).toFixed(1) : 
      '0';

    return {
      totalRequests: totalSongs,
      uniqueArtists,
      uniqueSongs,
      playedSongs: playedCount,
      playedPercentage: `${playedPercentage}%`
    };
  }, [filteredSongs]);

  // Mejorar el análisis de artistas
  const artistAnalysis = useMemo(() => {
    const artistStats = filteredSongs.reduce((acc, song) => {
      const artist = song.fields['Artist'];
      if (!acc[artist]) {
        acc[artist] = {
          totalRequests: 0,
          played: 0,
          notPlayed: 0,
          songs: new Set(),
        };
      }
      acc[artist].totalRequests++;
      acc[artist].songs.add(song.fields['Song Name']);
      
      if (song.fields['Option'] === 'Se puso') {
        acc[artist].played++;
      } else if (song.fields['Option'] === 'No la quiero poner') {
        acc[artist].notPlayed++;
      }
      return acc;
    }, {});

    return Object.entries(artistStats)
      .map(([artist, stats]) => ({
        name: artist,
        requests: stats.totalRequests,
        played: stats.played,
        notPlayed: stats.notPlayed,
        uniqueSongs: stats.songs.size,
        playRate: ((stats.played / stats.totalRequests) * 100).toFixed(1)
      }))
      .sort((a, b) => b.requests - a.requests)
      .slice(0, 10);
  }, [filteredSongs]);

  // Modificar el análisis de géneros para mostrar todos los géneros
  const genreAnalysis = useMemo(() => {
    const genreStats = filteredSongs.reduce((acc, song) => {
      const genre = song.fields['Genre'] || 'Sin género';
      if (!acc[genre]) {
        acc[genre] = {
          total: 0,
          played: 0,
          notPlayed: 0,
          artists: new Set(),
          songs: new Set()
        };
      }
      acc[genre].total++;
      acc[genre].artists.add(song.fields['Artist']);
      acc[genre].songs.add(song.fields['Song Name']);
      
      if (song.fields['Option'] === 'Se puso') {
        acc[genre].played++;
      } else if (song.fields['Option'] === 'No la quiero poner') {
        acc[genre].notPlayed++;
      }
      return acc;
    }, {});

    return Object.entries(genreStats)
      .map(([genre, stats]) => ({
        name: genre,
        total: stats.total,
        played: stats.played,
        notPlayed: stats.notPlayed,
        artistCount: stats.artists.size,
        songCount: stats.songs.size,
        playRate: ((stats.played / stats.total) * 100).toFixed(1)
      }))
      .sort((a, b) => b.total - a.total);

  }, [filteredSongs]);

  // Análisis temporal mejorado
  const timeAnalysis = useMemo(() => {
    // Agrupar por períodos del día
    const periods = {
      madrugada: { name: 'Madrugada', hours: [0, 1, 2, 3, 4, 5], total: 0, played: 0 },
      mañana: { name: 'Mañana', hours: [6, 7, 8, 9, 10, 11], total: 0, played: 0 },
      tarde: { name: 'Tarde', hours: [12, 13, 14, 15, 16, 17], total: 0, played: 0 },
      noche: { name: 'Noche', hours: [18, 19, 20, 21, 22, 23], total: 0, played: 0 }
    };

    // Datos por hora para el heatmap
    const hourlyData = Array.from({ length: 24 }, (_, hour) => ({
      hour,
      timeLabel: `${hour % 12 || 12}${hour < 12 ? 'AM' : 'PM'}`,
      value: 0,
      played: 0,
      notPlayed: 0
    }));

    filteredSongs.forEach(song => {
      if (song.fields['Time of request']) {
        const hour = new Date(song.fields['Time of request']).getHours();
        hourlyData[hour].value++;
        
        if (song.fields['Option'] === 'Se puso') {
          hourlyData[hour].played++;
        } else if (song.fields['Option'] === 'No la quiero poner') {
          hourlyData[hour].notPlayed++;
        }

        // Actualizar períodos
        Object.values(periods).forEach(period => {
          if (period.hours.includes(hour)) {
            period.total++;
            if (song.fields['Option'] === 'Se puso') {
              period.played++;
            }
          }
        });
      }
    });

    return {
      hourlyData,
      periods: Object.values(periods).map(period => ({
        ...period,
        playRate: period.total > 0 ? (period.played / period.total * 100).toFixed(1) : 0
      }))
    };
  }, [filteredSongs]);

  // Función para formatear las opciones
  const formatOption = (option) => {
    switch(option) {
      case 'Se puso':
        return '✅ Se reprodujo';
      case 'No la quiero poner':
        return '❌ No reproducida';
      default:
        return '⏳ Pendiente';
    }
  };

  // Datos formateados para la tabla
  const tableData = useMemo(() => {
    return filteredSongs.map(song => ({
      artist: song.fields['Artist'] || 'Sin artista',
      song: song.fields['Song Name'] || 'Sin título',
      genre: song.fields['Genre'] || 'Sin género',
      timeOfRequest: song.fields['Time of request'] ? 
        new Date(song.fields['Time of request']).toLocaleString('es-ES', {
          hour: '2-digit',
          minute: '2-digit',
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        }) : 'No disponible',
      dialingTime: song.fields['Dialing time'] ? 
        new Date(song.fields['Dialing time']).toLocaleString('es-ES', {
          hour: '2-digit',
          minute: '2-digit',
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        }) : 'No disponible',
      rawOption: song.fields['Option'],
      option: song.fields['Option'] === 'Se puso' ? '✅ Se puso' :
              song.fields['Option'] === 'No la quiero poner' ? '❌ No la quiero poner' : 
              '⏳ Pendiente'
    }));
  }, [filteredSongs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Actualizar el renderizado de las opciones en el gráfico
  const playOptionsData = useMemo(() => {
    const optionCount = filteredSongs.reduce((acc, song) => {
      const option = song.fields['Option'] || 'Pendiente';
      acc[option] = (acc[option] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(optionCount).map(([option, count]) => ({
      name: formatOption(option),
      value: count,
      rawOption: option
    }));
  }, [filteredSongs]);

  const PeriodSelector = () => {
    const currentWeekIndex = availableWeeks.findIndex(week => week.id === selectedPeriod);

    useEffect(() => {
      if (viewType === 'weekly' && selectedPeriod === 'all' && availableWeeks.length > 0) {
        setSelectedPeriod(availableWeeks[0].id);
      } else if (viewType === 'all') {
        setSelectedPeriod('all');
      }
    }, [viewType]);

    const handlePeriodChange = (direction) => {
      setAnimationDirection(direction);
      const newIndex = direction === 'left' ? 
        currentWeekIndex - 1 : 
        currentWeekIndex + 1;
      
      if (newIndex >= 0 && newIndex < availableWeeks.length) {
        setSelectedPeriod(availableWeeks[newIndex].id);
      }
    };

    return (
      <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Selector Principal */}
        <Paper elevation={0} sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          p: 1,
          backgroundColor: 'rgba(255,255,255,0.03)',
          borderRadius: '20px',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255,255,255,0.05)',
        }}>
          <ButtonBase
            onClick={() => {
              setViewType('all');
              setSelectedPeriod('all');
            }}
            sx={{
              borderRadius: '15px',
              p: 1.5,
              px: 3,
              backgroundColor: viewType === 'all' ? 'rgba(183,10,32,0.2)' : 'transparent',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: viewType === 'all' ? 'rgba(183,10,32,0.3)' : 'rgba(255,255,255,0.05)',
              }
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              color: viewType === 'all' ? '#fff' : 'rgba(255,255,255,0.7)',
            }}>
              <AllInclusiveIcon sx={{ fontSize: 20 }} />
              <Typography sx={{ fontWeight: 500 }}>
                Historial Completo
              </Typography>
            </Box>
          </ButtonBase>

          <ButtonBase
            onClick={() => setViewType('weekly')}
            sx={{
              borderRadius: '15px',
              p: 1.5,
              px: 3,
              backgroundColor: viewType === 'weekly' ? 'rgba(183,10,32,0.2)' : 'transparent',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: viewType === 'weekly' ? 'rgba(183,10,32,0.3)' : 'rgba(255,255,255,0.05)',
              }
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              color: viewType === 'weekly' ? '#fff' : 'rgba(255,255,255,0.7)',
            }}>
              <DateRangeIcon sx={{ fontSize: 20 }} />
              <Typography sx={{ fontWeight: 500 }}>
                Por Semanas
              </Typography>
            </Box>
          </ButtonBase>
        </Paper>

        {/* Navegador de Semanas */}
        {viewType === 'weekly' && availableWeeks.length > 0 && (
          <Paper elevation={0} sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            p: 2,
            backgroundColor: 'rgba(255,255,255,0.03)',
            borderRadius: '20px',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255,255,255,0.05)',
          }}>
            <IconButton 
              onClick={() => handlePeriodChange('left')}
              disabled={currentWeekIndex <= 0}
              sx={{
                color: 'white',
                backgroundColor: 'rgba(255,255,255,0.05)',
                '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)' },
                '&.Mui-disabled': { opacity: 0.3 }
              }}
            >
              <ChevronLeftIcon />
            </IconButton>

            <Fade 
              in={true} 
              direction={animationDirection === 'left' ? 'right' : 'left'}
              timeout={300}
            >
              <Box sx={{ 
                textAlign: 'center',
                minWidth: '260px',
                animation: 'fadeIn 0.3s ease-out',
              }}>
                <Typography variant="h6" sx={{ 
                  fontWeight: 600,
                  color: '#fff',
                  mb: 0.5
                }}>
                  {selectedPeriod !== 'all' && `Semana ${currentWeekIndex + 1} de ${availableWeeks.length}`}
                </Typography>
                <Typography variant="body2" sx={{ 
                  color: 'rgba(255,255,255,0.7)',
                  fontSize: '0.9rem'
                }}>
                  {selectedPeriod !== 'all' && availableWeeks[currentWeekIndex]?.label}
                </Typography>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  mt: 1
                }}>
                  <Chip
                    label={`${availableWeeks[currentWeekIndex]?.songCount || 0} canciones`}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(183,10,32,0.2)',
                      color: '#fff',
                      '& .MuiChip-label': { px: 2 }
                    }}
                  />
                </Box>
              </Box>
            </Fade>

            <IconButton 
              onClick={() => handlePeriodChange('right')}
              disabled={currentWeekIndex >= availableWeeks.length - 1}
              sx={{
                color: 'white',
                backgroundColor: 'rgba(255,255,255,0.05)',
                '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)' },
                '&.Mui-disabled': { opacity: 0.3 }
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Paper>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ 
      minHeight: '100vh', 
      background: 'linear-gradient(135deg, #1e1e1e 0%, #2d2d2d 100%)',
      color: '#fff', 
      pt: { xs: 2, sm: 4 },
      pb: 0,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '250px',
        background: 'linear-gradient(180deg, rgba(183,10,32,0.15) 0%, rgba(0,0,0,0) 100%)',
        pointerEvents: 'none'
      }
    }}>
      <Container maxWidth="lg" sx={{ flex: 1 }}>
        {/* Header Section */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: { xs: 2, sm: 2 },
          position: 'relative'
        }}>
          <Box sx={{
            position: 'relative',
            mb: 3,
            '&::after': {
              content: '""',
              position: 'absolute',
              top: -8,
              left: -8,
              right: -8,
              bottom: -8,
              background: 'radial-gradient(circle at center, rgba(183,10,32,0.2) 0%, rgba(0,0,0,0) 70%)',
              borderRadius: '50%',
              zIndex: -1
            }
          }}>
            <Avatar
              src={djInfo?.Foto?.[0]?.url}
              alt={djInfo?.Name || 'DJ'}
              sx={{
                width: { xs: 130, sm: 160 },
                height: { xs: 130, sm: 160 },
                boxShadow: '0 8px 32px rgba(0,0,0,0.3)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05) translateY(-5px)',
                  boxShadow: '0 12px 40px rgba(183,10,32,0.3)',
                },
                backgroundColor: '#b70a20',
                fontSize: { xs: '3.5rem', sm: '4.5rem' },
              }}
            >
              {djInfo?.Name ? djInfo.Name.charAt(0).toUpperCase() : 'D'}
            </Avatar>
          </Box>
          
          <Typography 
            variant="h3" 
            sx={{ 
              textAlign: 'center',
              fontWeight: 800,
              fontSize: { xs: '1.8rem', sm: '2.5rem' },
              background: '#fff',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              letterSpacing: '-0.5px',
              mb: 1
            }}
          >
            Analytics de  {djInfo?.Name || 'DJ'}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              color: 'rgba(255,255,255,0.7)',
              textAlign: 'center',
              maxWidth: '600px',
              mx: 'auto',
              fontSize: { xs: '0.9rem', sm: '1rem' },
              mb: 1
            }}
          >
            Estadísticas detalladas de tus tocadas y gustos musicales de tu público
          </Typography>
        </Box>

        <PeriodSelector />

        {/* Tabs Section */}
        <Box sx={{ 
          backgroundColor: 'rgba(255,255,255,0.03)',
          borderRadius: '16px',
          p: 1,
          mb: 4,
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255,255,255,0.05)'
        }}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
            allowScrollButtonsMobile
            centered={!isMobile}
            sx={{
              '& .MuiTab-root': {
                color: 'rgba(255,255,255,0.6)',
                fontSize: { xs: '0.85rem', sm: '0.95rem' },
                minWidth: { xs: 'auto', sm: 120 },
                textTransform: 'none',
                fontWeight: 500,
                py: 1.5,
                transition: 'all 0.3s ease',
                '&:hover': {
                  color: '#FF6B6B',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  fontWeight: 600
                }
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#b70a20',
                height: 3,
                borderRadius: '3px 3px 0 0'
              }
            }}
          >
            <Tab label="Resumen" />
            <Tab label="Artistas" />
            <Tab label="Géneros" />
            <Tab label="Tiempo" />
            <Tab label="Acciones" />
            <Tab label="Lista" />
          </Tabs>
        </Box>

        {/* Content Section */}
        <Box sx={{ 
          position: 'relative',
          animation: 'fadeIn 0.5s ease-out',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          }
        }}>
          {activeTab === 0 && <GeneralStats statistics={statistics} />}
          {activeTab === 1 && <ArtistAnalysis artistAnalysis={artistAnalysis} />}
          {activeTab === 2 && <GenreAnalysis genreAnalysis={genreAnalysis} />}
          {activeTab === 3 && <TimeAnalysis timeAnalysis={timeAnalysis} />}
          {activeTab === 4 && (
            <PlaybackStatus 
              playOptionsData={playOptionsData}
              statistics={statistics}
            />
          )}
          {activeTab === 5 && (
            <SongList 
              tableData={tableData}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Box>
      </Container>

      {/* Footer Section */}
      <Box sx={{ 
        mt: 'auto',
        background: 'linear-gradient(0deg, rgba(0,0,0,0.3) 0%, transparent 100%)',
        pt: 2
      }}>
        <FooterLogo />
        <Footer />
      </Box>
    </Box>
  );
};

export default DataAnalyticsView; 