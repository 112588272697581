import React from 'react';
import { Box, Typography, Button, Paper} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';

const MultimediaForm = ({ formData, errors, handleFileUpload }) => {
  return (
    <Paper 
      elevation={3}
      sx={{
        p: 3,
        bgcolor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
          transform: 'translateY(-2px)'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <PhotoCameraIcon sx={{ color: '#b70a20', mr: 2, fontSize: 28 }} />
        <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
          Multimedia
        </Typography>
      </Box>

      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: 3
      }}>
        {/* Foto de Perfil */}
        <Box>
          <Typography variant="subtitle1" sx={{ color: 'white', mb: 2 }}>
            Foto de Perfil
          </Typography>
          <Box
            sx={{
              position: 'relative',
              aspectRatio: '1',
              borderRadius: 2,
              overflow: 'hidden',
              border: formData.profilePhotoPreview 
                ? '1px solid rgba(255,255,255,0.1)'
                : '2px dashed rgba(255,255,255,0.3)',
              '&:hover .overlay': {
                opacity: 1
              }
            }}
          >
            {formData.profilePhotoPreview ? (
              <>
                <img
                  src={formData.profilePhotoPreview}
                  alt="Profile preview"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
                <Button
                  component="label"
                  className="overlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgcolor: 'rgba(0,0,0,0.7)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: 0,
                    transition: 'all 0.3s ease',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(0,0,0,0.8)',
                    }
                  }}
                >
                  <PhotoCameraIcon sx={{ fontSize: 40, mb: 1 }} />
                  <Typography variant="body2">
                    Cambiar foto de perfil
                  </Typography>
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e, 'profilePhoto')}
                  />
                </Button>
              </>
            ) : (
              <Button
                component="label"
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    bgcolor: 'rgba(183, 10, 32, 0.1)',
                  }
                }}
              >
                <PhotoCameraIcon sx={{ color: '#b70a20', fontSize: 40, mb: 1 }} />
                <Typography variant="body2" sx={{ color: 'white' }}>
                  Subir Foto de Perfil
                </Typography>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, 'profilePhoto')}
                />
              </Button>
            )}
          </Box>
          {errors.profilePhoto && (
            <Typography color="error" variant="caption" sx={{ mt: 1, display: 'block' }}>
              {errors.profilePhoto}
            </Typography>
          )}
        </Box>

        {/* Foto de Portada */}
        <Box>
          <Typography variant="subtitle1" sx={{ color: 'white', mb: 2 }}>
            Foto de Portada
          </Typography>
          <Box
            sx={{
              position: 'relative',
              aspectRatio: '1',
              borderRadius: 2,
              overflow: 'hidden',
              border: formData.coverPhotoPreview 
                ? '1px solid rgba(255,255,255,0.1)'
                : '2px dashed rgba(255,255,255,0.3)',
              '&:hover .overlay': {
                opacity: 1
              }
            }}
          >
            {formData.coverPhotoPreview ? (
              <>
                <img
                  src={formData.coverPhotoPreview}
                  alt="Cover preview"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
                <Button
                  component="label"
                  className="overlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgcolor: 'rgba(0,0,0,0.7)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: 0,
                    transition: 'all 0.3s ease',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(0,0,0,0.8)',
                    }
                  }}
                >
                  <ImageIcon sx={{ fontSize: 40, mb: 1 }} />
                  <Typography variant="body2">
                    Cambiar foto de portada
                  </Typography>
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e, 'coverPhoto')}
                  />
                </Button>
              </>
            ) : (
              <Button
                component="label"
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    bgcolor: 'rgba(183, 10, 32, 0.1)',
                  }
                }}
              >
                <ImageIcon sx={{ color: '#b70a20', fontSize: 40, mb: 1 }} />
                <Typography variant="body2" sx={{ color: 'white' }}>
                  Subir Foto de Portada
                </Typography>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, 'coverPhoto')}
                />
              </Button>
            )}
          </Box>
          {errors.coverPhoto && (
            <Typography color="error" variant="caption" sx={{ mt: 1, display: 'block' }}>
              {errors.coverPhoto}
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default MultimediaForm; 