import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumbs = ({ items }) => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        flexWrap: 'wrap',
        py: 2,
        opacity: 0.9
      }}
      aria-label="breadcrumb"
    >
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <NavigateNextIcon 
              fontSize="small" 
              sx={{ mx: 1, color: 'rgba(255,255,255,0.6)' }} 
            />
          )}
          
          {index === items.length - 1 ? (
            <Typography 
              color="textPrimary" 
              aria-current="page"
              sx={{ fontSize: '0.9rem' }}
            >
              {item.name}
            </Typography>
          ) : (
            <Link 
              component={RouterLink} 
              to={item.link}
              sx={{ 
                color: 'rgba(255,255,255,0.8)',
                textDecoration: 'none',
                fontSize: '0.9rem',
                '&:hover': {
                  textDecoration: 'underline',
                  color: '#b70a20'
                }
              }}
            >
              {item.name}
            </Link>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Breadcrumbs; 