import React from "react";
import { Box, Typography, Button, Paper, Stack } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PreviewDJ from "./PreviewDJ";

const DJPreview = ({ formData, handleBack, handleNext }) => {
  if (!formData || !formData.phone || !formData.djName || !formData.preview) {
    return (
      <Paper 
        elevation={3}
        sx={{
          p: 3,
          bgcolor: 'rgba(255, 255, 255, 0.05)',
          borderRadius: 2,
          textAlign: 'center'
        }}
      >
        <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
          Información Incompleta
        </Typography>
        <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 3 }}>
          Por favor, completa todos los campos requeridos.
        </Typography>
        <Button
          variant="contained"
          onClick={handleBack}
          startIcon={<ArrowBackIcon />}
          sx={{
            bgcolor: '#b70a20',
            '&:hover': {
              bgcolor: '#8f0816',
              transform: 'translateY(-2px)'
            },
            transition: 'all 0.3s ease'
          }}
        >
          Volver al formulario
        </Button>
      </Paper>
    );
  }

  return (
    <Stack spacing={3}>
      <Paper 
        elevation={3}
        sx={{
          p: 3,
          bgcolor: 'rgba(255, 255, 255, 0.05)',
          borderRadius: 2,
          transition: 'all 0.3s ease',
          '&:hover': {
            bgcolor: 'rgba(255, 255, 255, 0.08)',
            transform: 'translateY(-2px)'
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <CheckCircleIcon sx={{ color: '#4caf50', mr: 2 }} />
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
            Vista Previa de tu QR
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <PreviewDJ
            djName={formData.djName}
            djPhoto={formData.preview}
            creatorName="DJ PONLA"
          />
        </Box>

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          gap: 2,
          mt: 3 
        }}>
          <Button
            variant="outlined"
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
            sx={{
              flex: 1,
              color: 'white',
              borderColor: 'rgba(255, 255, 255, 0.3)',
              '&:hover': {
                borderColor: 'white',
                bgcolor: 'rgba(255, 255, 255, 0.05)'
              }
            }}
          >
            Volver
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{
              flex: 1,
              bgcolor: '#b70a20',
              '&:hover': {
                bgcolor: '#8f0816',
                transform: 'translateY(-2px)'
              },
              transition: 'all 0.3s ease'
            }}
          >
            Continuar al Pago
          </Button>
        </Box>
      </Paper>
    </Stack>
  );
};

export default DJPreview;
