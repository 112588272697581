import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  TextField, Button, List, ListItem, ListItemText, Paper, Container, Typography, CircularProgress, Avatar, ListItemAvatar, Divider
} from '@mui/material';
import { searchSongs, getGenreFromDeezer } from '../api/deezer';
import { createSongRequest, getDJInfo } from '../api/airtable';
import { useParams } from 'react-router-dom';
import DJInfoSection from '../components/DJForm/DJInfoSection';
import SelectedSongSection from '../components/DJForm/SelectedSongSection';
import FooterLogo from '../components/FooterLogo';
import SongRequestModal from '../components/DJForm/SongRequestModal';

const MemoizedDJInfoSection = React.memo(DJInfoSection);
const MemoizedSelectedSongSection = React.memo(SelectedSongSection);
const MemoizedFooterLogo = React.memo(FooterLogo);
const MemoizedSongRequestModal = React.memo(SongRequestModal);

const SongRequestForm = () => {
  const { formId } = useParams();
  const [query, setQuery] = useState('');
  const [tableNumber, setTableNumber] = useState('');
  const [tableNumberError, setTableNumberError] = useState('');
  const [results, setResults] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [djInfo, setDjInfo] = useState({});
  const [loadingDJInfo, setLoadingDJInfo] = useState(true);

  useEffect(() => {
    const fetchDJInfo = async () => {
      try {
        const info = await getDJInfo(formId);
        setDjInfo(info);
      } catch (error) {
        console.error('Error fetching DJ info:', error);
      } finally {
        setLoadingDJInfo(false);
      }
    };
    fetchDJInfo();
  }, [formId]);

  const handleSearch = useCallback(async () => {
    if (!query.trim()) {
      setError('El campo de búsqueda no puede estar vacío');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const songs = await searchSongs(query);
      setResults(songs);
    } catch (error) {
      console.error('Error searching songs:', error);
      setError('Hubo un error al buscar canciones. Por favor, inténtelo de nuevo.');
    } finally {
      setLoading(false);
    }
  }, [query]);

  const handleSelect = useCallback((song) => {
    setSelectedSong(song);
    setResults([]);
    setQuery('');
  }, []);

  const validateTableNumber = useCallback((number) => {
    const regex = /^\d+$/;
    if (!regex.test(number)) {
      return 'Por favor, ingresa un número de mesa válido (solo números)';
    }
    return '';
  }, []);

  const isTableNumberValid = useCallback(() => {
    if (formId !== 'fm0924tom') return true;
    return validateTableNumber(tableNumber.trim()) === '';
  }, [formId, tableNumber, validateTableNumber]);

  const handleTableNumberChange = useCallback((e) => {
    const number = e.target.value;
    setTableNumber(number);
    const error = validateTableNumber(number);
    setTableNumberError(error);
  }, [validateTableNumber]);

  const handleSubmit = useCallback(async () => {
    if (!selectedSong) return;
    if (formId === 'fm0924tom' && !isTableNumberValid()) {
      setTableNumberError('Por favor, ingresa un número de mesa válido');
      return;
    }
    
    setLoading(true);
    try {
      const genreData = await getGenreFromDeezer(
        selectedSong.artist.id,
        selectedSong.album.id
      );
      
      await createSongRequest(
        formId,
        selectedSong.title,
        selectedSong.artist.name,
        genreData.primary.join(', '),
        formId === 'fm0924tom' ? tableNumber.trim() : null
      );
      
      setOpen(true);
    } catch (error) {
      console.error('Error al agregar la canción:', error);
      setError('Hubo un error al solicitar la canción. Por favor, inténtelo de nuevo.');
    } finally {
      setLoading(false);
    }
  }, [formId, selectedSong, tableNumber, isTableNumberValid]);

  const handleCancel = () => {
    setSelectedSong(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSong(null);
  };

  const resultsMemo = useMemo(() => results, [results]);

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <MemoizedDJInfoSection djInfo={djInfo} loadingDJInfo={loadingDJInfo} />
        <Typography variant="h6" component="h2" sx={{ marginBottom: 2, textAlign: 'center', fontSize:'1.1rem' }}>
          ¿Qué canción quieres escuchar?
        </Typography>
        {!selectedSong && (
          <>
            {formId === 'fm0924tom' && (
              <TextField
                fullWidth
                label="Nº de mesa"
                value={tableNumber}
                onChange={handleTableNumberChange}
                sx={{
                  marginBottom: '1rem',
                  "& .MuiOutlinedInput-root": {
                    color: "#ffffff",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C2C2C2",
                    },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#C2C2C2",
                      },
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    color: "#C2C2C2",
                    "&.Mui-focused": {
                      color: "#868686",
                    },
                  },
                }}
                error={!!tableNumberError}
                helperText={tableNumberError}
              />
            )}
            <TextField
              fullWidth
              label="Nombre de la canción"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{
                marginBottom: '2rem',
                "& .MuiOutlinedInput-root": {
                  color: "#ffffff",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C2C2C2",
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C2C2C2",
                    },
                  },
                },
                "& .MuiInputLabel-outlined": {
                  color: "#C2C2C2",
                  "&.Mui-focused": {
                    color: "#868686",
                  },
                },
              }}
              error={!!error}
              helperText={error}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSearch}
              sx={{ marginTop: -1, marginBottom: 2, backgroundColor: '#54A772' }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Buscar'}
            </Button>
          </>
        )}
        {loading && (
          <CircularProgress 
            sx={{ 
              display: 'block', 
              margin: '0 auto', 
              marginTop: 2 
            }} 
          />
        )}
        <List>
          {resultsMemo.map((song, index) => (
            <React.Fragment key={index}>
              <ListItem 
                onClick={() => handleSelect(song)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  },
                  transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
                  '&:active': {
                    transform: 'scale(0.98)',
                  },
                  padding: '10px 0',
                }}
              >
                <ListItemAvatar>
                  <Avatar 
                    src={song.album.cover} 
                    alt={song.title} 
                    sx={{ 
                      width: 56, 
                      height: 56, 
                      borderRadius: '8px', 
                      marginRight: 0.5 
                    }} 
                  />
                </ListItemAvatar>
                <ListItemText 
                  primary={song.title} 
                  secondary={song.artist.name} 
                  sx={{
                    marginLeft: 0.5,
                    fontWeight: 'bold',
                  }}
                />
              </ListItem>
              {index < resultsMemo.length - 1 && <Divider sx={{ marginY: 1 }} />}
            </React.Fragment>
          ))}
        </List>
        {selectedSong && (
          <MemoizedSelectedSongSection
            selectedSong={selectedSong}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        )}
        <MemoizedFooterLogo />
      </Paper>
      <MemoizedSongRequestModal 
        open={open} 
        handleClose={handleClose} 
        djInfo={djInfo} 
        tableNumber={formId === 'fm0924tom' ? tableNumber : undefined}
      />
    </Container>
  );
};

export default SongRequestForm;
