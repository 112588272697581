import React, { useState, useEffect } from 'react';
import {
  Card, CardContent, Typography, Button, Box, CircularProgress, IconButton, useMediaQuery
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { motion, useMotionValue, useTransform } from 'framer-motion';

const KaraokeSongCard = ({ song, handleDelete, loading }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Se puso');
  const isMobile = useMediaQuery('(max-width:600px)'); // Añadido para responsive

  // Valor de movimiento para el arrastre en el eje x
  const x = useMotionValue(0);

  // Cambia el color del botón de manera progresiva y fluida
  const buttonBackgroundColor = useTransform(x, [-80, 0], ['#f44336', 'rgb(244, 67, 54)']); // Rojo a verde
  const cardBackgroundColor = useTransform(x, [-200, 0], ['#f44336', '#ffffff']); // Color de la tarjeta

  // Cambio de texto basado en el desplazamiento
  useEffect(() => {
    x.on('change', (latest) => {
      if (latest < -50) {
        setButtonLabel('No la quiero poner');
      } else {
        setButtonLabel('Se puso');
      }
    });
  }, [x]);

  const handleDragEnd = (event, info) => {
    // Si el usuario desliza más de 100px a la izquierda, eliminar la canción
    if (info.offset.x < -100) {
      handleDelete(song.id, 'No la quiero poner');
    }
    setIsDragging(false);
  };

  return (
    <motion.div
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={handleDragEnd}
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
        x,
      }}
      whileTap={{ scale: 0.95 }}  // Para que el usuario sienta la respuesta táctil
    >
      <Card 
        component={motion.div} 
        sx={{
          borderRadius: 5,
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '150px',
          backgroundColor: '#ffffff',
        }}
        style={{
          backgroundColor: cardBackgroundColor.get(),
          color: '#000',
        }}
      >
        {/* Actualización del IconButton */}
        <IconButton
          size="small"
          sx={{ 
            position: 'absolute', 
            top: 8, 
            right: 8, 
            color: "#f44336",
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }
          }}
          onClick={() => handleDelete(song.id, 'No la quiero poner')}
          disabled={loading}
        >
          <CancelOutlinedIcon />
        </IconButton>

        <CardContent sx={{ flexGrow: 1 }}>
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 'bold', 
              fontSize: '1.1rem', 
              color: '#000',
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              pr: 4 
            }}
          >
            {song.fields['Song Name']}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#555' }}>
            {song.fields['Artist']}
          </Typography>
          {/* Modificación para mostrar el nombre del cantante */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mt: 1 
          }}>
            {song.fields['Singer Name'] && (
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  color: '#333',
                  fontStyle: 'italic',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                Cantante: {song.fields['Singer Name']}
              </Typography>
            )}
            {song.fields['Enlace'] && (
              <IconButton
                href={song.fields['Enlace']}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  color: '#FF0000',
                  padding: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                  },
                }}
              >
                <YouTubeIcon />
              </IconButton>
            )}
          </Box>
        </CardContent>

        {/* Actualización de los botones de acción */}
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Button
                variant="contained"
                color="error"
                startIcon={<CancelOutlinedIcon sx={{ 
                  marginRight: '0.5rem', 
                  fontSize: '1.5rem', 
                  backgroundColor: '#fff', 
                  borderRadius: '50%', 
                  padding: '0.2rem', 
                  color: '#F44236' 
                }} />}
                sx={{ 
                  borderRadius: 3,
                  flexBasis: '48%',
                  minHeight: '45px',
                  fontSize: isMobile ? '0.9rem' : '1rem',
                  backgroundColor: '#F44236',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                  },
                  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                }}
                onClick={() => handleDelete(song.id, 'No la quiero poner')}
                disabled={loading}
              >
                No se cantará
              </Button>
              <Button
                variant="contained"
                startIcon={<PlaylistPlayIcon sx={{ 
                  marginRight: '0.5rem', 
                  fontSize: '1.5rem', 
                  backgroundColor: '#fff', 
                  borderRadius: '50%', 
                  padding: '0.2rem', 
                  color: '#54A773' 
                }} />}
                sx={{ 
                  borderRadius: 3,
                  flexBasis: '48%',
                  backgroundColor: '#54A773',
                  color: '#fff',
                  minHeight: '45px',
                  fontSize: isMobile ? '0.9rem' : '1rem',
                  ml: 1,
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: '#388e3c',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                  },
                  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                }}
                onClick={() => handleDelete(song.id, 'Se puso')}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Ya se cantó'}
              </Button>
            </>
          )}
        </Box>
      </Card>
    </motion.div>
  );
};

export default KaraokeSongCard;
