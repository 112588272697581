import React from "react";
import { Box, Typography, Avatar, Paper, TextField, Button } from "@mui/material";
import LogoExtendido from '../../assets/Logo DJ Ponla extendido (sin fondo).png';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

const PreviewDJ = ({ djName, djPhoto, creatorName }) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      width: '100%'
    }}>
      <Paper
        elevation={3}
        sx={{
          bgcolor: 'rgba(0, 0, 0, 0.6)',
          color: "#fff",
          p: 3,
          borderRadius: 2,
          maxWidth: "350px",
          width: '100%',
          border: '1px solid rgba(255,255,255,0.1)',
          transition: 'all 0.3s ease',
          textAlign: 'center',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 8px 24px rgba(0,0,0,0.2)'
          }
        }}
      >
        <Avatar
          src={djPhoto}
          alt={djName}
          sx={{
            width: 200,
            height: 200,
            borderRadius: 2,
            mb: 2,
            mx: 'auto',
            border: '2px solid rgba(255,255,255,0.1)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
          }}
          variant="rounded"
        />

        <Typography 
          variant="h6" 
          sx={{ 
            mb: 2,
            fontWeight: 600,
            textShadow: '0 2px 4px rgba(0,0,0,0.3)'
          }}
        >
          Tocando {djName}
        </Typography>

        <Typography 
          variant="body2" 
          sx={{ 
            mb: 2,
            color: 'rgba(255,255,255,0.7)'
          }}
        >
          ¿Qué canción quieres escuchar?
        </Typography>

        <TextField
          fullWidth
          variant="filled"
          placeholder="Nombre de la canción"
          disabled
          sx={{
            mb: 2,
            '& .MuiFilledInput-root': {
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              '&.Mui-disabled': {
                bgcolor: 'rgba(255, 255, 255, 0.05)'
              }
            },
            '& .MuiInputBase-input.Mui-disabled': {
              color: 'rgba(255, 255, 255, 0.7)',
              WebkitTextFillColor: 'rgba(255, 255, 255, 0.7)'
            }
          }}
        />

        <Button
          fullWidth
          variant="contained"
          disabled
          startIcon={<SearchIcon />}
          sx={{
            mb: 3,
            bgcolor: '#54A772',
            '&.Mui-disabled': {
              bgcolor: 'rgba(84, 167, 114, 0.7)',
              color: 'white'
            }
          }}
        >
          Buscar
        </Button>

        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="body2"
            sx={{ 
              mb: 1,
              color: 'rgba(255,255,255,0.5)',
              fontSize: '0.875rem'
            }}
          >
            Creado por:
          </Typography>
          
          <Link to="/" style={{ textDecoration: 'none', display: 'inline-block' }}>
            <Box
              component="img"
              src={LogoExtendido}
              alt='Logo DJPonla'
              sx={{
                width: '140px',
                height: 'auto',
                borderRadius: 1,
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)'
                }
              }}
            />
          </Link>
        </Box>
      </Paper>
    </Box>
  );
};

export default PreviewDJ;
