import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

const DJInfoSection = ({ djInfo, loadingDJInfo }) => (
  <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
    {loadingDJInfo ? (
      <CircularProgress />
    ) : (
      <>
        {djInfo.Logo && djInfo.Logo.length > 0 && (
          <img src={djInfo.Logo[0].url} alt={djInfo.Name} style={{ width: '40%', height: '40%', borderRadius: 10 }} />
        )}
        {djInfo.Foto && djInfo.Foto.length > 0 ? (
          <Box
            sx={{
              width: '90%',
              paddingTop: '90%',
              position: 'relative',
              margin: '0 auto',
            }}
          >
            <img 
              src={djInfo.Foto[0].url} 
              alt={djInfo.Name} 
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: 10,
                objectFit: 'cover'
              }} 
            />
          </Box>
        ) : (
          <Typography variant="h6" color="error">No se pudo cargar la imagen del DJ</Typography>
        )}
        <Typography variant="h5" component="h1" sx={{ marginTop: 2 }}>
          {djInfo.Name ? `Tocando ${djInfo.Name}` : 'DJ tocando en vivo'}
        </Typography>
      </>
    )}
  </Box>
);

export default DJInfoSection;
