import React, { useState } from 'react';
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: "¿Cómo funciona el código QR?",
    answer: "El código permite que tu público te envíe canciones automáticamente, al escanearlo con su celular son dirigidos a una web intuitiva donde pueden sugerirte la canción que quieren disfrutar. Es un código QR personal único, que puedes llevar a cada lugar al que toques."
  },
  {
    question: "¿Tengo que descargar alguna app?",
    answer: "No. Ni tu como DJ, ni tu público tienen que descargar nada. Al ser una aplicación web, funcionamos mediante un link en cualquier navegador."
  },
  {
    question: "¿Cómo veo las sugerencias de mi público?",
    answer: "Nosotros te generamos un link personal privado, que puedes abrir en cualquier navegador y ver directamente las peticiones de tu público."
  },
  {
    question: "¿Cómo veo el reporte de canciones?",
    answer: "Nosotros te damos un link personal privado, en el que verás tus estadísticas y performance siempre actualizado."
  },
  {
    question: "¿Es fácil de usar para mi público?",
    answer: "Sí. Es súper fácil y rápido, solo escanean, buscan su canción y la envían. No necesitan crear ninguna cuenta."
  },
  {
    question: "¿Tienen todas las canciones disponibles?",
    answer: "Sí. Tenemos un proveedor de canciones que usan las plataformas de streaming más conocidas."
  },
  {
    question: "¿En qué países está disponible?",
    answer: "Estamos disponible en todo el mundo."
  },
  {
    question: "¿En cuánto tiempo obtengo mi QR?",
    answer: "Luego de realizar el pago. En menos de 12 horas recibirás todos tus accesos y QR a través de WhatsApp."
  }
];

const FAQSection = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ py: { xs: 6, md: 10 }, backgroundColor: '#111111' }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography 
            variant="h3" 
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: '#ffffff',
              mb: 3
            }}
          >
            Preguntas{' '}
            <Box component="span" sx={{ color: '#b70a20' }}>
              frecuentes
            </Box>
          </Typography>
        </Box>

        <Box sx={{ maxWidth: 800, mx: 'auto' }}>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                color: '#ffffff',
                borderRadius: '8px !important',
                mb: 2,
                '&:before': {
                  display: 'none',
                },
                '&.Mui-expanded': {
                  margin: '0 0 16px 0',
                },
                boxShadow: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#b70a20' }} />}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    my: 2,
                  }
                }}
              >
                <Typography sx={{ 
                  fontWeight: 600,
                  fontSize: '1.1rem',
                  color: expanded === `panel${index}` ? '#b70a20' : '#ffffff'
                }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ 
                  color: '#A6A5A6',
                  lineHeight: 1.8,
                  pb: 1
                }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FAQSection; 