import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoExtendido from '../assets/Logo DJ Ponla extendido (sin fondo).png';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToSection = (section) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 300);
    } else {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
    handleClose();
  };

  // Funciones nombradas para manejar los clics
  const handleTestimonialsClick = () => {
    scrollToSection('testimonials');
  };

  const handleDjsClick = () => {
    handleClose();
    navigate('/djs');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleAboutClick = () => {
    handleClose();
    navigate('/nosotros');
    // Aseguramos que la página se muestre desde el inicio
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const trackBuyButtonClick = () => {
    try {
      window.gtag('event', 'click_buy_button_navbar', {
        event_category: 'Conversiones',
        event_label: 'Botón Comprar Navbar',
        location: location.pathname,
        timestamp: new Date().toISOString(),
        value: 1
      });
    } catch (error) {
      console.error('Error al registrar evento de compra:', error);
    }
  };

  const handlePricingClick = () => {
    handleClose();
    trackBuyButtonClick();
    navigate('/comprar');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <AppBar 
      position="fixed" 
      elevation={0}
      sx={{
        background: 'rgba(18, 18, 18, 0.8)', // Fondo semi-transparente
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img src={LogoExtendido} alt="Logo DJPonla" style={{ height: '70px' }} />
          </Link>
        </Box>
        {isMobile ? (
          <>
            <IconButton 
              edge="end" 
              color="inherit" 
              onClick={handleMenu}
              sx={{
                color: 'white',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                }
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu 
              anchorEl={anchorEl} 
              open={Boolean(anchorEl)} 
              onClose={handleClose}
              PaperProps={{
                sx: {
                  background: 'rgba(18, 18, 18, 0.9)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  '& .MuiMenuItem-root': {
                    color: 'white',
                    '&:hover': {
                      background: 'rgba(255, 255, 255, 0.1)',
                    }
                  }
                }
              }}
            >
              <MenuItem onClick={handleTestimonialsClick}>Testimonios</MenuItem>
              <MenuItem onClick={handleDjsClick}>Nuestros DJs</MenuItem>
              <MenuItem onClick={handleAboutClick}>Sobre nosotros</MenuItem>
              <Button
                variant="contained" 
                onClick={handlePricingClick}
                sx={{
                  py: { xs: 0.75, md: 1 },
                  px: { xs: 1.5, md: 2.5 },
                  fontSize: { xs: '0.8rem', md: '0.9rem' },
                  fontWeight: 700,
                  borderRadius: '50px',
                  background: 'linear-gradient(45deg, #b70a20, #8f0816)',
                  m: 1,
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 10px 20px rgba(183, 10, 32, 0.3)',
                    background: 'linear-gradient(45deg, #8f0816, #b70a20)',
                  },
                  transition: 'all 0.3s ease'
                }}
              >
                Nuestros Productos
              </Button>
            </Menu>
          </>
        ) : (
          <>
            <Button 
              color="inherit" 
              onClick={handleTestimonialsClick}
              sx={{
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                }
              }}
            >
              Testimonios
            </Button>
            <Button 
              color="inherit" 
              onClick={handleDjsClick}
              sx={{
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                }
              }}
            >
              Nuestros DJs
            </Button>
            <Button 
              color="inherit" 
              onClick={handleAboutClick}
              sx={{
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.1)',
                }
              }}
            >
              Sobre nosotros
            </Button>
            <Button 
              variant="contained"
              onClick={handlePricingClick}
              sx={{
                py: { xs: 0.75, md: 1 },
                px: { xs: 1.5, md: 2.5 },
                fontSize: { xs: '0.8rem', md: '0.9rem' },
                fontWeight: 700,
                borderRadius: '50px',
                background: 'linear-gradient(45deg, #b70a20, #8f0816)',
                ml: 2,
                position: 'relative',
                overflow: 'hidden',
                '&:hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 10px 20px rgba(183, 10, 32, 0.3)',
                  background: 'linear-gradient(45deg, #8f0816, #b70a20)',
                },
                transition: 'all 0.3s ease'
              }}
            >
              Nuestros Productos
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
