import React from 'react';
import {
  Grid,
  Typography,
  Card,
  Box,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';

const cardStyles = {
  backgroundColor: "rgba(34, 34, 34, 0.95)",
  backdropFilter: "blur(20px)",
  borderRadius: '24px',
  p: 3,
  boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)'
  }
};

// Función para determinar el color basado en la intensidad
const getIntensityColor = (value, maxValue) => {
  const intensity = value / maxValue;
  if (intensity <= 0.3) return '#d10718';  // Baja - Rojo más fuerte
  if (intensity <= 0.7) return '#a30613';   // Media - Rojo principal más oscuro
  return '#800000';  // Alta - Rojo muy oscuro
};

const TimeAnalysis = ({ timeAnalysis }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={cardStyles}>
          <Typography variant="h5" sx={{ 
            mb: 4, 
            color: '#fff', 
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', md: '1.5rem' },
          }}>
            Cantidad de peticiones por hora
          </Typography>

          {/* Heatmap de actividad */}
          <Box sx={{ height: 400}}>
            <ResponsiveContainer>
              <BarChart
                data={timeAnalysis.hourlyData.filter(data => data.value > 0)}
                margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
              >
                <CartesianGrid 
                  strokeDasharray="3 3" 
                  stroke="rgba(255,255,255,0.1)"
                  vertical={false}
                />
                <XAxis
                  dataKey="timeLabel"
                  stroke="#fff"
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  tick={{ fill: '#fff', fontSize: 12 }}
                />
                <YAxis 
                  stroke="#fff"
                  tick={{ fill: '#fff' }}
                  label={{ 
                    value: 'Cantidad de Solicitudes', 
                    angle: -90, 
                    position: 'insideLeft',
                    fill: '#fff',
                    style: { textAnchor: 'middle' }
                  }}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1e1e1e',
                    border: '1px solid rgba(255,255,255,0.1)',
                    borderRadius: '12px',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.3)'
                  }}
                  formatter={(value, name, props) => {
                    const data = props.payload;
                    return [
                      <Box sx={{ p: 1 }}>
                        <Typography sx={{ color: '#fff', fontWeight: 'bold', mb: 1 }}>
                          {data.timeLabel}
                        </Typography>
                        <Typography sx={{ color: '#fff' }}>
                          Total: {data.value} solicitudes
                        </Typography>
                        <Typography sx={{ color: '#b70a20' }}>
                          Se puso: {data.played} ✅
                        </Typography>
                        <Typography sx={{ color: '#FF6B6B' }}>
                          No la quiero poner: {data.notPlayed} ❌
                        </Typography>
                      </Box>,
                      ''
                    ];
                  }}
                />
                <Bar dataKey="value" name="Solicitudes">
                  {timeAnalysis.hourlyData.map((entry, index) => {
                    const maxValue = Math.max(...timeAnalysis.hourlyData.map(d => d.value));
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={getIntensityColor(entry.value, maxValue)}
                        radius={[4, 4, 0, 0]}
                      />
                    );
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>

          {/* Resumen por períodos */}
          <Grid container spacing={2} sx={{ mt: -6 }}>
            {timeAnalysis.periods.map((period) => (
              <Grid item xs={12} sm={6} md={3} key={period.name}>
                <Box sx={{
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  p: 1.5,
                  borderRadius: 2,
                  border: '1px solid rgba(255,255,255,0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                  <Typography variant="body1" sx={{ color: '#b70a20' }}>
                    {period.name}:
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#fff' }}>
                      {period.total}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#fff' }}>
                      ({period.playRate}% se puso)
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TimeAnalysis; 