import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';

const SongRequestModal = ({ open, handleClose, djInfo, tableNumber }) => (
  <Modal open={open} onClose={handleClose}>
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 320,
      bgcolor: 'rgba(34, 34, 34, 1)',
      border: 'none',
      boxShadow: 24,
      p: 4,
      borderRadius: 8,
    }}>
      <Typography variant="h6" component="h3" sx={{ fontSize: '1.1rem', textAlign: 'center', marginBottom: 2, color: '#ffffff' }}>
        {tableNumber ? (
          <>
            🎉 Mesa Nº {tableNumber}, ¡Tu canción fue sugerida!<br/> <span style={{ fontWeight: 'bold', color: '#ffffff', fontSize: '1.3rem', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>El DJ la tendrá en consideración.</span>
          </>
        ) : (
          <>
            🎉 ¡Tu canción fue sugerida!<br/> <span style={{ fontWeight: 'bold', color: '#ffffff', fontSize: '1.3rem', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>El DJ la tendrá en consideración.</span>
          </>
        )}
      </Typography>

      <Button 
        fullWidth 
        variant="contained" 
        color="primary" 
        onClick={handleClose} 
        sx={{ marginTop: 2, backgroundColor: '#54A772' }}
      >
        Pedir otra canción
      </Button>

      <Typography variant="h6" component="h3" sx={{ fontSize: '1rem', textAlign: 'center', marginTop: 3, color: '#ffffff' }}>
        ¡Síguenos en Instagram!
      </Typography>
      {djInfo.InstagramLink && djInfo.InstagramHandle && (
        <Button
          component={Link}
          to={djInfo.InstagramLink}
          fullWidth
          variant="contained"
          sx={{
            marginTop: 2,
            background: 'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
            color: '#ffffff',
            '&:hover': {
              background: 'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
              opacity: 0.9,
            },
          }}
        >
          <InstagramIcon sx={{ marginRight: 1 }} /> {djInfo.InstagramHandle}
        </Button>
      )}
    </Box>
  </Modal>
);

export default SongRequestModal;
