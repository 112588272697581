import React from 'react';
import { Container } from '@mui/material';

import Navbar from '../components/Navbar';
import HeroSection from '../components/Home/HeroSection';
import Testimonials from '../components/Home/Testimonials';
import Brands from '../components/Home/Brands';
import Footer from '../components/Footer';
import HowItWorks from '../components/Home/HowItWorks';
import BenefitsSection from '../components/Home/BenefitsSection';
import StatsSection from '../components/Home/StatsSection';
import FAQSection from '../components/Home/FAQSection';
import DG from '../assets/djgiangi.jpg';
import DD from '../assets/djdiegopozo.jpeg';
import DH from '../assets/djhans.jpeg';
import DAM from '../assets/djadrianmogollon.jpg';
import DA from '../assets/djAndrew.jpg';
import DDN from '../assets/DJ Diego Nieves.jpg';
import DLR from '../assets/DJ Luis Riofrío.jpeg';
import DJG from '../assets/DJG.jpeg';
import DDA from '../assets/DJ Diego Albán.jpg';
import DC from '../assets/DJ Checho.jpg';
import logo1 from '../assets/alba.png';
import logo2 from '../assets/L-Ancora.png';
import logo3 from '../assets/dembow.png';
import logo4 from '../assets/Logo 4.png';
import logo5 from '../assets/Logo 5.png';
import logo6 from '../assets/LogoUDEP.png'
import logo7 from '../assets/logo7.png';
import logo8 from '../assets/logo8.png';
import logo9 from '../assets/logo9.png';
import logo10 from '../assets/logo10.png';
import logo11 from '../assets/logo11.png';
import logo12 from '../assets/logo12.png';
import logo13 from '../assets/logo13.png';
import logo14 from '../assets/logo14.png';
import logo15 from '../assets/logo15.png';
import logo16 from '../assets/logo16.png';
import logo17 from '../assets/logo17.png';
import logo18 from '../assets/logo18.png';
import logo19 from '../assets/logo19.png';
import logo20 from '../assets/logo20.png';
import logo21 from '../assets/logo21.png';
import logo22 from '../assets/logo22.png';
import logo23 from '../assets/logo23.png';
import SEO from '../components/SEO';

const Home = () => {


  const testimonials = [
    { name: 'DJ Herrera', text: '“Muy buena aplicación, se proyecta a que será algo revolucionario en la industria del DJ. ¡Recomendado!”', image: DG },
    { name: 'DJ Diego Pozo', text: '“Una app indispensable para los DJ, facilita la conexión con el público y te ayuda a comprenderlos para mantener siempre la pista de baile on fire”.', image: DD },
    { name: 'DJ Hans', text: '“Lo máximo la aplicación. Me ayudó a interactuar más con el público y que la juerga sea más chévere. Lo máximo. Recomendadísimo 🔥”', image: DH },
    { name: 'DJ Adrián Mogollon', text: '“Esta app está revolucionando la industria musical, tenemos una mejor conexión entre nuestros invitados y clientes en lo que quieren oír y bailar, sin duda alguna, una de las mejores ideas que se ha podido crear !!', image: DAM },
    { name: 'DJ Andrew', text: '“Conocer el gusto musical de cada persona será más fácil gracias a DJ Ponla, una app diseñada para nosotros a fin de que nuestra tocada sea un éxito al 100%”', image: DA },
    { name: 'DJ Diego Nieves', text: '“Muy buena App, me ayudó a interactuar con el público en general. Recomendado.', image: DDN },
    { name: 'DJ Luis Riofrío', text: '“La aplicación es muy buena para nosotros los Djs nos ayudan a tener otra idea de que es lo que el público quiere escuchar, además de ser una aplicación muy amigable.”', image: DLR }, 
    { name: 'DJ John Gutierrez', text: '“Desde el primer día que conocí el aplicativo, no dudé en usarla y asimismo recomendarla, al 100%.”', image: DJG }, 
    { name: 'DJ Diego Albán', text: '“Una app muy interactiva e interesante para comunicarse con el público, y evitar esas incomodidades que solemos tener a la hora de pedidos o en momentos de bloqueo 💃”', image: DDA }, 
    { name: 'Bar Rock Mojito', text: '“Con DJ Ponla, subimos de nivel en el Bar, nos permite conocer de primera mano lo que nuestros clientes quieren escuchar creando un ambiente más ameno y activando la energía de la gente.', image:logo11 },
    { name: 'DJ Checho', text: '“Es una App muy innovadora y por no decir "La Mejor!!", te permite conocer muchos de los gustos de tu público, y hacer que puedas llevar la fiesta a otro nivel, rompiendo la barrera de los géneros musicales más comunes y hacer que la fiesta sea más variada y la mejor de la noche!!', image: DC },
  ];


  const brands = [
    { name: 'Alba', logo: logo1 },
    { name: 'L\'Ancora', logo: logo2 },
    { name: 'Dembow', logo: logo3 },
    {name: 'Manuel Agusto Eventos', logo:logo4},
    {name: 'La Casa de Milito', logo:logo5},
    {name: 'UDEP', logo:logo6},
    {name: 'logo', logo:logo7},
    {name: 'logo', logo:logo8},
    {name: 'logo', logo:logo9},
    {name: 'logo', logo:logo10},
    {name: 'logo', logo:logo11},
    {name: 'logo', logo:logo12},
    {name: 'logo', logo:logo13},
    {name: 'logo', logo:logo14},
    {name: 'logo', logo:logo15},
    {name: 'logo', logo:logo16},
    {name: 'logo', logo:logo17},
    {name: 'logo', logo:logo18},
    {name: 'logo', logo:logo19},
    {name: 'logo', logo:logo20},
    {name: 'logo', logo:logo21},
    {name: 'logo', logo:logo22},
    {name: 'logo', logo:logo23},
  ];

  // Datos estructurados específicos para la página de inicio
  const homeStructuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "DJ Ponla",
    "applicationCategory": "EntertainmentApplication",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "9.99",
      "priceCurrency": "USD"
    },
    "description": "DJ Ponla conecta DJs y público a través de un sistema de QR para sugerencias musicales en tiempo real."
  };

  return (
    <>
      <SEO 
        title="DJ Ponla - Conecta DJs y público con tecnología QR" 
        description="Sistema QR para sugerencias musicales en tiempo real. Tu público escanea, envía canciones y tú eliges cuáles poner. Potencia tu carrera como DJ con estadísticas de gustos musicales."
        keywords="DJ Ponla, aplicación para DJs, pedidos de canciones QR, conexión DJ y público, estadísticas musicales, sugerencias musicales en vivo"
        canonicalUrl="/"
        structuredData={homeStructuredData}
      />
      <Navbar />
      <Container maxWidth="lg" sx={{paddingTop:"80px"}}>
        <HeroSection />
        <div id="testimonials">
          <Testimonials testimonials={testimonials} />
        </div>
        <div id="how-it-works">
          <HowItWorks />
        </div>
        <div id="benefits-section">
          <BenefitsSection />
        </div>
        <div id="stats-section">
          <StatsSection />
        </div>
        <Brands brands={brands} />
        <div id="faq-section">
          <FAQSection />
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default Home;
