import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const Brands = ({ brands }) => {
  const [position1, setPosition1] = useState(0);
  const [position2, setPosition2] = useState(0);
  
  // Configuración del slider
  const itemWidth = 150;
  const gap = 30;
  
  // Distribuir los 23 logos
  const firstRow = brands.slice(0, 12);
  const secondRow = brands.slice(12);

  useEffect(() => {
    const row1Width = firstRow.length * (itemWidth + gap);
    const row2Width = secondRow.length * (itemWidth + gap);
    
    const animate = () => {
      setPosition1(prev => {
        const newPos = prev - 1;
        return newPos <= -row1Width ? 0 : newPos;
      });
      
      setPosition2(prev => {
        const newPos = prev + 1;
        return newPos >= row2Width ? 0 : newPos;
      });
    };

    const interval = setInterval(animate, 30);
    return () => clearInterval(interval);
  }, [firstRow.length, secondRow.length]);

  const createRow = (items, position, direction = 1) => {
    const rowContent = [...items, ...items, ...items]; // Triple repetición
    
    return (
      <Box
        sx={{
          display: 'flex',
          gap: `${gap}px`,
          position: 'relative',
          width: '100%',
          height: '100px',
          alignItems: 'center',
        }}
      >
        {/* Primera copia */}
        <Box
          sx={{
            display: 'flex',
            gap: `${gap}px`,
            position: 'absolute',
            transform: `translateX(${position * direction}px)`,
            transition: 'transform 0.1s linear',
          }}
        >
          {rowContent.map((brand, index) => (
            <Box
              key={`${index}-1`}
              sx={{
                minWidth: `${itemWidth}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={brand.logo}
                alt={brand.name}
                style={{
                  maxWidth: '100px',
                  maxHeight: '80px',
                  objectFit: 'contain',
                  filter: 'grayscale(0.4)',
                  transition: 'filter 0.3s ease',
                }}
                onMouseOver={(e) => e.target.style.filter = 'grayscale(0)'}
                onMouseOut={(e) => e.target.style.filter = 'grayscale(0.4)'}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box 
      sx={{ 
        mb: 5,
        overflow: 'hidden',
        width: '100%',
      }}
    >
      
      {createRow(firstRow, position1)}
      {createRow(secondRow, position2, -1)}
    </Box>
  );
};

export default Brands;
