import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import GroupIcon from '@mui/icons-material/Group';
import PublicIcon from '@mui/icons-material/Public';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';

const stats = [
  {
    number: 50,
    prefix: "+",
    label: "DJs en nuestra comunidad",
    icon: <GroupIcon sx={{ fontSize: 40, color: '#b70a20' }} />
  },
  {
    number: 10000,
    prefix: "+",
    label: "canciones sugeridas",
    icon: <QueueMusicIcon sx={{ fontSize: 40, color: '#b70a20' }} />
  },
  {
    number: 10,
    prefix: "+",
    label: "países con DJs clientes",
    icon: <PublicIcon sx={{ fontSize: 40, color: '#b70a20' }} />
  },
  {
    number: 30,
    prefix: "+",
    label: "Locales y eventos",
    icon: <LocationOnIcon sx={{ fontSize: 40, color: '#b70a20' }} />
  }
];

const StatsSection = () => {
  const [counts, setCounts] = useState(stats.map(() => 0));

  useEffect(() => {
    const duration = 2000; // 2 segundos para la animación
    const steps = 50; // Número de pasos en la animación
    const stepDuration = duration / steps;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          stats.forEach((stat, index) => {
            const increment = stat.number / steps;
            let current = 0;
            let step = 0;

            const timer = setInterval(() => {
              step++;
              current = Math.min(stat.number, Math.floor(increment * step));
              setCounts(prev => prev.map((count, i) => i === index ? current : count));

              if (step >= steps) {
                clearInterval(timer);
              }
            }, stepDuration);
          });
        }
      },
      { threshold: 0.1 }
    );

    const element = document.getElementById('stats-section');
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  return (
    <Box 
      id="stats-section"
      sx={{ 
        py: { xs: 6, md: 10 }, 
        backgroundColor: '#111111',
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography 
            variant="h3" 
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: '#ffffff',
              mb: 3
            }}
          >
            2025 y no paramos de{' '}
            <Box component="span" sx={{ color: '#b70a20' }}>
              ponerla
            </Box>
          </Typography>
        </Box>

        <Grid 
          container 
          spacing={4} 
          sx={{ 
            justifyContent: 'center',
            '& .MuiGrid-item': {
              display: 'flex',
              justifyContent: 'center'
            }
          }}
        >
          {stats.map((stat, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  width: '100%',
                  maxWidth: 300,
                  p: 3,
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: 2,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    boxShadow: '0 10px 20px rgba(183, 10, 32, 0.3)',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <Box sx={{ mb: 2 }}>
                  {stat.icon}
                </Box>
                <Typography 
                  variant="h3" 
                  sx={{ 
                    fontWeight: 'bold',
                    color: '#ffffff',
                    mb: 1,
                    fontSize: { xs: '2.5rem', md: '3rem' }
                  }}
                >
                  {stat.prefix}{counts[index].toLocaleString()}
                </Typography>
                <Typography 
                  variant="h6"
                  sx={{
                    color: '#A6A5A6',
                    fontWeight: 400,
                    fontSize: { xs: '1rem', md: '1.1rem' }
                  }}
                >
                  {stat.label}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Botón divertido de Call to Action */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            mt: 6 
          }}
        >
          <Box
            onClick={() => {
              window.location.href = '/qr-dj-product';
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }}
            sx={{
              backgroundColor: '#b70a20',
              color: 'white',
              py: 2,
              px: 5,
              borderRadius: '50px',
              fontWeight: 'bold',
              cursor: 'pointer',
              boxShadow: '0 4px 15px rgba(183, 10, 32, 0.5)',
              transition: 'all 0.3s ease',
              position: 'relative',
              overflow: 'hidden',
              '&:hover': {
                backgroundColor: '#d60d26',
                transform: 'translateY(-3px) scale(1.05)',
                boxShadow: '0 6px 20px rgba(183, 10, 32, 0.6)',
              },
              '&:active': {
                transform: 'translateY(1px)',
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                top: '-50%',
                left: '-60%',
                width: '200%',
                height: '200%',
                background: 'radial-gradient(circle, rgba(255,255,255,0.2) 0%, transparent 60%)',
                opacity: 0,
                transition: 'opacity 0.3s ease',
              },
              '&:hover::after': {
                opacity: 1,
              }
            }}
          >
            <Typography 
              variant="button" 
              sx={{ 
                fontWeight: 800, 
                fontSize: '1.2rem', 
                letterSpacing: 1, 
                textTransform: 'uppercase'
              }}
            >
              ¡Ponla Ya!
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default StatsSection; 