import React from 'react';
import { Box, Typography, TextField, Paper, IconButton, Button } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const GalleryLocalesForm = ({ formData, errors, handleGalleryUpload, handleChange, handleRemoveImage }) => {
  const remainingPhotos = 4 - (formData.galleryPreviews?.length || 0);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + (formData.galleryPreviews?.length || 0) <= 4) {
      handleGalleryUpload(files);
    }
  };

  return (
    <Paper 
      elevation={3}
      sx={{
        p: 3,
        bgcolor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
          transform: 'translateY(-2px)'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <CollectionsIcon sx={{ color: '#b70a20', mr: 2, fontSize: 28 }} />
        <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
          Galería y Locales
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" sx={{ color: 'white', mb: 2 }}>
          Galería de Fotos ({remainingPhotos} fotos restantes)
        </Typography>
        
        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: 2,
          mb: 2
        }}>
          {formData.galleryPreviews?.map((preview, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                aspectRatio: '1',
                borderRadius: 2,
                overflow: 'hidden',
                border: '1px solid rgba(255,255,255,0.1)',
                '&:hover .overlay': {
                  opacity: 1
                }
              }}
            >
              <img
                src={preview}
                alt={`Gallery ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
              <Box
                className="overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  bgcolor: 'rgba(0,0,0,0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0,
                  transition: 'all 0.3s ease'
                }}
              >
                <IconButton
                  onClick={() => handleRemoveImage(index)}
                  sx={{ 
                    color: '#ff1744',
                    '&:hover': {
                      transform: 'scale(1.1)',
                      bgcolor: 'rgba(255,23,68,0.1)'
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}

          {remainingPhotos > 0 && (
            <Button
              component="label"
              variant="outlined"
              sx={{
                aspectRatio: '1',
                border: '2px dashed rgba(255,255,255,0.3)',
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                '&:hover': {
                  borderColor: '#b70a20',
                  bgcolor: 'rgba(183, 10, 32, 0.1)',
                  transform: 'translateY(-2px)'
                }
              }}
            >
              <AddPhotoAlternateIcon sx={{ color: '#b70a20', fontSize: 40, mb: 1 }} />
              <Typography variant="body2" sx={{ color: 'white' }}>
                Agregar foto ({remainingPhotos} restantes)
              </Typography>
              <input
                type="file"
                hidden
                multiple
                accept="image/*"
                onChange={handleFileChange}
              />
            </Button>
          )}
        </Box>
        
        {errors.gallery && (
          <Typography color="error" variant="caption" sx={{ display: 'block', mt: 1 }}>
            {errors.gallery}
          </Typography>
        )}
      </Box>

      <TextField
        fullWidth
        multiline
        rows={3}
        variant="filled"
        label="Locales donde sueles tocar"
        name="locales"
        value={formData.locales}
        onChange={handleChange}
        error={!!errors.locales}
        helperText={errors.locales || "Separa los nombres de los locales con comas"}
        sx={{
          '& .MuiFilledInput-root': {
            bgcolor: 'rgba(255, 255, 255, 0.05)',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
            },
            '&.Mui-focused': {
              bgcolor: 'rgba(255, 255, 255, 0.12)'
            }
          },
          '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)'
          },
          '& .MuiInputBase-input': {
            color: 'white'
          }
        }}
      />
    </Paper>
  );
};

export default GalleryLocalesForm; 