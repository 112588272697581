import React from 'react';
import {
  Grid,
  Typography,
  Card,
  Box,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  LinearProgress,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const cardStyles = {
  backgroundColor: "rgba(34, 34, 34, 0.95)",
  backdropFilter: "blur(20px)",
  borderRadius: '24px',
  p: 3,
  minHeight: { xs: 'auto', md: '400px' },
  boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)'
  }
};

const ArtistAnalysis = ({ artistAnalysis }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Tomamos solo los primeros 5 artistas
  const top5Artists = artistAnalysis.slice(0, 5);

  const MobileArtistList = () => (
    <List sx={{ width: '100%', color: '#fff' }}>
      {top5Artists.map((artist, index) => (
        <ListItem 
          key={artist.name}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderBottom: '1px solid rgba(255,255,255,0.1)',
            py: 2,
            '&:last-child': {
              borderBottom: 'none'
            }
          }}
        >
          <Box sx={{ 
            width: '100%', 
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: '#b70a20',
                fontWeight: 'bold',
                minWidth: '28px'
              }}
            >
              #{index + 1}
            </Typography>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {artist.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                {artist.requests} solicitudes · {artist.uniqueSongs} canciones únicas
              </Typography>
            </Box>
          </Box>
          
          <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ 
                mb: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <span>Se puso ✅</span>
                <span>
                  <span style={{ fontWeight: 'bold' }}>{artist.played}</span>
                  <span style={{ opacity: 0.6 }}> ({artist.playRate}%)</span>
                </span>
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={parseFloat(artist.playRate)} 
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#b70a20'
                  },
                  borderRadius: 2,
                  height: 8
                }}
              />
            </Box>
            
            <Box>
              <Typography variant="body2" sx={{ 
                mb: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <span>No la quiero poner ❌</span>
                <span>
                  <span style={{ fontWeight: 'bold' }}>{artist.notPlayed}</span>
                  <span style={{ opacity: 0.6 }}> ({((artist.notPlayed / artist.requests) * 100).toFixed(1)}%)</span>
                </span>
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={parseFloat((artist.notPlayed / artist.requests) * 100)} 
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#FF6B6B'
                  },
                  borderRadius: 2,
                  height: 8
                }}
              />
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );

  const DesktopChart = () => (
    <Box sx={{ height: 320 }}>
      <ResponsiveContainer>
        <BarChart
          data={top5Artists}
          margin={{ top: 15, right: 30, left: 20, bottom: 35 }}
          barGap={0}
          barCategoryGap="15%"
        >
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
          <XAxis 
            dataKey="name" 
            angle={45} 
            textAnchor="start" 
            height={50} 
            stroke="#fff"
            interval={0}
            tick={{ fill: '#fff', fontSize: 11 }}
          />
          <YAxis 
            stroke="#fff"
            tick={{ fill: '#fff', fontSize: 11 }}
          />
          <Tooltip 
            contentStyle={{ 
              backgroundColor: '#1e1e1e', 
              border: '1px solid rgba(255,255,255,0.1)',
              borderRadius: '12px',
              boxShadow: '0 4px 20px rgba(0,0,0,0.3)'
            }}
            labelStyle={{ color: '#fff', fontWeight: 'bold' }}
            itemStyle={{ color: '#fff' }}
            formatter={(value, name) => [
              `${value} canciones`,
              name === "Se puso" ? "Se puso ✅" : "No la quiero poner ❌"
            ]}
          />
          <Legend 
            verticalAlign="bottom" 
            height={25}
            wrapperStyle={{ 
              color: '#fff',
              paddingTop: '5px'
            }}
            formatter={(value) => (
              <span style={{ color: '#fff', fontSize: '0.9rem' }}>
                {value === "Se puso" ? "Se puso ✅" : "No la quiero poner ❌"}
              </span>
            )}
          />
          <Bar 
            name="Se puso" 
            dataKey="played" 
            fill="#b70a20" 
            radius={[4, 4, 0, 0]}
          />
          <Bar 
            name="No la quiero poner" 
            dataKey="notPlayed" 
            fill="#FF6B6B"
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={cardStyles}>
          <Typography variant="h5" sx={{ 
            color: '#fff', 
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: { xs: '1.2rem', md: '1.5rem' },
          }}>
            Top 5 Artistas Más Solicitados
          </Typography>
          
          {isMobile ? <MobileArtistList /> : <DesktopChart />}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ArtistAnalysis; 