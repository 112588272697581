import React, { useState, lazy, Suspense, memo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Container,
  CircularProgress,
  Box,
  BottomNavigationAction,
} from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import useDjData from '../hooks/useDjData';
import { FloatingNav, GradientText } from '../components/DjProfile/StyledComponents';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Breadcrumbs from '../components/Breadcrumbs';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import SEO from '../components/SEO';
import Navbar from '../components/Navbar';

// Lazy-loaded components
const BioSection = lazy(() => import('../components/DjProfile/BioSection'));
const MusicSection = lazy(() => import('../components/DjProfile/MusicSection'));
const GallerySection = lazy(() => import('../components/DjProfile/GallerySection'));
const BookingSection = lazy(() => import('../components/DjProfile/BookingSection'));
const AdditionalInfo = lazy(() => import('../components/DjProfile/AdditionalInfo'));

// Personalizamos el componente FloatingNav
const EnhancedFloatingNav = styled(FloatingNav)(({ theme }) => ({
  borderRadius: '28px',
  padding: '5px',
  backgroundColor: 'rgba(18, 18, 24, 0.85)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
  '& .MuiBottomNavigationAction-root': {
    transition: 'all 0.3s ease',
    minWidth: 'auto',
    padding: '8px 12px',
    borderRadius: '20px',
    margin: '0 2px',
    '&.Mui-selected': {
      backgroundColor: 'rgba(211, 47, 47, 0.15)',
    },
  },
}));

// Estilo para la etiqueta del botón activo
const NavLabel = styled(Typography)(({ theme, active }) => ({
  fontSize: '0.75rem',
  marginTop: '2px',
  transition: 'all 0.3s ease',
  opacity: active ? 1 : 0,
  maxHeight: active ? '20px' : '0',
  color: active ? '#D32F2F' : 'inherit',
}));

function DjProfile() {
  const { djName } = useParams();
  const { djData, loading, error } = useDjData(djName);

  const [state, setState] = useState({
    navValue: 'bio',
    bioExpanded: false,
    showAllLocales: false,
  });

  const handleBioExpandClick = () => {
    setState((prev) => ({ ...prev, bioExpanded: !prev.bioExpanded }));
  };

  const { navValue, bioExpanded, showAllLocales } = state;

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress sx={{color:'#fff'}} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" align="center" sx={{ mt: 4 }}>
        Error al cargar los datos del DJ.
      </Typography>
    );
  }

  const photoUrl = djData.photo?.[0]?.url || null;
  const coverPhotoUrl = djData.coverPhotoUrl?.[0]?.url || null;

  const {
    Lugar,
    Experiencia,
    'Precio Min': precioMin,
    'Precio Max': precioMax,
    'Nombre Local': nombreLocal = '',
    youtubeUrl,
  } = djData || {};

  // Crear los items de breadcrumb
  const breadcrumbItems = [
    { name: 'Inicio', link: '/' },
    { name: 'DJs', link: '/djs' },
    { name: djData?.name || 'Perfil DJ', link: `/dj/${djName}` }
  ];

  // Datos estructurados para perfil DJ
  const getDjStructuredData = () => {
    if (!djData) return null;
    
    return {
      "@context": "https://schema.org",
      "@type": "Person",
      "name": djData.name,
      "image": djData.image,
      "description": djData.bio || `${djData.name} es un DJ profesional que utiliza DJ Ponla para conectar con su público.`,
      "url": `https://djponla.com/dj/${djName}`,
      "jobTitle": "DJ",
      "knowsAbout": djData.genres || ["Música electrónica", "DJ"],
      "sameAs": [
        djData.instagram,
        djData.facebook,
        djData.tiktok,
        djData.youtube
      ].filter(Boolean)
    };
  };

  return (
    <>
      {djData && (
        <SEO 
          title={`${djData.name} | DJ Ponla - Perfil oficial`}
          description={`Conoce a ${djData.name}, DJ profesional en ${djData.location || 'Perú'}. ${djData.bio?.substring(0, 120) || 'Reserva ahora y haz de tu evento una experiencia inolvidable.'}`}
          keywords={`${djData.name}, DJ ${djData.location || 'Perú'}, contratar DJ, DJ profesional, ${djData.genres?.join(', ')}`}
          canonicalUrl={`/dj/${djData.slug || djName}`}
          ogImage={djData.profileImage}
          structuredData={getDjStructuredData()}
        />
      )}
      
      <Navbar />
      <Container maxWidth="lg">
        <BreadcrumbSchema items={breadcrumbItems} />
        <Container maxWidth="sm" sx={{ mt: 4, pb: 9 }}>
          {coverPhotoUrl ? (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '180px',
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url(${coverPhotoUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: 3,
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                pb: '40px',
                marginBottom: '4rem',
              }}
            >
              {photoUrl && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '-60px',
                    width: '180px',
                    height: '180px',
                    backgroundColor: '#fff',
                    backgroundImage: `url(${photoUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                    borderRadius: '2rem',
                  }}
                />
              )}
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
              {photoUrl && (
                <Box
                  sx={{
                    width: '160px',
                    height: '160px',
                    backgroundColor: '#fff',
                    backgroundImage: `url(${photoUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                    borderRadius: '2rem',
                  }}
                />
              )}
            </Box>
          )}

          <GradientText variant="h4" align="center" gutterBottom sx={{ mt: 2, fontWeight: 'bold' }}>
            {djData.name}
          </GradientText>

          <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
            {djData.tagline}
          </Typography>

          <Suspense fallback={<CircularProgress />}>
            <AdditionalInfo
              Lugar={Lugar}
              Experiencia={Experiencia}
              precioMin={precioMin}
              precioMax={precioMax}
              nombreLocal={nombreLocal}
              instagramUrl={djData.instagram}
              facebookUrl={djData.facebook}
              tiktokUrl={djData.tiktok}
              spotifyUrl={djData.spotify}
            />
            <BioSection
              bio={djData.bio}
              bioExpanded={bioExpanded}
              handleBioExpandClick={handleBioExpandClick}
            />
            <MusicSection musicUrl={djData.musicUrl} youtubeUrl={youtubeUrl} />
            <GallerySection gallery={djData.gallery} />
            <BookingSection selectedColor="#D32F2F" djname={djData.name} phone={djData.Phone} />
          </Suspense>
        </Container>

        <EnhancedFloatingNav
          value={navValue}
          onChange={(event, newValue) => {
            setState((prev) => ({ ...prev, navValue: newValue }));
            const section = document.getElementById(newValue);
            if (section) {
              section.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <Tooltip title="Bio" placement="top">
            <BottomNavigationAction
              value="bio"
              icon={
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <PersonRoundedIcon 
                    fontSize="medium" 
                    sx={{ color: navValue === 'bio' ? '#D32F2F' : 'rgba(255,255,255,0.7)' }}
                  />
                  <NavLabel variant="caption" active={navValue === 'bio'}>
                    Bio
                  </NavLabel>
                </Box>
              }
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                }
              }}
            />
          </Tooltip>
          
          <Tooltip title="Música" placement="top">
            <BottomNavigationAction
              value="music"
              icon={
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <MusicNoteIcon 
                    fontSize="medium" 
                    sx={{ color: navValue === 'music' ? '#D32F2F' : 'rgba(255,255,255,0.7)' }}
                  />
                  <NavLabel variant="caption" active={navValue === 'music'}>
                    Música
                  </NavLabel>
                </Box>
              }
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                }
              }}
            />
          </Tooltip>
          
          <Tooltip title="Galería" placement="top">
            <BottomNavigationAction
              value="gallery"
              icon={
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <PhotoLibraryIcon 
                    fontSize="medium" 
                    sx={{ color: navValue === 'gallery' ? '#D32F2F' : 'rgba(255,255,255,0.7)' }}
                  />
                  <NavLabel variant="caption" active={navValue === 'gallery'}>
                    Galería
                  </NavLabel>
                </Box>
              }
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                }
              }}
            />
          </Tooltip>
          
          <Tooltip title="Reservar" placement="top">
            <BottomNavigationAction
              value="booking"
              icon={
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <ContactMailIcon 
                    fontSize="medium" 
                    sx={{ color: navValue === 'booking' ? '#D32F2F' : 'rgba(255,255,255,0.7)' }}
                  />
                  <NavLabel variant="caption" active={navValue === 'booking'}>
                    Reservar
                  </NavLabel>
                </Box>
              }
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                }
              }}
            />
          </Tooltip>
        </EnhancedFloatingNav>
      </Container>
    </>
  );
}

export default memo(DjProfile);
