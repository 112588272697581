import React from "react";
import { Box, Typography, Card, CardContent, Button, Grid, useTheme, useMediaQuery, Chip, Tooltip, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import QrCodeIcon from "@mui/icons-material/QrCode";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { motion } from "framer-motion";
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Breadcrumbs from '../components/Breadcrumbs';
import BreadcrumbSchema from '../components/BreadcrumbSchema';

// Extraer estilos comunes a un objeto de tema personalizado
const cardStyles = {
  borderRadius: '24px', // Bordes más redondeados
  width: '100%',
  backgroundColor: "rgba(34, 34, 34, 0.95)", // Más oscuro para mejor contraste
  backdropFilter: "blur(20px)", // Más blur para mejor efecto
  color: "white",
  transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
  position: "relative",
  overflow: "hidden",
  boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.2)',
};

const buttonBaseStyles = {
  transition: "all 0.3s ease",
  borderRadius: '12px', // Bordes más redondeados para botones
  fontWeight: "bold",
  zIndex: 2,
};

const Pricing = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currencies, setCurrencies] = React.useState({
    'QR DJ': 'PEN',
    'Página web DJ': 'PEN'
  });

  const trackQRDJButtonClick = () => {
    try {
      window.gtag('event', 'click_buy_qr_dj_product', {
        event_category: 'Conversiones',
        event_label: 'Botón Comprar QR DJ',
        product_name: 'QR DJ',
        currency: currencies['QR DJ'],
        price: currencies['QR DJ'] === 'PEN' ? '35' : '9.99',
        timestamp: new Date().toISOString(),
        value: 1
      });
    } catch (error) {
      console.error('Error al registrar evento de compra QR DJ:', error);
    }
  };

  const trackProfileDJButtonClick = () => {
    try {
      window.gtag('event', 'click_buy_profile_dj_product', {
        event_category: 'Conversiones',
        event_label: 'Botón Comprar Página Web DJ',
        product_name: 'Página web DJ',
        currency: currencies['Página web DJ'],
        price: currencies['Página web DJ'] === 'PEN' ? '7' : '1.99',
        timestamp: new Date().toISOString(),
        value: 1
      });
    } catch (error) {
      console.error('Error al registrar evento de compra Página Web DJ:', error);
    }
  };

  const handleCurrencyChange = (productTitle, newCurrency) => {
    setCurrencies(prev => ({
      ...prev,
      [productTitle]: newCurrency
    }));
  };

  const handleQRDJClick = () => {
    trackQRDJButtonClick();
    navigate("/qr-dj-product");
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleProfileDJClick = () => {
    trackProfileDJButtonClick();
    navigate("/profile-dj-product");
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const products = [
    {
      icon: <QrCodeIcon sx={{ 
        fontSize: isMobile ? 70 : 90, 
        color: '#ffffff',
        mb: { xs: 2, sm: 3 },
        filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.2))', // Añadido shadow al icono
      }} />,
      title: "QR DJ",
      isPopular: true, // Nuevo campo para destacar
      price: {
        PEN: {
          amount: "35",
          currency: "S/.",
          period: "/mes",
          prefix: "Desde",
        },
        USD: {
          amount: "9.99",
          currency: "$",
          period: "/mes",
          prefix: "From",
        }
      },
      description: "Sistema de petición de canciones mediante código QR para DJs",
      features: [
        { 
          icon: <CheckIcon fontSize="small" />, 
          text: "Código QR único para cada evento",
          tooltip: "Genera códigos QR personalizados para cada uno de tus eventos" // Nuevo campo
        },
        { icon: <CheckIcon fontSize="small" />, text: "Recibe peticiones en tiempo real" },
        { icon: <CheckIcon fontSize="small" />, text: "Interfaz intuitiva para el público" },
        { icon: <CheckIcon fontSize="small" />, text: "Control total sobre la playlist" }
      ],
      onClick: handleQRDJClick,
      color: "#b70a20"
    },
    {
      icon: <AccountCircleIcon sx={{ 
        fontSize: isMobile ? 70 : 90, 
        color: '#ffffff',
        mb: { xs: 2, sm: 3 },
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "scale(1.1)"
        }
      }} />,
      title: "Página web DJ",
      price: {
        PEN: {
          amount: "7",
          currency: "S/.",
          period: "/mes",
          prefix: "Desde"
        },
        USD: {
          amount: "1.99",
          currency: "$",
          period: "/mes",
          prefix: "From"
        }
      },
      description: "Tu presencia profesional en línea como DJ",
      features: [
        { icon: <CheckIcon fontSize="small" />, text: "Página personalizada profesional" },
        { icon: <CheckIcon fontSize="small" />, text: "Galería multimedia integrada" },
        { icon: <CheckIcon fontSize="small" />, text: "Sistema de contacto directo" },
        { icon: <CheckIcon fontSize="small" />, text: "Presencia online optimizada" }
      ],
      onClick: handleProfileDJClick,
      color: "#b70a20"
    }
  ];

  // Mover la definición de variantes de animación fuera del componente
  const animations = {
    containerVariants: {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: { staggerChildren: 0.3 }
      }
    },
    cardVariants: {
      hidden: { opacity: 0, y: 50 },
      visible: { 
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.6,
          ease: "easeOut"
        }
      }
    }
  };

  // Crear los items de breadcrumb
  const breadcrumbItems = [
    { name: 'Inicio', link: '/' },
    { name: 'Precios', link: '/comprar' }
  ];

  return (
    <>
      <Navbar />
      <Container>
        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          sx={{
            textAlign: "center",
            padding: { xs: 2, sm: 2 },
            backgroundColor: '#121212',
            color: "white",
            minHeight: "100vh",
            mt: { xs: 10, md: 8 },
            overflow: 'hidden',
            width: '100%',
            pt: { xs: 2, md: 4 }
          }}
        >
          <Container maxWidth="lg">
            <BreadcrumbSchema items={breadcrumbItems} />

            <Box sx={{ mb: 3 }}>
              
              <Box sx={{ 
                mb: { xs: 2, sm: 2 } // Reducido el margen en mobile
              }}>
                <Typography 
                  variant={isMobile ? "h5" : "h4"} 
                  gutterBottom 
                  sx={{ 
                    mt: { xs: 3, sm: 4 }, // Reducido el margen superior en mobile
                    fontWeight: "bold",
                    color: 'white' // Volvemos al color blanco sólido
                  }}
                >
                  Nuestros Productos
                </Typography>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    color: 'rgba(255,255,255,0.7)',
                    maxWidth: '600px',
                    mx: 'auto',
                    px: 2,
                    mb: { xs: 1, sm: 1 } // Ajustado el margen inferior
                  }}
                >
                  Elige el plan que mejor se adapte a tus necesidades y comienza a crecer tu negocio
                </Typography>
              </Box>
            </Box>
            
            <Box 
              component={motion.div}
              variants={animations.containerVariants}
              initial="hidden"
              animate="visible"
              sx={{ 
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 2, sm: 4 },
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: "1200px",
                margin: "0 auto",
                px: { xs: 2, sm: 6 },
                width: '100%'
              }}
            >
              {products.map((product, index) => (
                <Box
                  key={index}
                  component={motion.div}
                  variants={animations.cardVariants}
                  sx={{
                    width: '100%',
                    maxWidth: { xs: '100%', sm: '450px' },
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Card 
                    sx={{ 
                      ...cardStyles,
                      width: '100%',
                      mx: 'auto',
                      my: { xs: 1, sm: 2 },
                      "&:hover": {
                        transform: "translateY(-10px) scale(1.02)",
                        boxShadow: `0 20px 40px -15px ${product.color}80`,
                        "& .card-content": {
                          transform: "translateY(-5px)"
                        },
                        "& .feature-icon": {
                          color: product.color,
                          transform: "scale(1.1)"
                        }
                      },
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        inset: 0,
                        background: `linear-gradient(135deg, ${product.color}15, transparent 50%, ${product.color}10)`,
                        opacity: 0.1,
                        transition: "opacity 0.4s ease"
                      },
                      "&:hover::before": {
                        opacity: 0.3
                      }
                    }}
                  >
                    {product.isPopular && (
                      <Chip
                        icon={<StarIcon sx={{ color: '#FFD700 !important' }} />}
                          label="Más Popular"
                          sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            backgroundColor: 'rgba(255, 215, 0, 0.1)',
                            color: '#FFD700',
                            border: '1px solid #FFD700',
                            '& .MuiChip-label': {
                              fontWeight: 'bold'
                            }
                          }}
                        />
                      )}
                    <CardContent 
                      className="card-content"
                      sx={{ 
                        position: "relative", 
                        zIndex: 1, 
                        pt: 3,
                        px: { xs: 3, sm: 3 },
                        pb: { xs: 4, sm: 3 },
                        transition: "transform 0.3s ease"
                      }}
                    >
                      {product.icon}

                      <Typography 
                        variant={isMobile ? "h4" : "h3"}
                        gutterBottom
                        sx={{ 
                          fontWeight: "bold",
                          mb: { xs: 1, sm: 2 },
                          fontSize: { 
                            xs: "1.75rem", 
                            sm: "2rem", 
                            md: "2.5rem" 
                          },
                          color: '#ffffff'
                        }}
                      >
                        {product.title}
                      </Typography>

                      <Box sx={{ 
                        display: 'flex',
                        justifyContent: 'center',
                        gap: { xs: 2, sm: 1 },
                        mb: { xs: 3, sm: 2 },
                        mt: { xs: 2, sm: 1 }
                      }}>
                        {['PEN', 'USD'].map((curr) => (
                          <Button
                            key={curr}
                            size="small"
                            variant={currencies[product.title] === curr ? 'contained' : 'outlined'}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCurrencyChange(product.title, curr);
                            }}
                            sx={{
                              ...buttonBaseStyles,
                              minWidth: '60px',
                              fontSize: '0.75rem',
                              py: 0.5,
                              px: 1,
                              backgroundColor: currencies[product.title] === curr ? product.color : 'transparent',
                              borderColor: product.color,
                              color: 'white',
                              '&:hover': {
                                backgroundColor: currencies[product.title] === curr ? product.color : `${product.color}30`,
                                borderColor: product.color
                              }
                            }}
                          >
                            {curr === 'PEN' ? 'Soles' : 'USD'}
                          </Button>
                        ))}
                      </Box>

                      <Box sx={{ 
                        position: 'relative',
                        mb: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                      }}>
                        {product.price[currencies[product.title]].savings && (
                          <Chip
                            label={product.price[currencies[product.title]].savings}
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: -10,
                              backgroundColor: '#4CAF50',
                              color: 'white',
                              fontSize: '0.75rem',
                              fontWeight: 'bold'
                            }}
                          />
                        )}
                        <Typography variant="caption" sx={{ mb: 0.5 }}>
                          {product.price[currencies[product.title]].prefix}
                        </Typography>
                        <Box sx={{ 
                          display: 'flex',
                          alignItems: 'baseline',
                          justifyContent: 'center',
                          gap: 0.5
                        }}>
                          <Typography 
                            variant="h6"
                            sx={{ 
                              color: 'white',
                              fontWeight: "500"
                            }}
                          >
                            {product.price[currencies[product.title]].currency}
                          </Typography>
                          <Typography 
                            variant="h3" 
                            sx={{ 
                              color: 'white',
                              fontWeight: "bold",
                              lineHeight: 1
                            }}
                          >
                            {product.price[currencies[product.title]].amount}
                          </Typography>
                          <Typography 
                            variant="subtitle1"
                            sx={{ 
                              color: 'white',
                              fontWeight: "500",
                              opacity: 0.8
                            }}
                          >
                            {product.price[currencies[product.title]].period}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography 
                        variant="body1" 
                        sx={{ 
                          mb: { xs: 2, sm: 3 },
                          opacity: 0.9,
                          px: { xs: 1, sm: 2 },
                          fontSize: { xs: "0.9rem", sm: "1rem" }
                        }}
                      >
                        {product.description}
                      </Typography>

                      <Box sx={{ mb: 3 }}>
                        {product.features.map((feature, idx) => (
                          <Tooltip 
                            key={idx}
                            title={feature.tooltip}
                            placement="top"
                            arrow
                          >
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                color: '#aaa',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                py: 1,
                                px: 2,
                                borderRadius: 1,
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                  backgroundColor: 'rgba(255,255,255,0.05)',
                                  color: 'white',
                                  transform: 'translateX(5px)'
                                }
                              }}
                            >
                              {feature.icon}
                              {feature.text}
                              <InfoOutlinedIcon 
                                sx={{ 
                                  ml: 'auto', 
                                  fontSize: 16, 
                                  opacity: 0.5 
                                }} 
                              />
                            </Typography>
                          </Tooltip>
                        ))}
                      </Box>

                      <Button 
                        variant="contained" 
                        onClick={product.onClick}
                        sx={{ 
                          ...buttonBaseStyles,
                          background: `linear-gradient(45deg, ${product.color} 30%, ${product.color}dd 90%)`,
                          boxShadow: `0 8px 24px -12px ${product.color}`,
                          '&:hover': {
                            background: `linear-gradient(45deg, ${product.color} 30%, ${product.color} 90%)`,
                            transform: 'translateY(-2px)',
                            boxShadow: `0 12px 28px -8px ${product.color}`
                          }
                        }}
                      >
                        ¡Lo quiero!
                      </Button>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Box>
          </Container>
        </Box>
      </Container>
    </>
  );
};

export default Pricing;