import React, { useState, useCallback } from "react";
import { Box, Typography, useTheme, IconButton, Container } from "@mui/material";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ArrowBackIosNew as ArrowBackIosNewIcon, ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import StepperComponent from "../components/StepperComponent";
import GaleriaPreview from '../assets/galeria-preview.png';
import WhatsappPreview from '../assets/whatsapp-preview.png';
import UbicacionPreview from '../assets/ubicacion-preview.png';
import MusicaPreview from '../assets/musica-preview.png';
import BillingToggle from "../components/BillingToggle";
import PlanCard from "../components/PlanCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import SEO from '../components/SEO';

const CAROUSEL_FEATURES = [
  {
    image: GaleriaPreview,
    title: "Galería Visual Profesional",
    subtitle: "Tu portafolio digital como DJ",
    description: "Destaca con una galería multimedia que muestra lo mejor de tus eventos. Comparte fotos y videos de tus presentaciones que capturen la energía de tus shows y demuestren tu profesionalismo."
  },
  {
    image: WhatsappPreview,
    title: "Conexión Directa con Clientes",
    subtitle: "Nunca pierdas una oportunidad",
    description: "Facilita la comunicación instantánea con tus clientes potenciales. Integración con WhatsApp y redes sociales para que puedan contactarte de inmediato y asegurar más eventos."
  },
  {
    image: UbicacionPreview,
    title: "Gestión de Eventos y Ubicaciones",
    subtitle: "Tu agenda siempre visible",
    description: "Mantén a tu audiencia informada sobre tus próximas presentaciones. Muestra dónde tocas regularmente y permite que tus seguidores planifiquen asistir a tus eventos con anticipación."
  },
  {
    image: MusicaPreview,
    title: "Tu Música, Tu Identidad",
    subtitle: "Comparte tu estilo único",
    description: "Crea una experiencia musical inmersiva con tus mejores mezclas y sets en vivo. Integra tus playlists favoritas y demuestra por qué tu estilo musical es único y perfecto para cualquier evento."
  }
];

const PLANS = {
  monthly: { 
    price: { PEN: "S/.7", USD: "$2" }, 
    title: "Plan Mensual", 
    subtitle: "Ideal para empezar tu presencia online." 
  },
  yearly: { 
    price: { PEN: "S/.70", USD: "$20" }, 
    title: "Plan Anual", 
    subtitle: "Ahorra S/.14 con este plan exclusivo.",
    savings: "¡Ahorra 2 meses!" 
  },
};

const FEATURES = [
  { text: "Página personalizada profesional", icon: <CheckCircleIcon /> },
  { text: "Galería multimedia integrada", icon: <PhotoLibraryIcon /> },
  { text: "Sistema de contacto directo", icon: <WhatsAppIcon /> },
  { text: "Presencia online optimizada", icon: <ShareIcon /> },
];

const ProfileDJProduct = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [currency, setCurrency] = useState("PEN");
  const navigate = useNavigate();

  const trackProfileDJRegistration = () => {
    try {
      window.gtag('event', 'start_profile_dj_registration', {
        event_category: 'Conversiones',
        event_label: 'Registro Página Web DJ',
        billing_cycle: billingCycle,
        currency: currency,
        price: PLANS[billingCycle].price[currency],
        selected_plan: `${PLANS[billingCycle].title} - ${PLANS[billingCycle].price[currency]}`,
        features_viewed: CAROUSEL_FEATURES[activeStep].title,
        timestamp: new Date().toISOString(),
        value: 1
      });
    } catch (error) {
      console.error('Error al registrar evento de registro Página Web DJ:', error);
    }
  };

  const handleNext = useCallback(() => {
    setActiveStep((prevStep) => (prevStep + 1) % CAROUSEL_FEATURES.length);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevStep) => (prevStep - 1 + CAROUSEL_FEATURES.length) % CAROUSEL_FEATURES.length);
  }, []);

  const handleToggle = useCallback((_, value) => {
    if (value !== null) setBillingCycle(value);
  }, []);

  const handleCurrencyChange = useCallback((newCurrency) => {
    setCurrency(newCurrency);
  }, []);

  const handleOpenModal = useCallback(() => {
    const selectedPlanInfo = `${PLANS[billingCycle].price[currency]}`;
    trackProfileDJRegistration();
    navigate('/registro-dj', { 
      state: { 
        selectedPrice: selectedPlanInfo 
      } 
    });
  }, [billingCycle, currency, navigate, activeStep]);

  // Datos estructurados para producto
  const productStructuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Perfil DJ - Página web profesional para DJs",
    "image": "https://djponla.com/profile-product-image.jpg",
    "description": "Crea tu página web profesional como DJ. Muestra tu información, eventos, redes sociales y sistema de peticiones musicales integrado.",
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "1.99",
      "availability": "https://schema.org/InStock"
    }
  };

  // Breadcrumb items
  const breadcrumbItems = [
    { name: 'Inicio', link: '/' },
    { name: 'Perfil DJ', link: '/profile-dj-product' }
  ];

  return (
    <>
      <SEO 
        title="Perfil DJ Profesional | DJ Ponla - Aumenta tu visibilidad y reservas" 
        description="Crea tu perfil profesional como DJ. Muestra tu experiencia, videos, fotos y aumenta la cantidad de reservas. Conecta con más clientes potenciales."
        keywords="perfil DJ profesional, marketing para DJs, página web DJ, promoción servicios DJ"
        canonicalUrl="/profile-dj-product"
        structuredData={productStructuredData}
      />
      
      <Navbar />
      <Container>
        <BreadcrumbSchema items={breadcrumbItems} />
      </Container>
      <Box
        sx={{
          textAlign: "center",
          padding: { xs: 2, sm: 4 },
          backgroundColor: '#121212',
          color: "white",
          minHeight: "100vh",
          mt: { xs: 2, sm: 5 },
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "#ffffff",
            mb: 2,
            mt: { xs: 10, sm: 6 }
          }}
        >
          Tu Página Web como DJ
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mb: 4,
            color: "rgba(255,255,255,0.9)",
            maxWidth: "600px",
            margin: "0 auto",
            lineHeight: 1.5
          }}
        >
          Tu presencia profesional en línea con DJ Ponla
        </Typography>

        <BillingToggle 
          billingCycle={billingCycle} 
          handleToggle={handleToggle}
          sx={{
            '.MuiToggleButtonGroup-root': {
              background: 'rgba(255,255,255,0.05)',
              backdropFilter: 'blur(10px)',
              borderRadius: '15px',
              padding: '4px',
              border: '1px solid rgba(255,255,255,0.1)',
            },
            '.MuiToggleButton-root': {
              border: 'none',
              borderRadius: '12px !important',
              color: 'rgba(255,255,255,0.7)',
              padding: '8px 24px',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.1)',
              },
              '&.Mui-selected': {
                backgroundColor: '#b70a20',
                color: '#fff',
                fontWeight: 'bold',
                boxShadow: '0 4px 12px rgba(183, 10, 32, 0.3)',
                '&:hover': {
                  backgroundColor: '#d32f2f',
                }
              }
            }
          }}
        />

        <PlanCard 
          plan={{
            ...PLANS[billingCycle],
            price: PLANS[billingCycle].price[currency]
          }}
          features={FEATURES} 
          handleOpenModal={handleOpenModal}
          color="#b70a20"
          currency={currency}
          onCurrencyChange={handleCurrencyChange}
          sx={{
            transform: "scale(1.05)",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.08)",
            },
            boxShadow: "0 8px 32px rgba(0,0,0,0.2)"
          }}
        />

        <Box
          sx={{
            position: 'relative',
            my: 4,
            maxWidth: "1400px",
            margin: "0 auto",
            px: { xs: 4, sm: 8 },
            mt: { xs: 12, sm: 16 },
          }}>
          <StepperComponent 
            steps={CAROUSEL_FEATURES} 
            activeStep={activeStep}
            sx={{
              mb: { xs: 8, sm: 10 }
            }} 
          />
          
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              px: { xs: 2, sm: 6 },
              mb: { xs: 8, sm: 12 },
            }}
          >
            <motion.div
              key={activeStep}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(255,255,255,0.05)",
                  p: { xs: 3, sm: 4, md: 5 },
                  borderRadius: 2,
                  my: { xs: 4, sm: 6 },
                  minHeight: { xs: "auto", sm: "400px" },
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  gap: { xs: 3, md: 6 },
                  backdropFilter: "blur(10px)",
                  border: "1px solid rgba(255,255,255,0.1)",
                }}
              >
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    order: { xs: 1, md: 1 },
                  }}
                >
                  <Box
                    sx={{
                      order: { xs: 1, md: 1 },
                      mb: { xs: 3, md: 0 },
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    <Typography 
                      variant="h3" 
                      gutterBottom 
                      sx={{ 
                        fontWeight: "bold",
                        color: "#b70a20",
                        mb: 1,
                        lineHeight: 1.2,
                        fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                      }}
                    >
                      {CAROUSEL_FEATURES[activeStep].title}
                    </Typography>
                    
                    <Typography 
                      variant="h5" 
                      sx={{ 
                        color: "rgba(255,255,255,0.9)",
                        mb: 3,
                        fontWeight: "500",
                        fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.5rem" },
                      }}
                    >
                      {CAROUSEL_FEATURES[activeStep].subtitle}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      order: { xs: 2, md: 3 },
                      width: "100%",
                      display: { xs: "block", md: "none" },
                      mb: 3,
                    }}
                  >
                    <Box
                      component="img"
                      src={CAROUSEL_FEATURES[activeStep].image}
                      alt={CAROUSEL_FEATURES[activeStep].title}
                      sx={{
                        width: '100%',
                        maxWidth: "500px",
                        height: "auto",
                        objectFit: "contain",
                        borderRadius: '12px',
                        boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
                        mx: "auto",
                        display: "block",
                      }}
                    />
                  </Box>

                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: "rgba(255,255,255,0.8)",
                      lineHeight: 1.8,
                      fontSize: "1.1rem",
                      order: { xs: 3, md: 2 },
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    {CAROUSEL_FEATURES[activeStep].description}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    flex: "1",
                    width: "100%",
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                    order: 2,
                  }}
                >
                  <Box
                    component="img"
                    src={CAROUSEL_FEATURES[activeStep].image}
                    alt={CAROUSEL_FEATURES[activeStep].title}
                    sx={{
                      width: '100%',
                      maxWidth: "500px",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: '12px',
                      boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.02)',
                      },
                    }}
                  />
                </Box>
              </Box>
            </motion.div>

            <IconButton
              onClick={handleBack}
              sx={{
                position: "absolute",
                left: { xs: -16, sm: -30 },
                top: "50%",
                transform: "translateY(-50%)",
                color: "white",
                zIndex: 1,
                backgroundColor: "rgba(183, 10, 32, 0.9)",
                "&:hover": {
                  backgroundColor: "#b70a20",
                  transform: 'translateY(-50%) scale(1.1)',
                },
                width: { xs: 40, sm: 50 },
                height: { xs: 40, sm: 50 },
                transition: 'all 0.3s ease',
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>

            <IconButton
              onClick={handleNext}
              sx={{
                position: "absolute",
                right: { xs: -16, sm: -30 },
                top: "50%",
                transform: "translateY(-50%)",
                color: "white",
                zIndex: 1,
                backgroundColor: "rgba(183, 10, 32, 0.9)",
                "&:hover": {
                  backgroundColor: "#b70a20",
                  transform: 'translateY(-50%) scale(1.1)',
                },
                width: { xs: 40, sm: 50 },
                height: { xs: 40, sm: 50 },
                transition: 'all 0.3s ease',
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ 
          mt: 8, 
          mb: 6,
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(180deg, rgba(183, 10, 32, 0.1) 0%, rgba(17, 91, 235, 0.1) 100%)',
            borderRadius: '20px',
            zIndex: -1,
          }
        }}>
        </Box>
      </Box>
    </>
  );
};

export default ProfileDJProduct; 