import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

const cardStyles = {
  backgroundColor: "rgba(34, 34, 34, 0.95)",
  backdropFilter: "blur(20px)",
  borderRadius: '24px',
  transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
  position: "relative",
  overflow: "hidden",
  boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.2)',
  '&:hover': {
    transform: 'translateY(-10px) scale(1.02)',
    boxShadow: '0 20px 40px -15px rgba(183, 10, 32, 0.5)',
  }
};

const StatCard = ({ value, label, color = '#b70a20', subtext, icon }) => (
  <Card sx={cardStyles}>
    <CardContent 
      sx={{ 
        textAlign: 'center',
        position: "relative",
        zIndex: 1,
        pt: 3,
        px: { xs: 2, sm: 3 },
        pb: { xs: 3, sm: 3 },
        '&::before': {
          content: '""',
          position: "absolute",
          inset: 0,
          background: `linear-gradient(135deg, ${color}15, transparent 50%, ${color}10)`,
          opacity: 0.1,
          transition: "opacity 0.4s ease",
          zIndex: -1
        },
        '&:hover::before': {
          opacity: 0.3
        }
      }}
    >
      <Typography 
        variant="h3" 
        sx={{ 
          color: 'white',
          mb: 1,
          fontSize: { xs: '2rem', sm: '2.5rem' },
          fontWeight: 'bold'
        }}
      >
        {value}
        {icon && (
          <Typography 
            component="span" 
            variant="h4" 
            sx={{ 
              ml: 1,
              fontSize: { xs: '1.5rem', sm: '2rem' }
            }}
          >
            {icon}
          </Typography>
        )}
      </Typography>
      <Typography 
        variant="body1" 
        sx={{ 
          color: 'rgba(255,255,255,0.9)',
          fontSize: { xs: '0.9rem', sm: '1rem' },
          fontWeight: 500
        }}
      >
        {label}
      </Typography>
      {subtext && (
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'rgba(255,255,255,0.7)',
            mt: 1,
            fontSize: { xs: '0.8rem', sm: '0.9rem' }
          }}
        >
          {subtext}
        </Typography>
      )}
    </CardContent>
  </Card>
);

const GeneralStats = ({ statistics }) => {
  const notPlayedPercentage = ((statistics.totalRequests - statistics.playedSongs) / statistics.totalRequests * 100).toFixed(1);

  return (
    <Grid 
      container 
      spacing={3} 
    >
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          value={statistics.totalRequests}
          label="Total de solicitudes"
          color="#b70a20"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          value={statistics.uniqueArtists}
          label="Artistas únicos"
          color="#b70a20"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          value={statistics.uniqueSongs}
          label="Canciones únicas"
          color="#b70a20"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <StatCard
          value={statistics.playedSongs}
          label="Reproducidas"
          color="#b70a20"
          icon="✅"
          subtext={`${statistics.playedPercentage} del total`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <StatCard
          value={statistics.totalRequests - statistics.playedSongs}
          label="No Reproducidas"
          color="#b70a20"
          icon="❌"
          subtext={`${notPlayedPercentage}% del total`}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralStats; 