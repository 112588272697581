import React, { useState } from "react";
import { Box, Typography, useTheme, Container } from "@mui/material";
import Navbar from "../components/Navbar";
import DJModal from "../components/QRDJProduct/QRDJModal";
import BillingToggle from "../components/BillingToggle";
import PlanCard from "../components/PlanCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import UpdateIcon from "@mui/icons-material/Update";
import InfoIcon from "@mui/icons-material/Info";
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import SEO from '../components/SEO';

const QRDJProduct = () => {
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [currency, setCurrency] = useState("PEN");
  const [openDJModal, setOpenDJModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const plans = {
    monthly: {
      PEN: { 
        price: "S/.35", 
        title: "Plan Mensual", 
        subtitle: "Ideal para empezar y explorar.",
        savings: "Ahorra 20%" 
      },
      USD: { 
        price: "$9.99", 
        title: "Monthly Plan", 
        subtitle: "Perfect to start and explore.",
        savings: "Save 20%" 
      }
    },
    yearly: {
      PEN: { 
        price: "S/.350", 
        title: "Plan Anual", 
        subtitle: "Ahorra S/.70 con este plan exclusivo." 
      },
      USD: { 
        price: "$99.99", 
        title: "Annual Plan", 
        subtitle: "Save $20 with this exclusive plan." 
      }
    },
  };

  const features = [
    { text: "Vistas personalizadas de sugerencias de canciones.", icon: <CheckCircleIcon /> },
    { text: "Un QR personalizado único para tu evento.", icon: <QrCodeIcon /> },
    { text: "Datos detallados sobre las peticiones de canciones.", icon: <DataUsageIcon /> },
    { text: "Acceso a próximas actualizaciones.", icon: <UpdateIcon /> },
  ];

  const trackQRDJModalOpen = () => {
    try {
      window.gtag('event', 'open_qr_dj_registration_modal', {
        event_category: 'Conversiones',
        event_label: 'Modal Registro QR DJ',
        billing_cycle: billingCycle,
        currency: currency,
        price: plans[billingCycle][currency].price,
        selected_plan: `${plans[billingCycle][currency].title} - ${plans[billingCycle][currency].price}`,
        timestamp: new Date().toISOString(),
        value: 1
      });
    } catch (error) {
      console.error('Error al registrar evento de apertura de modal QR DJ:', error);
    }
  };

  const handleToggle = (_, value) => {
    if (value !== null) setBillingCycle(value);
  };

  const handleOpenModal = () => {
    const selectedPlanInfo = `${plans[billingCycle][currency].title} - ${plans[billingCycle][currency].price}`;
    setSelectedPlan(selectedPlanInfo);
    trackQRDJModalOpen();
    setOpenDJModal(true);
  };

  const theme = useTheme();

  // Datos estructurados para producto
  const productStructuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "QR DJ - Sistema de peticiones musicales",
    "image": "https://djponla.com/qr-product-image.jpg",
    "description": "Sistema QR personalizado para recibir peticiones musicales en tiempo real. Aumenta tus ingresos y la satisfacción del público en eventos.",
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "9.99",
      "availability": "https://schema.org/InStock"
    }
  };

  // Breadcrumb items
  const breadcrumbItems = [
    { name: 'Inicio', link: '/' },
    { name: 'Sistema QR para DJs', link: '/qr-dj-product' }
  ];

  return (
    <>
      <SEO 
        title="QR DJ | DJ Ponla - Recibe sugerencias musicales en tiempo real" 
        description="Recibe sugerencias musicales de tu público en tiempo real. Con QR DJ, tu público escanea el código y envía sus canciones favoritas."
        keywords="QR para DJs, sugerencias musicales QR, sistema de peticiones DJ, recibir peticiones música"
        canonicalUrl="/qr-dj-product"
        structuredData={productStructuredData}
      />
      <Navbar />
      <Container>
        <BreadcrumbSchema items={breadcrumbItems} />
        <Box
          sx={{
            textAlign: "center",
            padding: 4,
            backgroundColor: '#121212',
            color: "white",
            minHeight: "100vh",
            mt: { xs: 2, sm: 5 },
          }}
        >
          <Box
            sx={{
              mt: 4,
              position: "relative",
              maxWidth: { xs: "90%", md: "60%" },
              margin: "auto",
              opacity: 0,
              animation: "fadeIn 0.8s ease-out forwards",
              "@keyframes fadeIn": {
                from: { opacity: 0, transform: "translateY(20px)" },
                to: { opacity: 1, transform: "translateY(0)" }
              }
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "#ffffff",
              mb: 2,
              mt: { xs: 10, sm: 6 }
              }}
            >
              Sistema QR para DJs
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                color: "rgba(255,255,255,0.9)",
                maxWidth: "600px",
                margin: "0 auto",
                lineHeight: 1.5
              }}
            >
              La forma más inteligente de conectar con tu público
            </Typography>
          </Box>

          <BillingToggle 
            billingCycle={billingCycle} 
            handleToggle={handleToggle}
            sx={{
              '.MuiToggleButtonGroup-root': {
                background: 'rgba(255,255,255,0.05)',
                backdropFilter: 'blur(10px)',
                borderRadius: '15px',
                padding: '4px',
                border: '1px solid rgba(255,255,255,0.1)',
              },
              '.MuiToggleButton-root': {
                border: 'none',
                borderRadius: '12px !important',
                color: 'rgba(255,255,255,0.7)',
                padding: '8px 24px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.1)',
                },
                '&.Mui-selected': {
                  backgroundColor: '#b70a20',
                  color: '#fff',
                  fontWeight: 'bold',
                  boxShadow: '0 4px 12px rgba(183, 10, 32, 0.3)',
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                  }
                }
              }
            }}
          />

          <PlanCard 
            plan={plans[billingCycle][currency]}
            features={features} 
            handleOpenModal={handleOpenModal}
            color="#b70a20"
            currency={currency}
            onCurrencyChange={setCurrency}
            sx={{
              transform: "scale(1.05)",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.08)",
              }
            }}
          />

          <DJModal
            open={openDJModal}
            onClose={() => setOpenDJModal(false)}
            selectedPrice={plans[billingCycle][currency].price}
            selectedCurrency={currency}
          />

          <Typography 
            variant="body2" 
            sx={{ 
              marginTop: 3, 
              color: "rgba(255,255,255,0.7)", 
              fontStyle: "italic",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1
            }}
          >
            <InfoIcon sx={{ fontSize: 16 }} />
            Cancela en cualquier momento. Sin compromisos.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default QRDJProduct; 