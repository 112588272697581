import React from 'react';
import { Box, Typography, TextField, Paper } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import PersonIcon from '@mui/icons-material/Person';

const BasicInfoForm = ({ formData, errors, handleChange }) => {
  return (
    <Paper 
      elevation={3}
      sx={{
        p: 3,
        bgcolor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: 2,
        transition: 'all 0.3s ease',
        '&:hover': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
          transform: 'translateY(-2px)'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <PersonIcon sx={{ color: '#b70a20', mr: 2, fontSize: 28 }} />
        <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
          Información Básica
        </Typography>
      </Box>

      <Box sx={{ display: 'grid', gap: 3 }}>
        <TextField
          fullWidth
          variant="filled"
          label="Nombre de DJ"
          name="djName"
          value={formData.djName}
          onChange={handleChange}
          error={!!errors.djName}
          helperText={errors.djName || "Ejemplo: DJ Diego Pozo, Herrera DJ"}
          sx={{
            '& .MuiFilledInput-root': {
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.08)',
              }
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.7)'
            },
            '& .MuiInputBase-input': {
              color: 'white'
            }
          }}
        />

        <TextField
          fullWidth
          variant="filled"
          label="Tu frase que más te identifica"
          name="tagline"
          value={formData.tagline}
          onChange={handleChange}
          sx={{
            '& .MuiFilledInput-root': {
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.08)',
              }
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.7)'
            },
            '& .MuiInputBase-input': {
              color: 'white'
            }
          }}
        />

        <Box sx={{ '& .react-tel-input': { mb: 2 } }}>
          <PhoneInput
            country="pe"
            value={formData.phone}
            onChange={(phone) => handleChange({ target: { name: 'phone', value: phone }})}
            inputStyle={{
              width: '100%',
              height: '56px',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              color: 'white',
              border: 'none',
              borderRadius: '4px'
            }}
            dropdownStyle={{
              backgroundColor: '#1e1e1e'
            }}
          />
        </Box>

        <TextField
          fullWidth
          variant="filled"
          label="Correo Electrónico"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          sx={{
            '& .MuiFilledInput-root': {
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.08)',
              }
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.7)'
            },
            '& .MuiInputBase-input': {
              color: 'white'
            }
          }}
        />
      </Box>
    </Paper>
  );
};

export default BasicInfoForm; 